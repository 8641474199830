import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MultiChoiceCategory} from '../../multi-choice-category';

@Component({
  selector: 'hpf-multi-choice-category-create',
  templateUrl: './multi-choice-category-create.component.html'
})
export class MultiChoiceCategoryCreateComponent implements OnInit {
  /** The multi choice category */
  multiChoiceCategory = new MultiChoiceCategory();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the multi choice category is created */
  onCreate(multiChoiceCategory: MultiChoiceCategory): void {
    this.router.navigateByUrl(`/multi-choice-category/${multiChoiceCategory.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
