import {Helpers} from '@app/shared';
import {BaseModelSearchParams, BaseModelSearchParamsInterface} from '@app/abstracts';

/** Used to export and import search params */
export interface OralQuizModuleSearchParamsInterface extends BaseModelSearchParamsInterface {
  _sort?: '_id' | 'name'| 'created_at'
  _id?: string[];
  _text?: string;
  name?: string;
}

/** Manage oral quiz module search params */
export class OralQuizModuleSearchParams extends BaseModelSearchParams< OralQuizModuleSearchParamsInterface> {

  /** @inheritDoc */
  fromObject(input: Partial< OralQuizModuleSearchParamsInterface>): void {

    // Clone object with allowed keys only
    const props = {} as OralQuizModuleSearchParamsInterface;
    this.allowedKeys().map((key: string) => {
      if (typeof input[key] !== 'undefined') {
        props[key] = input[key];
      }
    });

    // Keep default values
    if (typeof props._page === 'undefined') { props._page = this.defaultPage; }
    if (typeof props._limit === 'undefined') { props._limit = this.defaultLimit; }
    if (typeof props._sort === 'undefined') { props._sort = this.defaultSort; }
    if (typeof props._order === 'undefined') { props._order = this.defaultOrder; }
    // Convert potentially multiple primary keys
    if (typeof props._id === 'string') {
      props._id = (< string>props._id).split(',').filter((s) => s.length);
    }

    // Assign values
    this.props = props;
    this.next();
  }
  /** @inheritDoc */
  toObject(): OralQuizModuleSearchParamsInterface {

    // Filter not allowed, undefined and null values
    const props = {} as OralQuizModuleSearchParamsInterface;
    this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));

    return props;
  }
  /** @inheritDoc */
  protected allowedKeys(): string[] {
    return super.allowedKeys().concat([
      '_id',
      '_text',
      'name',
    ]);
  }
}
