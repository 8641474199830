import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {MultiChoiceCategorySearchParams} from '../multi-choice-category-search-params';

@Component({
  selector: 'hpf-multi-choice-category-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class MultiChoiceCategoryListTopBarComponent extends EntityListTopBarComponent< MultiChoiceCategorySearchParams> {
}
 