import {Injectable} from '@angular/core';
import {Itinerary, ItineraryInterface, ItineraryPayload} from './itinerary';
import {ItinerarySearchParamsInterface} from './itinerary-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class ItineraryService extends BaseModelService< Itinerary, ItineraryInterface, ItineraryPayload, ItinerarySearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'itinerary';
  }
  /** @inheritDoc */
  protected new(object: ItineraryInterface): Itinerary {
    return new Itinerary(object);
  }
}
