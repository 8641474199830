import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {PdfSearchParams} from '../pdf-search-params';
import {PdfService} from '../pdf.service';
import {Pdf} from '../pdf';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-pdf-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class PdfSelectComponent extends EntitySelectComponent< Pdf> {
  protected modelName = 'pdf';
  /**
   * Constructor
   * @param {PdfService} pdfService
   * @param {ErrorService} errorService
   */
  constructor(private pdfService: PdfService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Pdf)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Pdf[]>this.model).push(<Pdf>model);
          } else {
            this.model = <Pdf>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Pdf[]> {
      const params = new PdfSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.pdfService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Pdf> {
      return await this.pdfService.get(id);
  }
}
