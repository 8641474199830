import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
export interface MediaImageInterface extends BaseModelInterface {
  title: string;
  thumbnail?: (string | Image | ImageInterface);
  image: (string | Image | ImageInterface);
  created_at: number | Date;
}
export interface MediaImagePayload {
  title: string;
  thumbnail?: (string | Image | ImageInterface);
  image: (string | Image | ImageInterface);
}
type MediaImagePayloadKey = keyof MediaImagePayload;
export class MediaImage extends BaseModel< MediaImageInterface, MediaImagePayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.title}`;
  }
  /** Denotes if the instance of thumbnail has been populated */
  thumbnailExists(): boolean {
    return !!this.props &&
        this.props.thumbnail instanceof Image &&
        this.props.thumbnail.exists();
  }
  /** Denotes if the instance of image has been populated */
  imageExists(): boolean {
    return !!this.props &&
        this.props.image instanceof Image &&
        this.props.image.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: MediaImageInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.thumbnail !== null && typeof this.props.thumbnail === 'object') {
        this.props.thumbnail = new Image(< ImageInterface>this.props.thumbnail);
    }
    if (typeof this.props.image === 'object') {
        this.props.image = new Image(< ImageInterface>this.props.image);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MediaImageInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.thumbnail === 'undefined') {
        props.thumbnail = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.thumbnail !== null && props.thumbnail instanceof Image) {
        props.thumbnail = props.thumbnail.toObject();
    }
    if (props.image instanceof Image) {
        props.image = props.image.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MediaImagePayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MediaImageInterface);
    payload.thumbnail = payload.thumbnail ? this.extractId(payload.thumbnail) : null;
    payload.image = payload.image ? this.extractId(payload.image) : null;
    return payload as MediaImagePayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MediaImagePayloadKey[] {
  	return [
      'title',
      'thumbnail',
      'image',
  	];
  }
}
