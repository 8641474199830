import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Itinerary} from '../../itinerary';
import {ItineraryService} from '../../itinerary.service';
@Component({
  selector: 'hpf-itinerary-edit',
  templateUrl: './itinerary-edit.component.html'
})
export class ItineraryEditComponent implements OnInit, OnDestroy {
  /** The itinerary */
  itinerary = new Itinerary();
  /** The itinerary */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private itineraryService: ItineraryService) {}
  /** Init */
  ngOnInit() {
    // Get the itinerary after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch itinerary from API */
  private refresh() {
    this.itineraryService.get(this.id, { _populate: ['*'] })
      .then((itinerary: Itinerary) => {
        this.itinerary.fromObject(itinerary.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the itinerary is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the itinerary is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/itinerary')
      .catch((error) => this.errorService.handle(error));
  }
}
