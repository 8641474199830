import {Injectable} from '@angular/core';
import {PhotoModule, PhotoModuleInterface, PhotoModulePayload} from './photo-module';
import {PhotoModuleSearchParamsInterface} from './photo-module-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class PhotoModuleService extends BaseModelService< PhotoModule, PhotoModuleInterface, PhotoModulePayload, PhotoModuleSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'photo-module';
  }
  /** @inheritDoc */
  protected new(object: PhotoModuleInterface): PhotoModule {
    return new PhotoModule(object);
  }
}
