import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {ItinerarySearchParams} from '../itinerary-search-params';
import {ItineraryService} from '../itinerary.service';
import {Itinerary} from '../itinerary';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-itinerary-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class ItinerarySelectComponent extends EntitySelectComponent< Itinerary> {
  protected modelName = 'itinerary';
  /**
   * Constructor
   * @param {ItineraryService} itineraryService
   * @param {ErrorService} errorService
   */
  constructor(private itineraryService: ItineraryService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Itinerary)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Itinerary[]>this.model).push(<Itinerary>model);
          } else {
            this.model = <Itinerary>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Itinerary[]> {
      const params = new ItinerarySearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      return (await this.itineraryService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Itinerary> {
      return await this.itineraryService.get(id);
  }
}
