import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {MultiChoiceCategory} from '../../multi-choice-category';
import {MultiChoiceCategoryService} from '../../multi-choice-category.service';
@Component({
  selector: 'hpf-multi-choice-category-edit',
  templateUrl: './multi-choice-category-edit.component.html'
})
export class MultiChoiceCategoryEditComponent implements OnInit, OnDestroy {
  /** The multi choice category */
  multiChoiceCategory = new MultiChoiceCategory();
  /** The multi choice category */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private multiChoiceCategoryService: MultiChoiceCategoryService) {}
  /** Init */
  ngOnInit() {
    // Get the multi choice category after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch multi choice category from API */
  private refresh() {
    this.multiChoiceCategoryService.get(this.id, { _populate: ['*'] })
      .then((multiChoiceCategory: MultiChoiceCategory) => {
        this.multiChoiceCategory.fromObject(multiChoiceCategory.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the multi choice category is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the multi choice category is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/multi-choice-category')
      .catch((error) => this.errorService.handle(error));
  }
}
