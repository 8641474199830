import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
export interface ActivityModuleInterface extends BaseModelInterface {
	module_id: any;
	created_at: number | Date;
	name: string;
	memory_aid: string;
}
export interface ActivityModulePayload {
	name: string;
	memory_aid: string;
	module_id: string;
}
type ActivityModulePayloadKey = keyof ActivityModulePayload;
export class ActivityModule extends BaseModel<
	ActivityModuleInterface,
	ActivityModulePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: ActivityModuleInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ActivityModuleInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ActivityModulePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ActivityModuleInterface);
		return payload as ActivityModulePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ActivityModulePayloadKey[] {
		return ['name', 'memory_aid', 'module_id'];
	}
}
