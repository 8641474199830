import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MediaVideo} from '../../media-video';

@Component({
  selector: 'hpf-media-video-create',
  templateUrl: './media-video-create.component.html'
})
export class MediaVideoCreateComponent implements OnInit {
  /** The media video */
  mediaVideo = new MediaVideo();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media video is created */
  onCreate(mediaVideo: MediaVideo): void {
    this.router.navigateByUrl(`/media-video/${mediaVideo.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
