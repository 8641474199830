import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {OralCategorySearchParams} from '../oral-category-search-params';

@Component({
  selector: 'hpf-oral-category-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class OralCategoryListTopBarComponent extends EntityListTopBarComponent< OralCategorySearchParams> {
}
 