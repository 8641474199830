<ng-container *ngIf="config.enabled">
	<nz-modal
		[nzVisible]="visible"
		nzWidth="50%"
		(nzOnCancel)="visible = false"
		[nzFooter]="null"
	>
		<div class="my-5" *ngIf="model">
			<ng-container [ngSwitch]="model">
				<!-- activityModule -->
				<hpf-activity-module-form
					*ngSwitchCase="'activity_module'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-activity-module-form>
				<!-- /activityModule -->
				<!-- audio -->
				<hpf-audio-form
					*ngSwitchCase="'audio'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-audio-form>
				<!-- /audio -->
				<!-- clientInfo -->
				<hpf-client-info-form
					*ngSwitchCase="'client_info'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-client-info-form>
				<!-- /clientInfo -->
				<!-- image -->
				<hpf-image-form
					*ngSwitchCase="'image'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-image-form>
				<!-- /image -->
				<!-- image360 -->
				<hpf-image-360-form
					*ngSwitchCase="'image_360'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-image-360-form>
				<!-- /image360 -->
				<!-- instruction -->
				<hpf-instruction-form
					*ngSwitchCase="'instruction'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-instruction-form>
				<!-- /instruction -->
				<!-- itinerary -->
				<hpf-itinerary-form
					*ngSwitchCase="'itinerary'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-itinerary-form>
				<!-- /itinerary -->
				<!-- media -->
				<hpf-media-form
					*ngSwitchCase="'media'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-form>
				<!-- /media -->
				<!-- media360Image -->
				<hpf-media-360-image-form
					*ngSwitchCase="'media_360_image'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-360-image-form>
				<!-- /media360Image -->
				<!-- media360Video -->
				<hpf-media-360-video-form
					*ngSwitchCase="'media_360_video'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-360-video-form>
				<!-- /media360Video -->
				<!-- mediaAudio -->
				<hpf-media-audio-form
					*ngSwitchCase="'media_audio'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-audio-form>
				<!-- /mediaAudio -->
				<!-- mediaImage -->
				<hpf-media-image-form
					*ngSwitchCase="'media_image'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-image-form>
				<!-- /mediaImage -->
				<!-- mediaPDF -->
				<hpf-media-pdf-form
					*ngSwitchCase="'media_pdf'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-pdf-form>
				<!-- /mediaPDF -->
				<!-- mediaVideo -->
				<hpf-media-video-form
					*ngSwitchCase="'media_video'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-media-video-form>
				<!-- /mediaVideo -->
				<!-- module -->
				<hpf-module-form
					*ngSwitchCase="'module'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-module-form>
				<!-- /module -->
				<!-- multiChoiceCategory -->
				<hpf-multi-choice-category-form
					*ngSwitchCase="'multi_choice_category'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-multi-choice-category-form>
				<!-- /multiChoiceCategory -->
				<!-- multiChoiceQuizModule -->
				<hpf-multi-choice-quiz-module-form
					*ngSwitchCase="'multi_choice_quiz_module'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-multi-choice-quiz-module-form>
				<!-- /multiChoiceQuizModule -->
				<!-- multiElement -->
				<hpf-multi-element-form
					*ngSwitchCase="'multi_element'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-multi-element-form>
				<!-- /multiElement -->
				<!-- multimediaModule -->
				<hpf-multimedia-module-form
					*ngSwitchCase="'multimedia_module'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-multimedia-module-form>
				<!-- /multimediaModule -->
				<!-- oralCategory -->
				<hpf-oral-category-form
					*ngSwitchCase="'oral_category'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-oral-category-form>
				<!-- /oralCategory -->
				<!-- oralQuizModule -->
				<hpf-oral-quiz-module-form
					*ngSwitchCase="'oral_quiz_module'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-oral-quiz-module-form>
				<!-- /oralQuizModule -->
				<!-- pdf -->
				<hpf-pdf-form
					*ngSwitchCase="'pdf'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-pdf-form>
				<!-- /pdf -->
				<!-- photoModule -->
				<hpf-photo-module-form
					*ngSwitchCase="'photo_module'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-photo-module-form>
				<!-- /photoModule -->
				<!-- pointOfInterest -->
				<hpf-point-of-interest-form
					*ngSwitchCase="'point_of_interest'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-point-of-interest-form>
				<!-- /pointOfInterest -->
				<!-- user -->
				<hpf-user-form
					*ngSwitchCase="'user'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-user-form>
				<!-- /user -->
				<!-- video -->
				<hpf-video-form
					*ngSwitchCase="'video'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-video-form>
				<!-- /video -->
			</ng-container>
		</div>
	</nz-modal>
</ng-container>
