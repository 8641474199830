
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChildren, QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService, ExplainErrorsService, SessionService} from '@app/services';
import { environment } from '@env/environment';
import {ClientInfo} from '../client-info';
import {ClientInfoService} from '../client-info.service';
@Component({
  selector: 'hpf-client-info-form',
  templateUrl: './client-info-form.component.html'
})
export class ClientInfoFormComponent implements OnInit, OnDestroy {
  /** The model subscription */
  private modelSubscription: RxJsSubscription;
  /** The client info to inject in the form */
  @Input() clientInfo: ClientInfo;
  /** Called the save button is clicked for a new instance */
  @Output() create = new EventEmitter<ClientInfo>();
  /** Called the save button is clicked for an existing instance */
  @Output() update = new EventEmitter<void>();
  /** Called the delete button is clicked */
  @Output() delete = new EventEmitter<void>();
  /** Set loading state of the form */
  @Input() loading = false;
  /** Enable the deletion button */
  @Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
  /** Denotes if deltion in progress */
  deleting = false;
  /** Show delete modal */
  deleteModal = false;
  /** The form group to use */
  form: FormGroup;
  /** Denotes if the form is pending */
  saving = false;
  /** Check permission of update **/
  readOnly = false;
  /** Constructor */
  constructor(private formBuilder: FormBuilder,
              private errorService: ErrorService,
              public explainErrorsService: ExplainErrorsService,
              private clientInfoService: ClientInfoService,
              private sessionService: SessionService) {
  }
  /** Init */
  async ngOnInit() {
    this.readOnly = !(await this.sessionService.checkPermission(
			'client_info',
			'update'
		));

    // Init model
    if(!this.clientInfo) this.clientInfo = new ClientInfo();

    // Create form
    this.form = this.formBuilder.group({
    name: new FormControl(this.clientInfo.props.name, [Validators.required]),
    email: new FormControl(this.clientInfo.props.email, [Validators.email, Validators.required]),
    invitation: new FormControl(this.clientInfo.props.invitation, [Validators.required]),
    newsletter: new FormControl(this.clientInfo.props.newsletter, [Validators.required]),
    });

    // Update form when model loads or changes
    this.modelSubscription = this.clientInfo.subscribe(() => {
        this.updateForm();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.modelSubscription.unsubscribe();
  }
  /** Called on form submit */
  async onSubmit(): Promise<void> {
    // Saving flag
    this.saving = true;
    try {

        // Update model
        this.updateModel();
	
        // Creation or update ?
        if (this.clientInfo.isNew()) {
            // Creation
            const clientInfo: ClientInfo = await this.clientInfoService.create(this.clientInfo.toPayload());
            this.create.next(clientInfo);
        } else {
            // Update
            await this.clientInfoService.update(this.clientInfo.getId(), this.clientInfo.toPayload());
            this.update.next();
        }
    } catch (error) {
        this.errorService.handle(error);
    }
    // Saving flag
    this.saving = false
  }
  /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.clientInfo.props[key] = this.form.get(key).value;
    }
  }
  /** Update inputs values from models properties */
  private updateForm(): void {
    this.form.setValue({
     'name': this.clientInfo.props.name,
     'email': this.clientInfo.props.email,
     'invitation': this.clientInfo.props.invitation,
     'newsletter': this.clientInfo.props.newsletter,
    });
  }
  /** Called on deletion */
  onDelete(): void {
    this.deleting = true;
    this.clientInfoService.remove(this.clientInfo.getId())
      .then(() => {
        this.delete.next();
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => this.deleting = false);
  }
}
