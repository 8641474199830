import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {MultimediaModuleSearchParams} from '../multimedia-module-search-params';
import {MultimediaModuleService} from '../multimedia-module.service';
import {MultimediaModule} from '../multimedia-module';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-multimedia-module-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class MultimediaModuleSelectComponent extends EntitySelectComponent< MultimediaModule> {
  protected modelName = 'multimedia_module';
  /**
   * Constructor
   * @param {MultimediaModuleService} multimediaModuleService
   * @param {ErrorService} errorService
   */
  constructor(private multimediaModuleService: MultimediaModuleService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof MultimediaModule)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<MultimediaModule[]>this.model).push(<MultimediaModule>model);
          } else {
            this.model = <MultimediaModule>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< MultimediaModule[]> {
      const params = new MultimediaModuleSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.multimediaModuleService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< MultimediaModule> {
      return await this.multimediaModuleService.get(id);
  }
}
