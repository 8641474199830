import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {MultiChoiceCategory} from '../multi-choice-category';
import {MultiChoiceCategoryService} from '../multi-choice-category.service';
import {MultiElementFormArrayComponent} from "../../multi-element/multi-element-form-array/multi-element-form-array.component"
@Component({
  selector: 'hpf-multi-choice-category-mini-form',
  templateUrl: './multi-choice-category-mini-form.component.html'
})
export class MultiChoiceCategoryMiniFormComponent implements OnInit{

    @Input() value: MultiChoiceCategory;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    @ViewChildren(MultiElementFormArrayComponent) elementsforms: QueryList<
		MultiElementFormArrayComponent
	>;
    constructor(private formBuilder: FormBuilder,private multiChoiceCategoryService: MultiChoiceCategoryService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    question: new FormControl(this.value.props.question, [Validators.required]),
                    result_type: new FormControl(this.value.props.result_type, [Validators.required]),
                    category_image: new FormControl(this.value.props.category_image, []),
                    environment_image: new FormControl(this.value.props.environment_image, []),
                    elements: new FormControl(this.value.props.elements, []),
                });
    	    
    	 }
    public async submit()
    {
    for (let form of this.elementsforms)
    {
        await form.submit();
    }
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.multiChoiceCategoryService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.multiChoiceCategoryService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'question': this.value.props.question,
     'result_type': this.value.props.result_type,
     'category_image': this.value.props.category_image,
     'environment_image': this.value.props.environment_image,
     'elements': this.value.props.elements,
    });
  }
	@Output() init = new EventEmitter<MultiChoiceCategoryMiniFormComponent>();

}
