import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {MultimediaModule} from '../multimedia-module';
import {MultimediaModuleService} from '../multimedia-module.service';
@Component({
  selector: 'hpf-multimedia-module-mini-form',
  templateUrl: './multimedia-module-mini-form.component.html'
})
export class MultimediaModuleMiniFormComponent implements OnInit{

    @Input() value: MultimediaModule;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private multimediaModuleService: MultimediaModuleService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    memory_aid: new FormControl(this.value.props.memory_aid, []),
                    medias: new FormControl(this.value.props.medias, [Validators.required]),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.multimediaModuleService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.multimediaModuleService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'memory_aid': this.value.props.memory_aid,
     'medias': this.value.props.medias,
    });
  }
	@Output() init = new EventEmitter<MultimediaModuleMiniFormComponent>();

}
