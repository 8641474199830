import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
export interface AudioInterface extends BaseModelInterface {
  name: string;
  file_ext: string;
  file: any;
  created_at: number | Date;
}
export interface AudioPayload {
  name: string;
  file: any;
}
type AudioPayloadKey = keyof AudioPayload;
export class Audio extends BaseModel< AudioInterface, AudioPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Populate the current instance from an object */
  fromObject(object: AudioInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): AudioInterface {
    const props = Object.assign({}, this.props);
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): AudioPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as AudioInterface);
    return payload as AudioPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): AudioPayloadKey[] {
  	return [
      'name',
      'file',
  	];
  }
}
