import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {Media360ImageSearchParams} from '../media-360-image-search-params';

@Component({
  selector: 'hpf-media-360-image-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class Media360ImageListTopBarComponent extends EntityListTopBarComponent< Media360ImageSearchParams> {
}
 