<nz-drawer
	[nzClosable]="false"
	[(nzVisible)]="filtersDrawer"
	nzPlacement="right"
	(nzOnClose)="filtersDrawer = false"
	[nzTitle]="'common_filters-title' | translate"
	nzWidth="300"
>
    <hpf-media-audio-filters [searchParams]="searchParams" [resetPage]="true"></hpf-media-audio-filters>
</nz-drawer>
<hpf-media-audio-list-top-bar
    [title]="'media-audio_common_name' | translate"
	(filterEvent)="filtersDrawer = true"
	[searchParams]="searchParams"
	(createEvent)="onCreate()"
    [createButton]="'common_create' | translate"
    [showQuickSearch]="true"
    [quickSearchTooltip]="'common_search-by' | translate: { list: 'title' }"
    [quickSearchPlaceholder]="'common_quick-search' | translate"
    [showAdvancedSearch]="true"
></hpf-media-audio-list-top-bar>
<div class="container-fluid px-5">
    <div class="row my-5">
        <div class="col-12">
            <hpf-media-audio-table
                [searchParams]="searchParams"
                [enableMultiSelect]="true"
                [showActions]="true"
                (select)="onSelect($event)"></hpf-media-audio-table>
        </div>
    </div>
</div>
