import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-media-image',
  templateUrl: './media-image.component.html'
})
export class MediaImageComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
