import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {OralQuizModule} from '../oral-quiz-module';
import {OralQuizModuleService} from '../oral-quiz-module.service';
import {OralCategoryFormArrayComponent} from "../../oral-category/oral-category-form-array/oral-category-form-array.component"
import {InstructionFormArrayComponent} from "../../instruction/instruction-form-array/instruction-form-array.component"
import {Helpers} from '@app/shared';
@Component({
  selector: 'hpf-oral-quiz-module-mini-form',
  templateUrl: './oral-quiz-module-mini-form.component.html'
})
export class OralQuizModuleMiniFormComponent implements OnInit{

    @Input() value: OralQuizModule;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    @ViewChildren(OralCategoryFormArrayComponent) categoriesforms: QueryList<
		OralCategoryFormArrayComponent
	>;
    @ViewChildren(InstructionFormArrayComponent) instructionsforms: QueryList<
		InstructionFormArrayComponent
	>;
    constructor(private formBuilder: FormBuilder,private oralQuizModuleService: OralQuizModuleService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    memory_aid: new FormControl(this.value.props.memory_aid, []),
                    background_image: new FormControl(this.value.props.background_image, [Validators.required]),
                    question: new FormControl(this.value.props.question, [Validators.required]),
                    good_answers_per_category: new FormControl(this.value.props.good_answers_per_category, [Validators.required]),
                    final_text: new FormControl(this.value.props.final_text, [Validators.required]),
                    final_image: new FormControl(this.value.props.final_image, [Validators.required]),
                    timer_final_results: new FormControl(Helpers.toJSON(this.value.props.timer_final_results), [Validators.required]),
                    time_in_seconds: new FormControl(this.value.props.time_in_seconds, [Validators.required]),
                    time_results_tiers: new FormControl(Helpers.toJSON(this.value.props.time_results_tiers), [Validators.required]),
                    categories: new FormControl(this.value.props.categories, []),
                    instructions: new FormControl(this.value.props.instructions, []),
                });
    	    
    	 }
    public async submit()
    {
    for (let form of this.categoriesforms)
    {
        await form.submit();
    }
    for (let form of this.instructionsforms)
    {
        await form.submit();
    }
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.oralQuizModuleService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.oralQuizModuleService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
    if (typeof this.value.props.timer_final_results === 'string') {
        this.value.props.timer_final_results = this.value.props.timer_final_results ?
            JSON.parse(this.value.props.timer_final_results) : null;
    }
    if (typeof this.value.props.time_results_tiers === 'string') {
        this.value.props.time_results_tiers = this.value.props.time_results_tiers ?
            JSON.parse(this.value.props.time_results_tiers) : null;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'memory_aid': this.value.props.memory_aid,
     'background_image': this.value.props.background_image,
     'question': this.value.props.question,
     'good_answers_per_category': this.value.props.good_answers_per_category,
     'final_text': this.value.props.final_text,
     'final_image': this.value.props.final_image,
     'timer_final_results': Helpers.toJSON(this.value.props.timer_final_results),
     'time_in_seconds': this.value.props.time_in_seconds,
     'time_results_tiers': Helpers.toJSON(this.value.props.time_results_tiers),
     'categories': this.value.props.categories,
     'instructions': this.value.props.instructions,
    });
  }
	@Output() init = new EventEmitter<OralQuizModuleMiniFormComponent>();

}
