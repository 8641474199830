import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Image360} from '../../image-360';

@Component({
  selector: 'hpf-image-360-create',
  templateUrl: './image-360-create.component.html'
})
export class Image360CreateComponent implements OnInit {
  /** The image 360 */
  image360 = new Image360();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the image 360 is created */
  onCreate(image360: Image360): void {
    this.router.navigateByUrl(`/image-360/${image360.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
