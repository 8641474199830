import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {MultiChoiceCategorySearchParams} from '../multi-choice-category-search-params';
import {MultiChoiceCategoryService} from '../multi-choice-category.service';
import {MultiChoiceCategory} from '../multi-choice-category';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-multi-choice-category-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class MultiChoiceCategorySelectComponent extends EntitySelectComponent< MultiChoiceCategory> {
  protected modelName = 'multi_choice_category';
  /**
   * Constructor
   * @param {MultiChoiceCategoryService} multiChoiceCategoryService
   * @param {ErrorService} errorService
   */
  constructor(private multiChoiceCategoryService: MultiChoiceCategoryService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof MultiChoiceCategory)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<MultiChoiceCategory[]>this.model).push(<MultiChoiceCategory>model);
          } else {
            this.model = <MultiChoiceCategory>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< MultiChoiceCategory[]> {
      const params = new MultiChoiceCategorySearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.multiChoiceCategoryService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< MultiChoiceCategory> {
      return await this.multiChoiceCategoryService.get(id);
  }
}
