import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
export interface MediaInterface extends BaseModelInterface {
  title: string;
  thumbnail?: (string | Image | ImageInterface);
  type: string;
  created_at: number | Date;
}
export interface MediaPayload {
  title: string;
  thumbnail?: (string | Image | ImageInterface);
}
type MediaPayloadKey = keyof MediaPayload;
export class Media extends BaseModel< MediaInterface, MediaPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.title}`;
  }
  /** Denotes if the instance of thumbnail has been populated */
  thumbnailExists(): boolean {
    return !!this.props &&
        this.props.thumbnail instanceof Image &&
        this.props.thumbnail.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: MediaInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.thumbnail !== null && typeof this.props.thumbnail === 'object') {
        this.props.thumbnail = new Image(< ImageInterface>this.props.thumbnail);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MediaInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.thumbnail === 'undefined') {
        props.thumbnail = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.thumbnail !== null && props.thumbnail instanceof Image) {
        props.thumbnail = props.thumbnail.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MediaPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MediaInterface);
    payload.thumbnail = payload.thumbnail ? this.extractId(payload.thumbnail) : null;
    return payload as MediaPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MediaPayloadKey[] {
  	return [
      'title',
      'thumbnail',
  	];
  }
}
