import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
import {Pdf, PdfInterface} from '../pdf/pdf';
export interface MediaPDFInterface extends BaseModelInterface {
  title: string;
  thumbnail?: (string | Image | ImageInterface);
  pdf: (string | Pdf | PdfInterface);
  created_at: number | Date;
}
export interface MediaPDFPayload {
  title: string;
  thumbnail?: (string | Image | ImageInterface);
  pdf: (string | Pdf | PdfInterface);
}
type MediaPDFPayloadKey = keyof MediaPDFPayload;
export class MediaPDF extends BaseModel< MediaPDFInterface, MediaPDFPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.title}`;
  }
  /** Denotes if the instance of thumbnail has been populated */
  thumbnailExists(): boolean {
    return !!this.props &&
        this.props.thumbnail instanceof Image &&
        this.props.thumbnail.exists();
  }
  /** Denotes if the instance of pdf has been populated */
  pdfExists(): boolean {
    return !!this.props &&
        this.props.pdf instanceof Pdf &&
        this.props.pdf.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: MediaPDFInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.thumbnail !== null && typeof this.props.thumbnail === 'object') {
        this.props.thumbnail = new Image(< ImageInterface>this.props.thumbnail);
    }
    if (typeof this.props.pdf === 'object') {
        this.props.pdf = new Pdf(< PdfInterface>this.props.pdf);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MediaPDFInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.thumbnail === 'undefined') {
        props.thumbnail = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.thumbnail !== null && props.thumbnail instanceof Image) {
        props.thumbnail = props.thumbnail.toObject();
    }
    if (props.pdf instanceof Pdf) {
        props.pdf = props.pdf.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MediaPDFPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MediaPDFInterface);
    payload.thumbnail = payload.thumbnail ? this.extractId(payload.thumbnail) : null;
    payload.pdf = payload.pdf ? this.extractId(payload.pdf) : null;
    return payload as MediaPDFPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MediaPDFPayloadKey[] {
  	return [
      'title',
      'thumbnail',
      'pdf',
  	];
  }
}
