import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MediaImage} from '../../media-image';

@Component({
  selector: 'hpf-media-image-create',
  templateUrl: './media-image-create.component.html'
})
export class MediaImageCreateComponent implements OnInit {
  /** The media image */
  mediaImage = new MediaImage();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media image is created */
  onCreate(mediaImage: MediaImage): void {
    this.router.navigateByUrl(`/media-image/${mediaImage.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
