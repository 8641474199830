import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Media360Video} from '../../media-360-video';
import {Media360VideoService} from '../../media-360-video.service';
@Component({
  selector: 'hpf-media-360-video-edit',
  templateUrl: './media-360-video-edit.component.html'
})
export class Media360VideoEditComponent implements OnInit, OnDestroy {
  /** The media 360 video */
  media360Video = new Media360Video();
  /** The media 360 video */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private media360VideoService: Media360VideoService) {}
  /** Init */
  ngOnInit() {
    // Get the media 360 video after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch media 360 video from API */
  private refresh() {
    this.media360VideoService.get(this.id, { _populate: ['*'] })
      .then((media360Video: Media360Video) => {
        this.media360Video.fromObject(media360Video.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the media 360 video is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the media 360 video is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/media-360-video')
      .catch((error) => this.errorService.handle(error));
  }
}
