import {
	Component,
	EventEmitter,
	ViewChildren,
	Input,
	OnInit,
	OnDestroy,
	Output,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { environment } from '@env/environment';
import { OralCategory } from '../oral-category';
import { OralCategoryService } from '../oral-category.service';
import { Helpers } from '@app/shared';
@Component({
	selector: 'hpf-oral-category-mini-form',
	templateUrl: './oral-category-mini-form.component.html',
})
export class OralCategoryMiniFormComponent implements OnInit {
	@Input() value: OralCategory;

	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;

	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private oralCategoryService: OralCategoryService
	) {}

	ngOnInit() {
		this.init.next(this);

		this.form = this.formBuilder.group({
			name: new FormControl(this.value.props.name, [Validators.required]),
			answers: new FormControl(this.value.props.answers, []),
			foreground_image: new FormControl(
				this.value.props.foreground_image,
				[]
			),
			icon: new FormControl(this.value.props.icon, []),
			should_shrink: new FormControl(this.value.props.should_shrink, [
				Validators.required,
			]),
			color: new FormControl(this.value.props.color, [
				Validators.required,
			]),
		});
	}
	public async submit() {
		this.updateModel();
		if (this.value.isNew()) {
			var payload = this.value.toPayload();
			var created = await this.oralCategoryService.create(payload);
			this.value.props._id = created.getId();
		} else {
			var payload = this.value.toPayload();
			await this.oralCategoryService.update(this.value.getId(), payload);
		}
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.value.props[key] = this.form.get(key).value;
		}
		if (typeof this.value.props.answers === 'string') {
			this.value.props.answers = this.value.props.answers
				? JSON.parse(this.value.props.answers)
				: null;
		}
	}
	private updateForm(): void {
		this.form.setValue({
			name: this.value.props.name,
			answers: this.value.props.answers,
			foreground_image: this.value.props.foreground_image,
			icon: this.value.props.icon,
			should_shrink: this.value.props.should_shrink,
			color: this.value.props.color,
		});
	}
	@Output() init = new EventEmitter<OralCategoryMiniFormComponent>();
}
