import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-multimedia-module',
  templateUrl: './multimedia-module.component.html'
})
export class MultimediaModuleComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
