<nz-modal [(nzVisible)]="deleteModal" (nzOnCancel)="deleteModal = false" (nzOnOk)="onDelete()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<form nz-form class="media-video-form w-100" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()"
  [nzAutoTips]="explainErrorsService.autoTips">
  <nz-affix>
    <div class="py-4 border-bottom bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Title -->
            <div>
              <h1 class="mb-0">
                <span *ngIf="mediaVideo.isNew()">{{ 'media-video_create-form-title' | translate }}</span>
                <span *ngIf="!mediaVideo.isNew()">{{ mediaVideo.getLabel() }}</span>
              </h1>
              <small *ngIf="!mediaVideo.isNew()"
                class="text-black-50">{{ 'common_id' | translate: { id : mediaVideo.getId() } }}</small>
            </div>
            <!-- /Title -->

            <!-- Actions -->
            <div>
              <button nz-button [disabled]="form.invalid" [nzLoading]="saving" nzType="primary" *appRestrictedByScope="{
                                    model: 'media_video',
                                    action: 'update'
                                }">
                {{ 'common_save' | translate }}
              </button>
              <ng-container *appRestrictedByScope="{
                                model: 'media_video',
                                action: 'delete'
                            }">
                <button *ngIf="!mediaVideo.isNew() && deletable" class="ml-4" nz-button nzShape="circle"
                  [title]="'common_delete' | translate" [nzLoading]="deleting" (click)="deleteModal = true"
                  type="button">
                  <i nz-icon nzType="delete"></i>
                </button>
              </ng-container>
            </div>
            <!-- /Actions -->
          </div>
        </div>
      </div>
    </div>
  </nz-affix>
  <div class="container">
    <nz-card class="round-border my-5">
      <div *ngIf="loading" class="row">
        <div class="col-12">
          <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
          <div class="col-12  col-md-6">
            <!-- Title -->


            <nz-form-item>
              <h4>{{ 'media-video_title' | translate }}</h4>
              <nz-form-control>


                <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
                  formControlName="title">
              </nz-form-control>
            </nz-form-item>
            <!-- /Title -->
          </div>
          <div class="col-12  col-md-6">
            <!-- Thumbnail -->


            <nz-form-item>
              <h4>{{ 'media-video_thumbnail' | translate }}</h4>
              <nz-form-control [nzValidateStatus]="form.get('thumbnail')">



                <app-image-modal-button formControlName="thumbnail"></app-image-modal-button>
              </nz-form-control>
            </nz-form-item>
            <!-- /Thumbnail -->
          </div>
          <div class="col-12  col-md-6">
            <!-- Video -->


            <nz-form-item>
              <h4>{{ 'media-video_video' | translate }}</h4>
              <nz-form-control [nzValidateStatus]="form.get('video')">



                <hpf-video-select [controlName]="'video'" [formGroup]="form" [quickFormEnabled]="quickFormEnabled"
                  [(model)]="mediaVideo.props.video" [filterEnabled]="true"
                  [placeholder]="'video_common_search-placeholder' | translate"
                  [emptyLabel]="'common_empty' | translate">
                </hpf-video-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Video -->
          </div>
          <div class="col-12  col-md-6">
            <!-- Subtitles -->


            <nz-form-item>
              <h4>{{ 'media-video_subtitles' | translate }}</h4>
              <nz-form-control>

                <textarea class="w-100" nz-input [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                  [placeholder]="'common_value-string' | translate" formControlName="subtitles"></textarea>

              </nz-form-control>
            </nz-form-item>
            <!-- /Subtitles -->
          </div>
        </div>

        <nz-divider *ngIf="!mediaVideo.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!mediaVideo.isNew()" class="row">
          <div class="col-12 col-md-6">
            <!-- _Id -->
            <div class="mb-4">
              <h4>{{ 'media-video_-id' | translate }}</h4>
              <div>{{ mediaVideo.props._id }}</div>
            </div>
            <!-- /_Id -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Title -->
            <div class="mb-4">
              <h4>{{ 'media-video_title' | translate }}</h4>
              <div>{{ mediaVideo.props.title }}</div>
            </div>
            <!-- /Title -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Thumbnail -->
            <div class="mb-4">
              <h4>{{ 'media-video_thumbnail' | translate }}</h4>
              <div *ngIf="mediaVideo.thumbnailExists()">
                <a [routerLink]="['/image', mediaVideo.props.thumbnail.getId()]">
                  {{ mediaVideo.props.thumbnail.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Thumbnail -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Video -->
            <div class="mb-4">
              <h4>{{ 'media-video_video' | translate }}</h4>
              <div *ngIf="mediaVideo.videoExists()">
                <a [routerLink]="['/video', mediaVideo.props.video.getId()]">
                  {{ mediaVideo.props.video.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Video -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Subtitles -->
            <div class="mb-4">
              <h4>{{ 'media-video_subtitles' | translate }}</h4>
              <div>{{ mediaVideo.props.subtitles }}</div>
            </div>
            <!-- /Subtitles -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Created At -->
            <div class="mb-4">
              <h4>{{ 'media-video_created-at' | translate }}</h4>
              <div>{{ mediaVideo.props.created_at | date:'medium' }}</div>
            </div>
            <!-- /Created At -->
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</form>
