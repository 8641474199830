import {Injectable} from '@angular/core';
import {OralQuizModule, OralQuizModuleInterface, OralQuizModulePayload} from './oral-quiz-module';
import {OralQuizModuleSearchParamsInterface} from './oral-quiz-module-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class OralQuizModuleService extends BaseModelService< OralQuizModule, OralQuizModuleInterface, OralQuizModulePayload, OralQuizModuleSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'oral-quiz-module';
  }
  /** @inheritDoc */
  protected new(object: OralQuizModuleInterface): OralQuizModule {
    return new OralQuizModule(object);
  }
}
