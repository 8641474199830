import {Injectable} from '@angular/core';
import {Media, MediaInterface, MediaPayload} from './media';
import {MediaSearchParamsInterface} from './media-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MediaService extends BaseModelService< Media, MediaInterface, MediaPayload, MediaSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media';
  }
  /** @inheritDoc */
  protected new(object: MediaInterface): Media {
    return new Media(object);
  }
}
