import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ActivityModuleComponent} from './activity-module/activity-module/activity-module.component';
import {ActivityModuleCreateComponent} from './activity-module/views/activity-module-create/activity-module-create.component';
import {ActivityModuleEditComponent} from './activity-module/views/activity-module-edit/activity-module-edit.component';
import {ActivityModuleListComponent} from './activity-module/views/activity-module-list/activity-module-list.component';
import {AudioComponent} from './audio/audio/audio.component';
import {AudioCreateComponent} from './audio/views/audio-create/audio-create.component';
import {AudioEditComponent} from './audio/views/audio-edit/audio-edit.component';
import {AudioListComponent} from './audio/views/audio-list/audio-list.component';
import {ClientInfoComponent} from './client-info/client-info/client-info.component';
import {ClientInfoCreateComponent} from './client-info/views/client-info-create/client-info-create.component';
import {ClientInfoEditComponent} from './client-info/views/client-info-edit/client-info-edit.component';
import {ClientInfoListComponent} from './client-info/views/client-info-list/client-info-list.component';
import {ImageComponent} from './image/image/image.component';
import {ImageCreateComponent} from './image/views/image-create/image-create.component';
import {ImageEditComponent} from './image/views/image-edit/image-edit.component';
import {ImageListComponent} from './image/views/image-list/image-list.component';
import {Image360Component} from './image-360/image-360/image-360.component';
import {Image360CreateComponent} from './image-360/views/image-360-create/image-360-create.component';
import {Image360EditComponent} from './image-360/views/image-360-edit/image-360-edit.component';
import {Image360ListComponent} from './image-360/views/image-360-list/image-360-list.component';
import {InstructionComponent} from './instruction/instruction/instruction.component';
import {InstructionCreateComponent} from './instruction/views/instruction-create/instruction-create.component';
import {InstructionEditComponent} from './instruction/views/instruction-edit/instruction-edit.component';
import {InstructionListComponent} from './instruction/views/instruction-list/instruction-list.component';
import {ItineraryComponent} from './itinerary/itinerary/itinerary.component';
import {ItineraryCreateComponent} from './itinerary/views/itinerary-create/itinerary-create.component';
import {ItineraryEditComponent} from './itinerary/views/itinerary-edit/itinerary-edit.component';
import {ItineraryListComponent} from './itinerary/views/itinerary-list/itinerary-list.component';
import {MediaComponent} from './media/media/media.component';
import {MediaCreateComponent} from './media/views/media-create/media-create.component';
import {MediaEditComponent} from './media/views/media-edit/media-edit.component';
import {MediaListComponent} from './media/views/media-list/media-list.component';
import {Media360ImageComponent} from './media-360-image/media-360-image/media-360-image.component';
import {Media360ImageCreateComponent} from './media-360-image/views/media-360-image-create/media-360-image-create.component';
import {Media360ImageEditComponent} from './media-360-image/views/media-360-image-edit/media-360-image-edit.component';
import {Media360ImageListComponent} from './media-360-image/views/media-360-image-list/media-360-image-list.component';
import {Media360VideoComponent} from './media-360-video/media-360-video/media-360-video.component';
import {Media360VideoCreateComponent} from './media-360-video/views/media-360-video-create/media-360-video-create.component';
import {Media360VideoEditComponent} from './media-360-video/views/media-360-video-edit/media-360-video-edit.component';
import {Media360VideoListComponent} from './media-360-video/views/media-360-video-list/media-360-video-list.component';
import {MediaAudioComponent} from './media-audio/media-audio/media-audio.component';
import {MediaAudioCreateComponent} from './media-audio/views/media-audio-create/media-audio-create.component';
import {MediaAudioEditComponent} from './media-audio/views/media-audio-edit/media-audio-edit.component';
import {MediaAudioListComponent} from './media-audio/views/media-audio-list/media-audio-list.component';
import {MediaImageComponent} from './media-image/media-image/media-image.component';
import {MediaImageCreateComponent} from './media-image/views/media-image-create/media-image-create.component';
import {MediaImageEditComponent} from './media-image/views/media-image-edit/media-image-edit.component';
import {MediaImageListComponent} from './media-image/views/media-image-list/media-image-list.component';
import {MediaPDFComponent} from './media-pdf/media-pdf/media-pdf.component';
import {MediaPDFCreateComponent} from './media-pdf/views/media-pdf-create/media-pdf-create.component';
import {MediaPDFEditComponent} from './media-pdf/views/media-pdf-edit/media-pdf-edit.component';
import {MediaPDFListComponent} from './media-pdf/views/media-pdf-list/media-pdf-list.component';
import {MediaVideoComponent} from './media-video/media-video/media-video.component';
import {MediaVideoCreateComponent} from './media-video/views/media-video-create/media-video-create.component';
import {MediaVideoEditComponent} from './media-video/views/media-video-edit/media-video-edit.component';
import {MediaVideoListComponent} from './media-video/views/media-video-list/media-video-list.component';
import {ModuleComponent} from './module/module/module.component';
import {ModuleCreateComponent} from './module/views/module-create/module-create.component';
import {ModuleEditComponent} from './module/views/module-edit/module-edit.component';
import {ModuleListComponent} from './module/views/module-list/module-list.component';
import {MultiChoiceCategoryComponent} from './multi-choice-category/multi-choice-category/multi-choice-category.component';
import {MultiChoiceCategoryCreateComponent} from './multi-choice-category/views/multi-choice-category-create/multi-choice-category-create.component';
import {MultiChoiceCategoryEditComponent} from './multi-choice-category/views/multi-choice-category-edit/multi-choice-category-edit.component';
import {MultiChoiceCategoryListComponent} from './multi-choice-category/views/multi-choice-category-list/multi-choice-category-list.component';
import {MultiChoiceQuizModuleComponent} from './multi-choice-quiz-module/multi-choice-quiz-module/multi-choice-quiz-module.component';
import {MultiChoiceQuizModuleCreateComponent} from './multi-choice-quiz-module/views/multi-choice-quiz-module-create/multi-choice-quiz-module-create.component';
import {MultiChoiceQuizModuleEditComponent} from './multi-choice-quiz-module/views/multi-choice-quiz-module-edit/multi-choice-quiz-module-edit.component';
import {MultiChoiceQuizModuleListComponent} from './multi-choice-quiz-module/views/multi-choice-quiz-module-list/multi-choice-quiz-module-list.component';
import {MultiElementComponent} from './multi-element/multi-element/multi-element.component';
import {MultiElementCreateComponent} from './multi-element/views/multi-element-create/multi-element-create.component';
import {MultiElementEditComponent} from './multi-element/views/multi-element-edit/multi-element-edit.component';
import {MultiElementListComponent} from './multi-element/views/multi-element-list/multi-element-list.component';
import {MultimediaModuleComponent} from './multimedia-module/multimedia-module/multimedia-module.component';
import {MultimediaModuleCreateComponent} from './multimedia-module/views/multimedia-module-create/multimedia-module-create.component';
import {MultimediaModuleEditComponent} from './multimedia-module/views/multimedia-module-edit/multimedia-module-edit.component';
import {MultimediaModuleListComponent} from './multimedia-module/views/multimedia-module-list/multimedia-module-list.component';
import {OralCategoryComponent} from './oral-category/oral-category/oral-category.component';
import {OralCategoryCreateComponent} from './oral-category/views/oral-category-create/oral-category-create.component';
import {OralCategoryEditComponent} from './oral-category/views/oral-category-edit/oral-category-edit.component';
import {OralCategoryListComponent} from './oral-category/views/oral-category-list/oral-category-list.component';
import {OralQuizModuleComponent} from './oral-quiz-module/oral-quiz-module/oral-quiz-module.component';
import {OralQuizModuleCreateComponent} from './oral-quiz-module/views/oral-quiz-module-create/oral-quiz-module-create.component';
import {OralQuizModuleEditComponent} from './oral-quiz-module/views/oral-quiz-module-edit/oral-quiz-module-edit.component';
import {OralQuizModuleListComponent} from './oral-quiz-module/views/oral-quiz-module-list/oral-quiz-module-list.component';
import {PdfComponent} from './pdf/pdf/pdf.component';
import {PdfCreateComponent} from './pdf/views/pdf-create/pdf-create.component';
import {PdfEditComponent} from './pdf/views/pdf-edit/pdf-edit.component';
import {PdfListComponent} from './pdf/views/pdf-list/pdf-list.component';
import {PhotoModuleComponent} from './photo-module/photo-module/photo-module.component';
import {PhotoModuleCreateComponent} from './photo-module/views/photo-module-create/photo-module-create.component';
import {PhotoModuleEditComponent} from './photo-module/views/photo-module-edit/photo-module-edit.component';
import {PhotoModuleListComponent} from './photo-module/views/photo-module-list/photo-module-list.component';
import {PointOfInterestComponent} from './point-of-interest/point-of-interest/point-of-interest.component';
import {PointOfInterestCreateComponent} from './point-of-interest/views/point-of-interest-create/point-of-interest-create.component';
import {PointOfInterestEditComponent} from './point-of-interest/views/point-of-interest-edit/point-of-interest-edit.component';
import {PointOfInterestListComponent} from './point-of-interest/views/point-of-interest-list/point-of-interest-list.component';
import {UserComponent} from './user/user/user.component';
import {UserCreateComponent} from './user/views/user-create/user-create.component';
import {UserEditComponent} from './user/views/user-edit/user-edit.component';
import {UserListComponent} from './user/views/user-list/user-list.component';
import {VideoComponent} from './video/video/video.component';
import {VideoCreateComponent} from './video/views/video-create/video-create.component';
import {VideoEditComponent} from './video/views/video-edit/video-edit.component';
import {VideoListComponent} from './video/views/video-list/video-list.component';
import { CheckPermissionGuard } from '@app/guards';

const routes: Routes = [
  {
    path: 'activity-module',
    component: ActivityModuleComponent,
    children: [
      {
        path: '',
        component: ActivityModuleListComponent,
        data: { title: 'List activity module', model: 'activity_module', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: ActivityModuleCreateComponent,
        data: { title: 'Create activity module', model: 'activity_module', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: ActivityModuleEditComponent,
        data: { title: 'Edit activity module', model: 'activity_module', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'audio',
    component: AudioComponent,
    children: [
      {
        path: '',
        component: AudioListComponent,
        data: { title: 'List audio', model: 'audio', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: AudioCreateComponent,
        data: { title: 'Create audio', model: 'audio', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: AudioEditComponent,
        data: { title: 'Edit audio', model: 'audio', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'client-info',
    component: ClientInfoComponent,
    children: [
      {
        path: '',
        component: ClientInfoListComponent,
        data: { title: 'List client info', model: 'client_info', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: ClientInfoCreateComponent,
        data: { title: 'Create client info', model: 'client_info', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: ClientInfoEditComponent,
        data: { title: 'Edit client info', model: 'client_info', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'image',
    component: ImageComponent,
    children: [
      {
        path: '',
        component: ImageListComponent,
        data: { title: 'List image', model: 'image', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: ImageCreateComponent,
        data: { title: 'Create image', model: 'image', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: ImageEditComponent,
        data: { title: 'Edit image', model: 'image', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'image-360',
    component: Image360Component,
    children: [
      {
        path: '',
        component: Image360ListComponent,
        data: { title: 'List image 360', model: 'image_360', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: Image360CreateComponent,
        data: { title: 'Create image 360', model: 'image_360', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: Image360EditComponent,
        data: { title: 'Edit image 360', model: 'image_360', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'instruction',
    component: InstructionComponent,
    children: [
      {
        path: '',
        component: InstructionListComponent,
        data: { title: 'List instruction', model: 'instruction', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: InstructionCreateComponent,
        data: { title: 'Create instruction', model: 'instruction', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: InstructionEditComponent,
        data: { title: 'Edit instruction', model: 'instruction', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'itinerary',
    component: ItineraryComponent,
    children: [
      {
        path: '',
        component: ItineraryListComponent,
        data: { title: 'List itinerary', model: 'itinerary', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: ItineraryCreateComponent,
        data: { title: 'Create itinerary', model: 'itinerary', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: ItineraryEditComponent,
        data: { title: 'Edit itinerary', model: 'itinerary', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media',
    component: MediaComponent,
    children: [
      {
        path: '',
        component: MediaListComponent,
        data: { title: 'List media', model: 'media', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MediaCreateComponent,
        data: { title: 'Create media', model: 'media', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MediaEditComponent,
        data: { title: 'Edit media', model: 'media', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media-360-image',
    component: Media360ImageComponent,
    children: [
      {
        path: '',
        component: Media360ImageListComponent,
        data: { title: 'List media 360 image', model: 'media_360_image', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: Media360ImageCreateComponent,
        data: { title: 'Create media 360 image', model: 'media_360_image', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: Media360ImageEditComponent,
        data: { title: 'Edit media 360 image', model: 'media_360_image', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media-360-video',
    component: Media360VideoComponent,
    children: [
      {
        path: '',
        component: Media360VideoListComponent,
        data: { title: 'List media 360 video', model: 'media_360_video', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: Media360VideoCreateComponent,
        data: { title: 'Create media 360 video', model: 'media_360_video', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: Media360VideoEditComponent,
        data: { title: 'Edit media 360 video', model: 'media_360_video', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media-audio',
    component: MediaAudioComponent,
    children: [
      {
        path: '',
        component: MediaAudioListComponent,
        data: { title: 'List media audio', model: 'media_audio', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MediaAudioCreateComponent,
        data: { title: 'Create media audio', model: 'media_audio', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MediaAudioEditComponent,
        data: { title: 'Edit media audio', model: 'media_audio', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media-image',
    component: MediaImageComponent,
    children: [
      {
        path: '',
        component: MediaImageListComponent,
        data: { title: 'List media image', model: 'media_image', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MediaImageCreateComponent,
        data: { title: 'Create media image', model: 'media_image', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MediaImageEditComponent,
        data: { title: 'Edit media image', model: 'media_image', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media-pdf',
    component: MediaPDFComponent,
    children: [
      {
        path: '',
        component: MediaPDFListComponent,
        data: { title: 'List media pdf', model: 'media_pdf', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MediaPDFCreateComponent,
        data: { title: 'Create media pdf', model: 'media_pdf', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MediaPDFEditComponent,
        data: { title: 'Edit media pdf', model: 'media_pdf', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'media-video',
    component: MediaVideoComponent,
    children: [
      {
        path: '',
        component: MediaVideoListComponent,
        data: { title: 'List media video', model: 'media_video', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MediaVideoCreateComponent,
        data: { title: 'Create media video', model: 'media_video', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MediaVideoEditComponent,
        data: { title: 'Edit media video', model: 'media_video', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'module',
    component: ModuleComponent,
    children: [
      {
        path: '',
        component: ModuleListComponent,
        data: { title: 'List module', model: 'module', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: ModuleCreateComponent,
        data: { title: 'Create module', model: 'module', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: ModuleEditComponent,
        data: { title: 'Edit module', model: 'module', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'multi-choice-category',
    component: MultiChoiceCategoryComponent,
    children: [
      {
        path: '',
        component: MultiChoiceCategoryListComponent,
        data: { title: 'List multi choice category', model: 'multi_choice_category', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MultiChoiceCategoryCreateComponent,
        data: { title: 'Create multi choice category', model: 'multi_choice_category', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MultiChoiceCategoryEditComponent,
        data: { title: 'Edit multi choice category', model: 'multi_choice_category', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'multi-choice-quiz-module',
    component: MultiChoiceQuizModuleComponent,
    children: [
      {
        path: '',
        component: MultiChoiceQuizModuleListComponent,
        data: { title: 'List multi choice quiz module', model: 'multi_choice_quiz_module', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MultiChoiceQuizModuleCreateComponent,
        data: { title: 'Create multi choice quiz module', model: 'multi_choice_quiz_module', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MultiChoiceQuizModuleEditComponent,
        data: { title: 'Edit multi choice quiz module', model: 'multi_choice_quiz_module', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'multi-element',
    component: MultiElementComponent,
    children: [
      {
        path: '',
        component: MultiElementListComponent,
        data: { title: 'List multi element', model: 'multi_element', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MultiElementCreateComponent,
        data: { title: 'Create multi element', model: 'multi_element', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MultiElementEditComponent,
        data: { title: 'Edit multi element', model: 'multi_element', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'multimedia-module',
    component: MultimediaModuleComponent,
    children: [
      {
        path: '',
        component: MultimediaModuleListComponent,
        data: { title: 'List multimedia module', model: 'multimedia_module', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: MultimediaModuleCreateComponent,
        data: { title: 'Create multimedia module', model: 'multimedia_module', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: MultimediaModuleEditComponent,
        data: { title: 'Edit multimedia module', model: 'multimedia_module', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'oral-category',
    component: OralCategoryComponent,
    children: [
      {
        path: '',
        component: OralCategoryListComponent,
        data: { title: 'List oral category', model: 'oral_category', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: OralCategoryCreateComponent,
        data: { title: 'Create oral category', model: 'oral_category', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: OralCategoryEditComponent,
        data: { title: 'Edit oral category', model: 'oral_category', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'oral-quiz-module',
    component: OralQuizModuleComponent,
    children: [
      {
        path: '',
        component: OralQuizModuleListComponent,
        data: { title: 'List oral quiz module', model: 'oral_quiz_module', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: OralQuizModuleCreateComponent,
        data: { title: 'Create oral quiz module', model: 'oral_quiz_module', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: OralQuizModuleEditComponent,
        data: { title: 'Edit oral quiz module', model: 'oral_quiz_module', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'pdf',
    component: PdfComponent,
    children: [
      {
        path: '',
        component: PdfListComponent,
        data: { title: 'List pdf', model: 'pdf', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: PdfCreateComponent,
        data: { title: 'Create pdf', model: 'pdf', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: PdfEditComponent,
        data: { title: 'Edit pdf', model: 'pdf', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'photo-module',
    component: PhotoModuleComponent,
    children: [
      {
        path: '',
        component: PhotoModuleListComponent,
        data: { title: 'List photo module', model: 'photo_module', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: PhotoModuleCreateComponent,
        data: { title: 'Create photo module', model: 'photo_module', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: PhotoModuleEditComponent,
        data: { title: 'Edit photo module', model: 'photo_module', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'point-of-interest',
    component: PointOfInterestComponent,
    children: [
      {
        path: '',
        component: PointOfInterestListComponent,
        data: { title: 'List point of interest', model: 'point_of_interest', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: PointOfInterestCreateComponent,
        data: { title: 'Create point of interest', model: 'point_of_interest', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: PointOfInterestEditComponent,
        data: { title: 'Edit point of interest', model: 'point_of_interest', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
        path: '',
        component: UserListComponent,
        data: { title: 'List user', model: 'user', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: UserCreateComponent,
        data: { title: 'Create user', model: 'user', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: UserEditComponent,
        data: { title: 'Edit user', model: 'user', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
  {
    path: 'video',
    component: VideoComponent,
    children: [
      {
        path: '',
        component: VideoListComponent,
        data: { title: 'List video', model: 'video', action: 'search' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: 'create',
        component: VideoCreateComponent,
        data: { title: 'Create video', model: 'video', action: 'create' },
				canActivate: [CheckPermissionGuard]
      },
      {
        path: ':id',
        component: VideoEditComponent,
        data: { title: 'Edit video', model: 'video', action: 'read' },
				canActivate: [CheckPermissionGuard]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModelsRoutingModule {
}
