import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-itinerary',
  templateUrl: './itinerary.component.html'
})
export class ItineraryComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
