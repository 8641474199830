import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'color-input',
	templateUrl: './color-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ColorInputComponent,
			multi: true,
		},
	],
})
export class ColorInputComponent implements ControlValueAccessor {
	@Input() value: string;

	onChange: Function;

	writeValue(value: any) {
		this.value = value;
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}
	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}

	registerOnTouched(fn: Function) {}
	onChangedColor(color) {
		this.value = color;
		this.onChange(this.value);
	}
}
