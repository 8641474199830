import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {MediaVideo} from '../media-video';
import {MediaVideoService} from '../media-video.service';
@Component({
  selector: 'hpf-media-video-mini-form',
  templateUrl: './media-video-mini-form.component.html'
})
export class MediaVideoMiniFormComponent implements OnInit{

    @Input() value: MediaVideo;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private mediaVideoService: MediaVideoService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    title: new FormControl(this.value.props.title, [Validators.required]),
                    thumbnail: new FormControl(this.value.props.thumbnail, []),
                    video: new FormControl(this.value.props.video, [Validators.required]),
                    subtitles: new FormControl(this.value.props.subtitles, []),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.mediaVideoService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.mediaVideoService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'title': this.value.props.title,
     'thumbnail': this.value.props.thumbnail,
     'video': this.value.props.video,
     'subtitles': this.value.props.subtitles,
    });
  }
	@Output() init = new EventEmitter<MediaVideoMiniFormComponent>();

}
