<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Details -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_details' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="details">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Details -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Picture -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_picture' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="picture"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Picture -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Latitude -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_latitude' | translate }}</h4>
                    <nz-form-control>

         
                        <nz-input-number class="w-100"
                                         formControlName="latitude" [nzMin]="-90" [nzMax]="90"
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Latitude -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Longitude -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_longitude' | translate }}</h4>
                    <nz-form-control>

         
                        <nz-input-number class="w-100"
                                         formControlName="longitude" [nzMin]="-180" [nzMax]="180"
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Longitude -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Modules -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_modules' | translate }}</h4>
                    <nz-form-control>

         

        

 

                        <hpf-module-select
                            [controlName]="'modules'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="value.props.modules"
                            [multiple]="true"
                            [nullable]="true"
                            [filterEnabled]="true"
                            [placeholder]="'module_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-module-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Modules -->
            </div>
        </div>
  </div>
  