import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {Image360SearchParams} from '../image-360-search-params';

@Component({
  selector: 'hpf-image-360-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class Image360ListTopBarComponent extends EntityListTopBarComponent< Image360SearchParams> {
}
 