import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import {
	ActivityModuleService,
	ActivityModuleFormComponent,
	ActivityModuleFiltersComponent,
	ActivityModuleTableComponent,
	ActivityModuleSelectComponent,
	ActivityModuleListTopBarComponent,
	ActivityModuleCountComponent,
	ActivityModuleFormArrayComponent,
	ActivityModuleMiniFormComponent,
} from './activity-module';
import {
	AudioService,
	AudioFormComponent,
	AudioFiltersComponent,
	AudioTableComponent,
	AudioSelectComponent,
	AudioListTopBarComponent,
	AudioCountComponent,
	AudioFormArrayComponent,
	AudioMiniFormComponent,
} from './audio';
import {
	ClientInfoService,
	ClientInfoFormComponent,
	ClientInfoFiltersComponent,
	ClientInfoTableComponent,
	ClientInfoSelectComponent,
	ClientInfoListTopBarComponent,
	ClientInfoCountComponent,
	ClientInfoFormArrayComponent,
	ClientInfoMiniFormComponent,
} from './client-info';
import {
	ImageService,
	ImageFormComponent,
	ImageFiltersComponent,
	ImageTableComponent,
	ImageSelectComponent,
	ImageListTopBarComponent,
	ImageCountComponent,
	ImageFormArrayComponent,
	ImageMiniFormComponent,
	ImageModalButtonComponent,
} from './image';

import {
	Image360Service,
	Image360FormComponent,
	Image360FiltersComponent,
	Image360TableComponent,
	Image360SelectComponent,
	Image360ListTopBarComponent,
	Image360CountComponent,
	Image360FormArrayComponent,
	Image360MiniFormComponent,
} from './image-360';
import {
	InstructionService,
	InstructionFormComponent,
	InstructionFiltersComponent,
	InstructionTableComponent,
	InstructionSelectComponent,
	InstructionListTopBarComponent,
	InstructionCountComponent,
	InstructionFormArrayComponent,
	InstructionMiniFormComponent,
} from './instruction';
import {
	ItineraryService,
	ItineraryFormComponent,
	ItineraryFiltersComponent,
	ItineraryTableComponent,
	ItinerarySelectComponent,
	ItineraryListTopBarComponent,
	ItineraryCountComponent,
	ItineraryFormArrayComponent,
	ItineraryMiniFormComponent,
} from './itinerary';
import {
	MediaService,
	MediaFormComponent,
	MediaFiltersComponent,
	MediaTableComponent,
	MediaSelectComponent,
	MediaListTopBarComponent,
	MediaCountComponent,
	MediaFormArrayComponent,
	MediaMiniFormComponent,
} from './media';
import {
	Media360ImageService,
	Media360ImageFormComponent,
	Media360ImageFiltersComponent,
	Media360ImageTableComponent,
	Media360ImageSelectComponent,
	Media360ImageListTopBarComponent,
	Media360ImageCountComponent,
	Media360ImageFormArrayComponent,
	Media360ImageMiniFormComponent,
} from './media-360-image';
import {
	Media360VideoService,
	Media360VideoFormComponent,
	Media360VideoFiltersComponent,
	Media360VideoTableComponent,
	Media360VideoSelectComponent,
	Media360VideoListTopBarComponent,
	Media360VideoCountComponent,
	Media360VideoFormArrayComponent,
	Media360VideoMiniFormComponent,
} from './media-360-video';
import {
	MediaAudioService,
	MediaAudioFormComponent,
	MediaAudioFiltersComponent,
	MediaAudioTableComponent,
	MediaAudioSelectComponent,
	MediaAudioListTopBarComponent,
	MediaAudioCountComponent,
	MediaAudioFormArrayComponent,
	MediaAudioMiniFormComponent,
} from './media-audio';
import {
	MediaImageService,
	MediaImageFormComponent,
	MediaImageFiltersComponent,
	MediaImageTableComponent,
	MediaImageSelectComponent,
	MediaImageListTopBarComponent,
	MediaImageCountComponent,
	MediaImageFormArrayComponent,
	MediaImageMiniFormComponent,
} from './media-image';
import {
	MediaPDFService,
	MediaPDFFormComponent,
	MediaPDFFiltersComponent,
	MediaPDFTableComponent,
	MediaPDFSelectComponent,
	MediaPDFListTopBarComponent,
	MediaPDFCountComponent,
	MediaPDFFormArrayComponent,
	MediaPDFMiniFormComponent,
} from './media-pdf';
import {
	MediaVideoService,
	MediaVideoFormComponent,
	MediaVideoFiltersComponent,
	MediaVideoTableComponent,
	MediaVideoSelectComponent,
	MediaVideoListTopBarComponent,
	MediaVideoCountComponent,
	MediaVideoFormArrayComponent,
	MediaVideoMiniFormComponent,
} from './media-video';
import {
	ModuleService,
	ModuleFormComponent,
	ModuleFiltersComponent,
	ModuleTableComponent,
	ModuleSelectComponent,
	ModuleListTopBarComponent,
	ModuleCountComponent,
	ModuleFormArrayComponent,
	ModuleMiniFormComponent,
} from './module';
import {
	MultiChoiceCategoryService,
	MultiChoiceCategoryFormComponent,
	MultiChoiceCategoryFiltersComponent,
	MultiChoiceCategoryTableComponent,
	MultiChoiceCategorySelectComponent,
	MultiChoiceCategoryListTopBarComponent,
	MultiChoiceCategoryCountComponent,
	MultiChoiceCategoryFormArrayComponent,
	MultiChoiceCategoryMiniFormComponent,
} from './multi-choice-category';
import {
	MultiChoiceQuizModuleService,
	MultiChoiceQuizModuleFormComponent,
	MultiChoiceQuizModuleFiltersComponent,
	MultiChoiceQuizModuleTableComponent,
	MultiChoiceQuizModuleSelectComponent,
	MultiChoiceQuizModuleListTopBarComponent,
	MultiChoiceQuizModuleCountComponent,
	MultiChoiceQuizModuleFormArrayComponent,
	MultiChoiceQuizModuleMiniFormComponent,
} from './multi-choice-quiz-module';
import {
	MultiElementService,
	MultiElementFormComponent,
	MultiElementFiltersComponent,
	MultiElementTableComponent,
	MultiElementSelectComponent,
	MultiElementListTopBarComponent,
	MultiElementCountComponent,
	MultiElementFormArrayComponent,
	MultiElementMiniFormComponent,
} from './multi-element';
import {
	MultimediaModuleService,
	MultimediaModuleFormComponent,
	MultimediaModuleFiltersComponent,
	MultimediaModuleTableComponent,
	MultimediaModuleSelectComponent,
	MultimediaModuleListTopBarComponent,
	MultimediaModuleCountComponent,
	MultimediaModuleFormArrayComponent,
	MultimediaModuleMiniFormComponent,
} from './multimedia-module';
import {
	OralCategoryService,
	OralCategoryFormComponent,
	OralCategoryFiltersComponent,
	OralCategoryTableComponent,
	OralCategorySelectComponent,
	OralCategoryListTopBarComponent,
	OralCategoryCountComponent,
	OralCategoryFormArrayComponent,
	OralCategoryMiniFormComponent,
} from './oral-category';
import {
	OralQuizModuleService,
	OralQuizModuleFormComponent,
	OralQuizModuleFiltersComponent,
	OralQuizModuleTableComponent,
	OralQuizModuleSelectComponent,
	OralQuizModuleListTopBarComponent,
	OralQuizModuleCountComponent,
	OralQuizModuleFormArrayComponent,
	OralQuizModuleMiniFormComponent,
} from './oral-quiz-module';
import {
	PdfService,
	PdfFormComponent,
	PdfFiltersComponent,
	PdfTableComponent,
	PdfSelectComponent,
	PdfListTopBarComponent,
	PdfCountComponent,
	PdfFormArrayComponent,
	PdfMiniFormComponent,
} from './pdf';
import {
	PhotoModuleService,
	PhotoModuleFormComponent,
	PhotoModuleFiltersComponent,
	PhotoModuleTableComponent,
	PhotoModuleSelectComponent,
	PhotoModuleListTopBarComponent,
	PhotoModuleCountComponent,
	PhotoModuleFormArrayComponent,
	PhotoModuleMiniFormComponent,
} from './photo-module';
import {
	PointOfInterestService,
	PointOfInterestFormComponent,
	PointOfInterestFiltersComponent,
	PointOfInterestTableComponent,
	PointOfInterestSelectComponent,
	PointOfInterestListTopBarComponent,
	PointOfInterestCountComponent,
	PointOfInterestFormArrayComponent,
	PointOfInterestMiniFormComponent,
} from './point-of-interest';
import {
	UserService,
	UserFormComponent,
	UserFiltersComponent,
	UserTableComponent,
	UserSelectComponent,
	UserListTopBarComponent,
	UserCountComponent,
	UserFormArrayComponent,
	UserMiniFormComponent,
} from './user';
import {
	VideoService,
	VideoFormComponent,
	VideoFiltersComponent,
	VideoTableComponent,
	VideoSelectComponent,
	VideoListTopBarComponent,
	VideoCountComponent,
	VideoFormArrayComponent,
	VideoMiniFormComponent,
} from './video';
@NgModule({
	imports: [CommonModule, SharedModule, TranslateModuleLoad()],
	declarations: [
		ActivityModuleFormComponent,
		ActivityModuleFiltersComponent,
		ActivityModuleTableComponent,
		ActivityModuleSelectComponent,
		ActivityModuleListTopBarComponent,
		ActivityModuleCountComponent,
		ActivityModuleFormArrayComponent,
		ActivityModuleMiniFormComponent,
		AudioFormComponent,
		AudioFiltersComponent,
		AudioTableComponent,
		AudioSelectComponent,
		AudioListTopBarComponent,
		AudioCountComponent,
		AudioFormArrayComponent,
		AudioMiniFormComponent,
		ClientInfoFormComponent,
		ClientInfoFiltersComponent,
		ClientInfoTableComponent,
		ClientInfoSelectComponent,
		ClientInfoListTopBarComponent,
		ClientInfoCountComponent,
		ClientInfoFormArrayComponent,
		ClientInfoMiniFormComponent,
		ImageFormComponent,
		ImageFiltersComponent,
		ImageTableComponent,
		ImageSelectComponent,
		ImageListTopBarComponent,
		ImageCountComponent,
		ImageFormArrayComponent,
		ImageMiniFormComponent,
		Image360FormComponent,
		Image360FiltersComponent,
		Image360TableComponent,
		Image360SelectComponent,
		Image360ListTopBarComponent,
		Image360CountComponent,
		Image360FormArrayComponent,
		Image360MiniFormComponent,
		InstructionFormComponent,
		InstructionFiltersComponent,
		InstructionTableComponent,
		InstructionSelectComponent,
		InstructionListTopBarComponent,
		InstructionCountComponent,
		InstructionFormArrayComponent,
		InstructionMiniFormComponent,
		ItineraryFormComponent,
		ItineraryFiltersComponent,
		ItineraryTableComponent,
		ItinerarySelectComponent,
		ItineraryListTopBarComponent,
		ItineraryCountComponent,
		ItineraryFormArrayComponent,
		ItineraryMiniFormComponent,
		MediaFormComponent,
		MediaFiltersComponent,
		MediaTableComponent,
		MediaSelectComponent,
		MediaListTopBarComponent,
		MediaCountComponent,
		MediaFormArrayComponent,
		MediaMiniFormComponent,
		Media360ImageFormComponent,
		Media360ImageFiltersComponent,
		Media360ImageTableComponent,
		Media360ImageSelectComponent,
		Media360ImageListTopBarComponent,
		Media360ImageCountComponent,
		Media360ImageFormArrayComponent,
		Media360ImageMiniFormComponent,
		Media360VideoFormComponent,
		Media360VideoFiltersComponent,
		Media360VideoTableComponent,
		Media360VideoSelectComponent,
		Media360VideoListTopBarComponent,
		Media360VideoCountComponent,
		Media360VideoFormArrayComponent,
		Media360VideoMiniFormComponent,
		MediaAudioFormComponent,
		MediaAudioFiltersComponent,
		MediaAudioTableComponent,
		MediaAudioSelectComponent,
		MediaAudioListTopBarComponent,
		MediaAudioCountComponent,
		MediaAudioFormArrayComponent,
		MediaAudioMiniFormComponent,
		MediaImageFormComponent,
		MediaImageFiltersComponent,
		MediaImageTableComponent,
		MediaImageSelectComponent,
		MediaImageListTopBarComponent,
		MediaImageCountComponent,
		MediaImageFormArrayComponent,
		MediaImageMiniFormComponent,
		MediaPDFFormComponent,
		MediaPDFFiltersComponent,
		MediaPDFTableComponent,
		MediaPDFSelectComponent,
		MediaPDFListTopBarComponent,
		MediaPDFCountComponent,
		MediaPDFFormArrayComponent,
		MediaPDFMiniFormComponent,
		MediaVideoFormComponent,
		MediaVideoFiltersComponent,
		MediaVideoTableComponent,
		MediaVideoSelectComponent,
		MediaVideoListTopBarComponent,
		MediaVideoCountComponent,
		MediaVideoFormArrayComponent,
		MediaVideoMiniFormComponent,
		ModuleFormComponent,
		ModuleFiltersComponent,
		ModuleTableComponent,
		ModuleSelectComponent,
		ModuleListTopBarComponent,
		ModuleCountComponent,
		ModuleFormArrayComponent,
		ModuleMiniFormComponent,
		MultiChoiceCategoryFormComponent,
		MultiChoiceCategoryFiltersComponent,
		MultiChoiceCategoryTableComponent,
		MultiChoiceCategorySelectComponent,
		MultiChoiceCategoryListTopBarComponent,
		MultiChoiceCategoryCountComponent,
		MultiChoiceCategoryFormArrayComponent,
		MultiChoiceCategoryMiniFormComponent,
		MultiChoiceQuizModuleFormComponent,
		MultiChoiceQuizModuleFiltersComponent,
		MultiChoiceQuizModuleTableComponent,
		MultiChoiceQuizModuleSelectComponent,
		MultiChoiceQuizModuleListTopBarComponent,
		MultiChoiceQuizModuleCountComponent,
		MultiChoiceQuizModuleFormArrayComponent,
		MultiChoiceQuizModuleMiniFormComponent,
		MultiElementFormComponent,
		MultiElementFiltersComponent,
		MultiElementTableComponent,
		MultiElementSelectComponent,
		MultiElementListTopBarComponent,
		MultiElementCountComponent,
		MultiElementFormArrayComponent,
		MultiElementMiniFormComponent,
		MultimediaModuleFormComponent,
		MultimediaModuleFiltersComponent,
		MultimediaModuleTableComponent,
		MultimediaModuleSelectComponent,
		MultimediaModuleListTopBarComponent,
		MultimediaModuleCountComponent,
		MultimediaModuleFormArrayComponent,
		MultimediaModuleMiniFormComponent,
		OralCategoryFormComponent,
		OralCategoryFiltersComponent,
		OralCategoryTableComponent,
		OralCategorySelectComponent,
		OralCategoryListTopBarComponent,
		OralCategoryCountComponent,
		OralCategoryFormArrayComponent,
		OralCategoryMiniFormComponent,
		OralQuizModuleFormComponent,
		OralQuizModuleFiltersComponent,
		OralQuizModuleTableComponent,
		OralQuizModuleSelectComponent,
		OralQuizModuleListTopBarComponent,
		OralQuizModuleCountComponent,
		OralQuizModuleFormArrayComponent,
		OralQuizModuleMiniFormComponent,
		PdfFormComponent,
		PdfFiltersComponent,
		PdfTableComponent,
		PdfSelectComponent,
		PdfListTopBarComponent,
		PdfCountComponent,
		PdfFormArrayComponent,
		PdfMiniFormComponent,
		PhotoModuleFormComponent,
		PhotoModuleFiltersComponent,
		PhotoModuleTableComponent,
		PhotoModuleSelectComponent,
		PhotoModuleListTopBarComponent,
		PhotoModuleCountComponent,
		PhotoModuleFormArrayComponent,
		PhotoModuleMiniFormComponent,
		PointOfInterestFormComponent,
		PointOfInterestFiltersComponent,
		PointOfInterestTableComponent,
		PointOfInterestSelectComponent,
		PointOfInterestListTopBarComponent,
		PointOfInterestCountComponent,
		PointOfInterestFormArrayComponent,
		PointOfInterestMiniFormComponent,
		UserFormComponent,
		UserFiltersComponent,
		UserTableComponent,
		UserSelectComponent,
		UserListTopBarComponent,
		UserCountComponent,
		UserFormArrayComponent,
		UserMiniFormComponent,
		VideoFormComponent,
		VideoFiltersComponent,
		VideoTableComponent,
		VideoSelectComponent,
		VideoListTopBarComponent,
		VideoCountComponent,
		VideoFormArrayComponent,
		VideoMiniFormComponent,
		ImageModalButtonComponent,
	],
	providers: [
		ActivityModuleService,
		AudioService,
		ClientInfoService,
		ImageService,
		InstructionService,
		ItineraryService,
		MediaService,
		Media360ImageService,
		Media360VideoService,
		MediaAudioService,
		MediaImageService,
		MediaPDFService,
		MediaVideoService,
		ModuleService,
		MultiChoiceCategoryService,
		MultiChoiceQuizModuleService,
		MultiElementService,
		MultimediaModuleService,
		OralCategoryService,
		OralQuizModuleService,
		PdfService,
		PhotoModuleService,
		PointOfInterestService,
		UserService,
		VideoService,
	],
	exports: [
		ActivityModuleFormComponent,
		ActivityModuleFiltersComponent,
		ActivityModuleTableComponent,
		ActivityModuleSelectComponent,
		ActivityModuleListTopBarComponent,
		ActivityModuleCountComponent,
		ActivityModuleFormArrayComponent,
		ActivityModuleMiniFormComponent,
		AudioFormComponent,
		AudioFiltersComponent,
		AudioTableComponent,
		AudioSelectComponent,
		AudioListTopBarComponent,
		AudioCountComponent,
		AudioFormArrayComponent,
		AudioMiniFormComponent,
		ClientInfoFormComponent,
		ClientInfoFiltersComponent,
		ClientInfoTableComponent,
		ClientInfoSelectComponent,
		ClientInfoListTopBarComponent,
		ClientInfoCountComponent,
		ClientInfoFormArrayComponent,
		ClientInfoMiniFormComponent,
		ImageFormComponent,
		ImageFiltersComponent,
		ImageTableComponent,
		ImageSelectComponent,
		ImageListTopBarComponent,
		ImageCountComponent,
		ImageFormArrayComponent,
		ImageMiniFormComponent,
		Image360FormComponent,
		Image360FiltersComponent,
		Image360TableComponent,
		Image360SelectComponent,
		Image360ListTopBarComponent,
		Image360CountComponent,
		Image360FormArrayComponent,
		Image360MiniFormComponent,
		InstructionFormComponent,
		InstructionFiltersComponent,
		InstructionTableComponent,
		InstructionSelectComponent,
		InstructionListTopBarComponent,
		InstructionCountComponent,
		InstructionFormArrayComponent,
		InstructionMiniFormComponent,
		ItineraryFormComponent,
		ItineraryFiltersComponent,
		ItineraryTableComponent,
		ItinerarySelectComponent,
		ItineraryListTopBarComponent,
		ItineraryCountComponent,
		ItineraryFormArrayComponent,
		ItineraryMiniFormComponent,
		MediaFormComponent,
		MediaFiltersComponent,
		MediaTableComponent,
		MediaSelectComponent,
		MediaListTopBarComponent,
		MediaCountComponent,
		MediaFormArrayComponent,
		MediaMiniFormComponent,
		Media360ImageFormComponent,
		Media360ImageFiltersComponent,
		Media360ImageTableComponent,
		Media360ImageSelectComponent,
		Media360ImageListTopBarComponent,
		Media360ImageCountComponent,
		Media360ImageFormArrayComponent,
		Media360ImageMiniFormComponent,
		Media360VideoFormComponent,
		Media360VideoFiltersComponent,
		Media360VideoTableComponent,
		Media360VideoSelectComponent,
		Media360VideoListTopBarComponent,
		Media360VideoCountComponent,
		Media360VideoFormArrayComponent,
		Media360VideoMiniFormComponent,
		MediaAudioFormComponent,
		MediaAudioFiltersComponent,
		MediaAudioTableComponent,
		MediaAudioSelectComponent,
		MediaAudioListTopBarComponent,
		MediaAudioCountComponent,
		MediaAudioFormArrayComponent,
		MediaAudioMiniFormComponent,
		MediaImageFormComponent,
		MediaImageFiltersComponent,
		MediaImageTableComponent,
		MediaImageSelectComponent,
		MediaImageListTopBarComponent,
		MediaImageCountComponent,
		MediaImageFormArrayComponent,
		MediaImageMiniFormComponent,
		MediaPDFFormComponent,
		MediaPDFFiltersComponent,
		MediaPDFTableComponent,
		MediaPDFSelectComponent,
		MediaPDFListTopBarComponent,
		MediaPDFCountComponent,
		MediaPDFFormArrayComponent,
		MediaPDFMiniFormComponent,
		MediaVideoFormComponent,
		MediaVideoFiltersComponent,
		MediaVideoTableComponent,
		MediaVideoSelectComponent,
		MediaVideoListTopBarComponent,
		MediaVideoCountComponent,
		MediaVideoFormArrayComponent,
		MediaVideoMiniFormComponent,
		ModuleFormComponent,
		ModuleFiltersComponent,
		ModuleTableComponent,
		ModuleSelectComponent,
		ModuleListTopBarComponent,
		ModuleCountComponent,
		ModuleFormArrayComponent,
		ModuleMiniFormComponent,
		MultiChoiceCategoryFormComponent,
		MultiChoiceCategoryFiltersComponent,
		MultiChoiceCategoryTableComponent,
		MultiChoiceCategorySelectComponent,
		MultiChoiceCategoryListTopBarComponent,
		MultiChoiceCategoryCountComponent,
		MultiChoiceCategoryFormArrayComponent,
		MultiChoiceCategoryMiniFormComponent,
		MultiChoiceQuizModuleFormComponent,
		MultiChoiceQuizModuleFiltersComponent,
		MultiChoiceQuizModuleTableComponent,
		MultiChoiceQuizModuleSelectComponent,
		MultiChoiceQuizModuleListTopBarComponent,
		MultiChoiceQuizModuleCountComponent,
		MultiChoiceQuizModuleFormArrayComponent,
		MultiChoiceQuizModuleMiniFormComponent,
		MultiElementFormComponent,
		MultiElementFiltersComponent,
		MultiElementTableComponent,
		MultiElementSelectComponent,
		MultiElementListTopBarComponent,
		MultiElementCountComponent,
		MultiElementFormArrayComponent,
		MultiElementMiniFormComponent,
		MultimediaModuleFormComponent,
		MultimediaModuleFiltersComponent,
		MultimediaModuleTableComponent,
		MultimediaModuleSelectComponent,
		MultimediaModuleListTopBarComponent,
		MultimediaModuleCountComponent,
		MultimediaModuleFormArrayComponent,
		MultimediaModuleMiniFormComponent,
		OralCategoryFormComponent,
		OralCategoryFiltersComponent,
		OralCategoryTableComponent,
		OralCategorySelectComponent,
		OralCategoryListTopBarComponent,
		OralCategoryCountComponent,
		OralCategoryFormArrayComponent,
		OralCategoryMiniFormComponent,
		OralQuizModuleFormComponent,
		OralQuizModuleFiltersComponent,
		OralQuizModuleTableComponent,
		OralQuizModuleSelectComponent,
		OralQuizModuleListTopBarComponent,
		OralQuizModuleCountComponent,
		OralQuizModuleFormArrayComponent,
		OralQuizModuleMiniFormComponent,
		PdfFormComponent,
		PdfFiltersComponent,
		PdfTableComponent,
		PdfSelectComponent,
		PdfListTopBarComponent,
		PdfCountComponent,
		PdfFormArrayComponent,
		PdfMiniFormComponent,
		PhotoModuleFormComponent,
		PhotoModuleFiltersComponent,
		PhotoModuleTableComponent,
		PhotoModuleSelectComponent,
		PhotoModuleListTopBarComponent,
		PhotoModuleCountComponent,
		PhotoModuleFormArrayComponent,
		PhotoModuleMiniFormComponent,
		PointOfInterestFormComponent,
		PointOfInterestFiltersComponent,
		PointOfInterestTableComponent,
		PointOfInterestSelectComponent,
		PointOfInterestListTopBarComponent,
		PointOfInterestCountComponent,
		PointOfInterestFormArrayComponent,
		PointOfInterestMiniFormComponent,
		UserFormComponent,
		UserFiltersComponent,
		UserTableComponent,
		UserSelectComponent,
		UserListTopBarComponent,
		UserCountComponent,
		UserFormArrayComponent,
		UserMiniFormComponent,
		VideoFormComponent,
		VideoFiltersComponent,
		VideoTableComponent,
		VideoSelectComponent,
		VideoListTopBarComponent,
		VideoCountComponent,
		VideoFormArrayComponent,
		VideoMiniFormComponent,
		ImageModalButtonComponent,
	],
})
export class ModelsModule {}
