import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {MediaAudioSearchParams} from '../media-audio-search-params';
import {MediaAudioService} from '../media-audio.service';
import {MediaAudio} from '../media-audio';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-media-audio-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class MediaAudioSelectComponent extends EntitySelectComponent< MediaAudio> {
  protected modelName = 'media_audio';
  /**
   * Constructor
   * @param {MediaAudioService} mediaAudioService
   * @param {ErrorService} errorService
   */
  constructor(private mediaAudioService: MediaAudioService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof MediaAudio)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<MediaAudio[]>this.model).push(<MediaAudio>model);
          } else {
            this.model = <MediaAudio>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< MediaAudio[]> {
      const params = new MediaAudioSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.mediaAudioService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< MediaAudio> {
      return await this.mediaAudioService.get(id);
  }
}
