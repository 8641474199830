<div class="container">
  <div class="col-12" *ngFor="let item of value;index as i">

    <input [value]="item.name" nz-input class="w-100 col-10" (focusout)="item.name=$event.target.value"
      [type]="inputType">

    <span class="btn btn-danger col-2" (click)="remove(index)">{{ 'common_remove' | translate }}</span>
  </div>

  <div class="row">
    <span class="col-12 btn btn-primary" (click)="add()">{{ 'common_add' | translate }}</span>
  </div>
</div>
