import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {MediaSearchParams} from '../media-search-params';
import {MediaService} from '../media.service';
import {Media} from '../media';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-media-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class MediaSelectComponent extends EntitySelectComponent< Media> {
  protected modelName = 'media';
  /**
   * Constructor
   * @param {MediaService} mediaService
   * @param {ErrorService} errorService
   */
  constructor(private mediaService: MediaService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Media)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Media[]>this.model).push(<Media>model);
          } else {
            this.model = <Media>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Media[]> {
      const params = new MediaSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.mediaService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Media> {
      return await this.mediaService.get(id);
  }
}
