import {Injectable} from '@angular/core';
import {Media360Image, Media360ImageInterface, Media360ImagePayload} from './media-360-image';
import {Media360ImageSearchParamsInterface} from './media-360-image-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class Media360ImageService extends BaseModelService< Media360Image, Media360ImageInterface, Media360ImagePayload, Media360ImageSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media-360-image';
  }
  /** @inheritDoc */
  protected new(object: Media360ImageInterface): Media360Image {
    return new Media360Image(object);
  }
}
