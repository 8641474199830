import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Instruction, InstructionInterface} from '../instruction/instruction';
import {MultiChoiceCategory, MultiChoiceCategoryInterface} from '../multi-choice-category/multi-choice-category';
export interface MultiChoiceQuizModuleInterface extends BaseModelInterface {
  name: string;
  memory_aid?: string;
  answers?: any;
  instructions?: (string | Instruction | InstructionInterface)[];
  categories?: (string | MultiChoiceCategory | MultiChoiceCategoryInterface)[];
  created_at: number | Date;
}
export interface MultiChoiceQuizModulePayload {
  name: string;
  memory_aid?: string;
  answers?: any;
  instructions?: (string | Instruction | InstructionInterface)[];
  categories?: (string | MultiChoiceCategory | MultiChoiceCategoryInterface)[];
}
type MultiChoiceQuizModulePayloadKey = keyof MultiChoiceQuizModulePayload;
export class MultiChoiceQuizModule extends BaseModel< MultiChoiceQuizModuleInterface, MultiChoiceQuizModulePayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Denotes if the instance of instructions has been populated */
  instructionsExists(): boolean {
    return !!this.props &&
        this.props.instructions instanceof Array &&
        (< Instruction[]>this.props.instructions).every((item) => {
            return item instanceof Instruction && item.exists();
        });
  }
  /** Denotes if the instance of categories has been populated */
  categoriesExists(): boolean {
    return !!this.props &&
        this.props.categories instanceof Array &&
        (< MultiChoiceCategory[]>this.props.categories).every((item) => {
            return item instanceof MultiChoiceCategory && item.exists();
        });
  }
  /** Populate the current instance from an object */
  fromObject(object: MultiChoiceQuizModuleInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.instructions instanceof Array) {
        this.props.instructions = (< InstructionInterface[]>this.props.instructions).map((item) => {
            return typeof item === 'object' ? new Instruction(item) : item;
        });
    }
    if (this.props.categories instanceof Array) {
        this.props.categories = (< MultiChoiceCategoryInterface[]>this.props.categories).map((item) => {
            return typeof item === 'object' ? new MultiChoiceCategory(item) : item;
        });
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MultiChoiceQuizModuleInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.memory_aid !== 'string' || props.memory_aid.length === 0) {
        props.memory_aid = null;
    }
    if (typeof props.answers === 'undefined') {
        props.answers = null;
    }
    if (typeof props.instructions === 'undefined') {
        props.instructions = [];
    }
    if (typeof props.categories === 'undefined') {
        props.categories = [];
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (this.props.instructions instanceof Array) {
        props.instructions = (< Instruction[]>props.instructions).map((item) => {
            return item instanceof Instruction ? item.toObject() : item;
        });
    }
    if (this.props.categories instanceof Array) {
        props.categories = (< MultiChoiceCategory[]>props.categories).map((item) => {
            return item instanceof MultiChoiceCategory ? item.toObject() : item;
        });
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MultiChoiceQuizModulePayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MultiChoiceQuizModuleInterface);
    payload.instructions = payload.instructions ? payload.instructions.map(i => this.extractId(i)) : null;
    payload.categories = payload.categories ? payload.categories.map(i => this.extractId(i)) : null;
    return payload as MultiChoiceQuizModulePayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MultiChoiceQuizModulePayloadKey[] {
  	return [
      'name',
      'memory_aid',
      'answers',
      'instructions',
      'categories',
  	];
  }
}
