import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { OralQuizModule } from '../../oral-quiz-module';
import { OralQuizModuleService } from '../../oral-quiz-module.service';
@Component({
	selector: 'hpf-oral-quiz-module-edit',
	templateUrl: './oral-quiz-module-edit.component.html',
})
export class OralQuizModuleEditComponent implements OnInit, OnDestroy {
	/** The oral quiz module */
	oralQuizModule = new OralQuizModule();
	/** The oral quiz module */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private oralQuizModuleService: OralQuizModuleService
	) {}
	/** Init */
	ngOnInit() {
		// Get the oral quiz module after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch oral quiz module from API */
	private refresh() {
		this.oralQuizModuleService
			.get(this.id, {
				_populate: [
					'final_image',
					'categories.foreground_image',
					'background_image',
					'categories.icon',
					'instructions.image',
				],
			})
			.then((oralQuizModule: OralQuizModule) => {
				this.oralQuizModule.fromObject(oralQuizModule.toObject());
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the oral quiz module is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the oral quiz module is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/oral-quiz-module')
			.catch((error) => this.errorService.handle(error));
	}
}
