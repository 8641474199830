import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {OralCategory} from '../../oral-category';
import {OralCategoryService} from '../../oral-category.service';
@Component({
  selector: 'hpf-oral-category-edit',
  templateUrl: './oral-category-edit.component.html'
})
export class OralCategoryEditComponent implements OnInit, OnDestroy {
  /** The oral category */
  oralCategory = new OralCategory();
  /** The oral category */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private oralCategoryService: OralCategoryService) {}
  /** Init */
  ngOnInit() {
    // Get the oral category after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch oral category from API */
  private refresh() {
    this.oralCategoryService.get(this.id, { _populate: ['*'] })
      .then((oralCategory: OralCategory) => {
        this.oralCategory.fromObject(oralCategory.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the oral category is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the oral category is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/oral-category')
      .catch((error) => this.errorService.handle(error));
  }
}
