import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {ImageSearchParams} from '../image-search-params';
import {ImageService} from '../image.service';
import {Image} from '../image';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-image-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class ImageSelectComponent extends EntitySelectComponent< Image> {
  protected modelName = 'image';
  /**
   * Constructor
   * @param {ImageService} imageService
   * @param {ErrorService} errorService
   */
  constructor(private imageService: ImageService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Image)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Image[]>this.model).push(<Image>model);
          } else {
            this.model = <Image>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Image[]> {
      const params = new ImageSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.imageService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Image> {
      return await this.imageService.get(id);
  }
}
