import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-image-360',
  templateUrl: './image-360.component.html'
})
export class Image360Component implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
