import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {MultiElement} from '../../multi-element';
import {MultiElementService} from '../../multi-element.service';
@Component({
  selector: 'hpf-multi-element-edit',
  templateUrl: './multi-element-edit.component.html'
})
export class MultiElementEditComponent implements OnInit, OnDestroy {
  /** The multi element */
  multiElement = new MultiElement();
  /** The multi element */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private multiElementService: MultiElementService) {}
  /** Init */
  ngOnInit() {
    // Get the multi element after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch multi element from API */
  private refresh() {
    this.multiElementService.get(this.id, { _populate: ['*'] })
      .then((multiElement: MultiElement) => {
        this.multiElement.fromObject(multiElement.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the multi element is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the multi element is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/multi-element')
      .catch((error) => this.errorService.handle(error));
  }
}
