import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Media, MediaInterface } from '../media/media';
export interface MultimediaModuleInterface extends BaseModelInterface {
	name: string;
	memory_aid?: string;
	medias: (string | Media | MediaInterface)[];
	created_at: number | Date;
}
export interface MultimediaModulePayload {
	name: string;
	memory_aid?: string;
	medias: (string | Media | MediaInterface)[];
}
type MultimediaModulePayloadKey = keyof MultimediaModulePayload;
export class MultimediaModule extends BaseModel<
	MultimediaModuleInterface,
	MultimediaModulePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of medias has been populated */
	mediasExists(): boolean {
		return (
			!!this.props &&
			this.props.medias instanceof Media &&
			this.props.medias.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: MultimediaModuleInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.medias instanceof Array) {
			this.props.medias = (<MediaInterface[]>this.props.medias).map(
				(item) => {
					return typeof item === 'object' ? new Media(item) : item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): MultimediaModuleInterface {
		const props = Object.assign({}, this.props);
		if (
			typeof props.memory_aid !== 'string' ||
			props.memory_aid.length === 0
		) {
			props.memory_aid = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.medias instanceof Array) {
			props.medias = (<Media[]>props.medias).map((item) => {
				return item instanceof Media ? item.toObject() : item;
			});
		}

		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): MultimediaModulePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as MultimediaModuleInterface);
		payload.medias = payload.medias
			? payload.medias.map((i) => this.extractId(i))
			: null;
		return payload as MultimediaModulePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): MultimediaModulePayloadKey[] {
		return ['name', 'memory_aid', 'medias'];
	}
}
