import {Injectable} from '@angular/core';
import {OralCategory, OralCategoryInterface, OralCategoryPayload} from './oral-category';
import {OralCategorySearchParamsInterface} from './oral-category-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class OralCategoryService extends BaseModelService< OralCategory, OralCategoryInterface, OralCategoryPayload, OralCategorySearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'oral-category';
  }
  /** @inheritDoc */
  protected new(object: OralCategoryInterface): OralCategory {
    return new OralCategory(object);
  }
}
