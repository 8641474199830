import {Injectable} from '@angular/core';
import {MediaImage, MediaImageInterface, MediaImagePayload} from './media-image';
import {MediaImageSearchParamsInterface} from './media-image-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MediaImageService extends BaseModelService< MediaImage, MediaImageInterface, MediaImagePayload, MediaImageSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media-image';
  }
  /** @inheritDoc */
  protected new(object: MediaImageInterface): MediaImage {
    return new MediaImage(object);
  }
}
