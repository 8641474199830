import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {InstructionSearchParams} from '../instruction-search-params';

@Component({
  selector: 'hpf-instruction-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class InstructionListTopBarComponent extends EntityListTopBarComponent< InstructionSearchParams> {
}
 