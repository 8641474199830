import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {Image} from '../image';
import {ImageService} from '../image.service';
@Component({
  selector: 'hpf-image-mini-form',
  templateUrl: './image-mini-form.component.html'
})
export class ImageMiniFormComponent implements OnInit{

    @Input() value: Image;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private imageService: ImageService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    file: new FormControl(this.value.props.file, [Validators.required]),
                    size: new FormControl(this.value.props.size, [Validators.required]),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.imageService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.imageService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'file': this.value.props.file,
     'size': this.value.props.size,
    });
  }
	@Output() init = new EventEmitter<ImageMiniFormComponent>();

}
