<div class="container my-5">
  <div class="row">
    <div class="col-12 mb-5">
      <h1>{{ 'home_title' | translate }}</h1>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'activity_module',
			action: 'count'
		}">
      <hpf-activity-module-count></hpf-activity-module-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'audio',
			action: 'count'
		}">
      <hpf-audio-count></hpf-audio-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'client_info',
			action: 'count'
		}">
      <hpf-client-info-count></hpf-client-info-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'image',
			action: 'count'
		}">
      <hpf-image-count></hpf-image-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'instruction',
			action: 'count'
		}">
      <hpf-instruction-count></hpf-instruction-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'itinerary',
			action: 'count'
		}">
      <hpf-itinerary-count></hpf-itinerary-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media',
			action: 'count'
		}">
      <hpf-media-count></hpf-media-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media_360_image',
			action: 'count'
		}">
      <hpf-media-360-image-count></hpf-media-360-image-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media_360_video',
			action: 'count'
		}">
      <hpf-media-360-video-count></hpf-media-360-video-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media_audio',
			action: 'count'
		}">
      <hpf-media-audio-count></hpf-media-audio-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media_image',
			action: 'count'
		}">
      <hpf-media-image-count></hpf-media-image-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media_pdf',
			action: 'count'
		}">
      <hpf-media-pdf-count></hpf-media-pdf-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'media_video',
			action: 'count'
		}">
      <hpf-media-video-count></hpf-media-video-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'module',
			action: 'count'
		}">
      <hpf-module-count></hpf-module-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'multi_choice_category',
			action: 'count'
		}">
      <hpf-multi-choice-category-count></hpf-multi-choice-category-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'multi_choice_quiz_module',
			action: 'count'
		}">
      <hpf-multi-choice-quiz-module-count></hpf-multi-choice-quiz-module-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'multi_element',
			action: 'count'
		}">
      <hpf-multi-element-count></hpf-multi-element-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'multimedia_module',
			action: 'count'
		}">
      <hpf-multimedia-module-count></hpf-multimedia-module-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'oral_category',
			action: 'count'
		}">
      <hpf-oral-category-count></hpf-oral-category-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'oral_quiz_module',
			action: 'count'
		}">
      <hpf-oral-quiz-module-count></hpf-oral-quiz-module-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'pdf',
			action: 'count'
		}">
      <hpf-pdf-count></hpf-pdf-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'photo_module',
			action: 'count'
		}">
      <hpf-photo-module-count></hpf-photo-module-count>
    </div>

    <hpf-point-of-interest-count></hpf-point-of-interest-count>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'user',
			action: 'count'
		}">
    <hpf-user-count></hpf-user-count>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'video',
			action: 'count'
		}">
    <hpf-video-count></hpf-video-count>
  </div>
</div>
