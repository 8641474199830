import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {MultiElementSearchParams} from '../multi-element-search-params';

@Component({
  selector: 'hpf-multi-element-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class MultiElementListTopBarComponent extends EntityListTopBarComponent< MultiElementSearchParams> {
}
 