import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
export interface InstructionInterface extends BaseModelInterface {
  title: string;
  body?: string;
  image?: (string | Image | ImageInterface);
  created_at: number | Date;
}
export interface InstructionPayload {
  title: string;
  body?: string;
  image?: (string | Image | ImageInterface);
}
type InstructionPayloadKey = keyof InstructionPayload;
export class Instruction extends BaseModel< InstructionInterface, InstructionPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.title}`;
  }
  /** Denotes if the instance of image has been populated */
  imageExists(): boolean {
    return !!this.props &&
        this.props.image instanceof Image &&
        this.props.image.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: InstructionInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.image !== null && typeof this.props.image === 'object') {
        this.props.image = new Image(< ImageInterface>this.props.image);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): InstructionInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.body !== 'string' || props.body.length === 0) {
        props.body = null;
    }
    if (typeof props.image === 'undefined') {
        props.image = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.image !== null && props.image instanceof Image) {
        props.image = props.image.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): InstructionPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as InstructionInterface);
    payload.image = payload.image ? this.extractId(payload.image) : null;
    return payload as InstructionPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): InstructionPayloadKey[] {
  	return [
      'title',
      'body',
      'image',
  	];
  }
}
