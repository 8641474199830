import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Media} from '../../media';

@Component({
  selector: 'hpf-media-create',
  templateUrl: './media-create.component.html'
})
export class MediaCreateComponent implements OnInit {
  /** The media */
  media = new Media();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media is created */
  onCreate(media: Media): void {
    this.router.navigateByUrl(`/media/${media.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
