import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {Media360VideoSearchParams} from '../media-360-video-search-params';

@Component({
  selector: 'hpf-media-360-video-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class Media360VideoListTopBarComponent extends EntityListTopBarComponent< Media360VideoSearchParams> {
}
 