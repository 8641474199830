import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {Itinerary} from '../itinerary';
import {ItineraryService} from '../itinerary.service';
@Component({
  selector: 'hpf-itinerary-mini-form',
  templateUrl: './itinerary-mini-form.component.html'
})
export class ItineraryMiniFormComponent implements OnInit{

    @Input() value: Itinerary;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private itineraryService: ItineraryService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    details: new FormControl(this.value.props.details, [Validators.required]),
                    point_of_interests: new FormControl(this.value.props.point_of_interests, []),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.itineraryService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.itineraryService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'details': this.value.props.details,
     'point_of_interests': this.value.props.point_of_interests,
    });
  }
	@Output() init = new EventEmitter<ItineraryMiniFormComponent>();

}
