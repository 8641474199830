import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Media} from '../../media';
import {MediaService} from '../../media.service';
@Component({
  selector: 'hpf-media-edit',
  templateUrl: './media-edit.component.html'
})
export class MediaEditComponent implements OnInit, OnDestroy {
  /** The media */
  media = new Media();
  /** The media */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private mediaService: MediaService) {}
  /** Init */
  ngOnInit() {
    // Get the media after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch media from API */
  private refresh() {
    this.mediaService.get(this.id, { _populate: ['*'] })
      .then((media: Media) => {
        this.media.fromObject(media.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the media is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the media is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/media')
      .catch((error) => this.errorService.handle(error));
  }
}
