import {Injectable} from '@angular/core';
import {Module, ModuleInterface, ModulePayload} from './module';
import {ModuleSearchParamsInterface} from './module-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class ModuleService extends BaseModelService< Module, ModuleInterface, ModulePayload, ModuleSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'module';
  }
  /** @inheritDoc */
  protected new(object: ModuleInterface): Module {
    return new Module(object);
  }
}
