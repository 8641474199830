import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputArrayComponent } from '../input-array.component';

@Component({
	selector: 'title-body-array',
	templateUrl: 'title-body-array.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TitleBodyArrayComponent,
			multi: true,
		},
	],
})
export class TitleBodyArrayComponent extends InputArrayComponent<any> {
	inputType = 'string';

	add() {
		this.value.push({ title: '', body: '' });
		this.onChange(this.value);
	}
}
