<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Title -->

                
                <nz-form-item>
                    <h4>{{ 'media-pdf_title' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="title">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Title -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Thumbnail -->

                
                <nz-form-item>
                    <h4>{{ 'media-pdf_thumbnail' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="thumbnail"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Thumbnail -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Pdf -->

                
                <nz-form-item>
                    <h4>{{ 'media-pdf_pdf' | translate }}</h4>
                    <nz-form-control>

         

        

 

                        <hpf-pdf-select
                            [controlName]="'pdf'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="value.props.pdf"
                            [filterEnabled]="true"
                            [placeholder]="'pdf_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-pdf-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Pdf -->
            </div>
        </div>
  </div>
  