<div class="container" [formGroup]="form">
  <div class="row">
    <div class="col-12 col-md-6">
      <!-- Name -->


      <nz-form-item>
        <h4>{{ 'oral-category_name' | translate }}</h4>
        <nz-form-control>


          <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
            formControlName="name">
        </nz-form-control>
      </nz-form-item>
      <!-- /Name -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Answers -->


      <nz-form-item>
        <h4>{{ 'oral-category_answers' | translate }}</h4>
        <string-array formControlName="answers"></string-array>
      </nz-form-item>
      <!-- /Answers -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Foreground Image -->


      <nz-form-item>
        <h4>{{ 'oral-category_foreground-image' | translate }}</h4>
        <nz-form-control>





          <app-image-modal-button formControlName="foreground_image"></app-image-modal-button>
        </nz-form-control>
      </nz-form-item>
      <!-- /Foreground Image -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Icon -->


      <nz-form-item>
        <h4>{{ 'oral-category_icon' | translate }}</h4>
        <nz-form-control>





          <app-image-modal-button formControlName="icon"></app-image-modal-button>
        </nz-form-control>
      </nz-form-item>
      <!-- /Icon -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Should Shrink -->


      <nz-form-item>
        <h4>{{ 'oral-category_should-shrink' | translate }}</h4>
        <nz-form-control>


          <nz-select class="w-100" [nzPlaceHolder]="'common_value-boolean' | translate" formControlName="should_shrink">
            <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
            <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <!-- /Should Shrink -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Color -->


      <nz-form-item>
        <h4>{{ 'oral-category_color' | translate }}</h4>
        <nz-form-control>
          <color-input formControlName="color"></color-input>


        </nz-form-control>
      </nz-form-item>
      <!-- /Color -->
    </div>
  </div>
</div>
