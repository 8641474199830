import {Injectable} from '@angular/core';
import {MediaVideo, MediaVideoInterface, MediaVideoPayload} from './media-video';
import {MediaVideoSearchParamsInterface} from './media-video-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MediaVideoService extends BaseModelService< MediaVideo, MediaVideoInterface, MediaVideoPayload, MediaVideoSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media-video';
  }
  /** @inheritDoc */
  protected new(object: MediaVideoInterface): MediaVideo {
    return new MediaVideo(object);
  }
}
