import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Media360Video} from '../../media-360-video';

@Component({
  selector: 'hpf-media-360-video-create',
  templateUrl: './media-360-video-create.component.html'
})
export class Media360VideoCreateComponent implements OnInit {
  /** The media 360 video */
  media360Video = new Media360Video();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media 360 video is created */
  onCreate(media360Video: Media360Video): void {
    this.router.navigateByUrl(`/media-360-video/${media360Video.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
