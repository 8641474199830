import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Itinerary} from '../../itinerary';

@Component({
  selector: 'hpf-itinerary-create',
  templateUrl: './itinerary-create.component.html'
})
export class ItineraryCreateComponent implements OnInit {
  /** The itinerary */
  itinerary = new Itinerary();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the itinerary is created */
  onCreate(itinerary: Itinerary): void {
    this.router.navigateByUrl(`/itinerary/${itinerary.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
