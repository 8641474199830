import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {MediaImage} from '../../media-image';
import {MediaImageService} from '../../media-image.service';
@Component({
  selector: 'hpf-media-image-edit',
  templateUrl: './media-image-edit.component.html'
})
export class MediaImageEditComponent implements OnInit, OnDestroy {
  /** The media image */
  mediaImage = new MediaImage();
  /** The media image */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private mediaImageService: MediaImageService) {}
  /** Init */
  ngOnInit() {
    // Get the media image after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch media image from API */
  private refresh() {
    this.mediaImageService.get(this.id, { _populate: ['*'] })
      .then((mediaImage: MediaImage) => {
        this.mediaImage.fromObject(mediaImage.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the media image is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the media image is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/media-image')
      .catch((error) => this.errorService.handle(error));
  }
}
