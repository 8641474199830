import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {ClientInfo} from '../../client-info';

@Component({
  selector: 'hpf-client-info-create',
  templateUrl: './client-info-create.component.html'
})
export class ClientInfoCreateComponent implements OnInit {
  /** The client info */
  clientInfo = new ClientInfo();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the client info is created */
  onCreate(clientInfo: ClientInfo): void {
    this.router.navigateByUrl(`/client-info/${clientInfo.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
