import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
export interface ClientInfoInterface extends BaseModelInterface {
  name: string;
  email: string;
  invitation: boolean;
  newsletter: boolean;
  created_at: number | Date;
}
export interface ClientInfoPayload {
  name: string;
  email: string;
  invitation: boolean;
  newsletter: boolean;
}
type ClientInfoPayloadKey = keyof ClientInfoPayload;
export class ClientInfo extends BaseModel< ClientInfoInterface, ClientInfoPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Populate the current instance from an object */
  fromObject(object: ClientInfoInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): ClientInfoInterface {
    const props = Object.assign({}, this.props);
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): ClientInfoPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as ClientInfoInterface);
    return payload as ClientInfoPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): ClientInfoPayloadKey[] {
  	return [
      'name',
      'email',
      'invitation',
      'newsletter',
  	];
  }
}
