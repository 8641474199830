import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-multi-choice-category',
  templateUrl: './multi-choice-category.component.html'
})
export class MultiChoiceCategoryComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
