<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Description -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_description' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100"
                                  nz-input
                                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                                  [placeholder]="'common_value-string' | translate"
                                  formControlName="description"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Description -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Answer -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_answer' | translate }}</h4>
                    <nz-form-control>

         
                        <nz-input-number class="w-100"
                                         formControlName="answer" 
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Answer -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Media -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_media' | translate }}</h4>
                    <nz-form-control>

         

        

 

                        <hpf-media-select
                            [controlName]="'media'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="value.props.media"
                            [nullable]="true"
                            [filterEnabled]="true"
                            [placeholder]="'media_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-media-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Media -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Image -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_image' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="image"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Image -->
            </div>
        </div>
  </div>
  