import {Injectable} from '@angular/core';
import {MediaPDF, MediaPDFInterface, MediaPDFPayload} from './media-pdf';
import {MediaPDFSearchParamsInterface} from './media-pdf-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MediaPDFService extends BaseModelService< MediaPDF, MediaPDFInterface, MediaPDFPayload, MediaPDFSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media-pdf';
  }
  /** @inheritDoc */
  protected new(object: MediaPDFInterface): MediaPDF {
    return new MediaPDF(object);
  }
}
