import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
export interface ModuleInterface extends BaseModelInterface {
  name: string;
  memory_aid?: string;
  type: string;
  created_at: number | Date;
}
export interface ModulePayload {
  name: string;
  memory_aid?: string;
}
type ModulePayloadKey = keyof ModulePayload;
export class Module extends BaseModel< ModuleInterface, ModulePayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Populate the current instance from an object */
  fromObject(object: ModuleInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): ModuleInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.memory_aid !== 'string' || props.memory_aid.length === 0) {
        props.memory_aid = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): ModulePayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as ModuleInterface);
    return payload as ModulePayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): ModulePayloadKey[] {
  	return [
      'name',
      'memory_aid',
  	];
  }
}
