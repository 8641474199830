import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { MultiChoiceQuizModule } from '../../multi-choice-quiz-module';
import { MultiChoiceQuizModuleService } from '../../multi-choice-quiz-module.service';
@Component({
	selector: 'hpf-multi-choice-quiz-module-edit',
	templateUrl: './multi-choice-quiz-module-edit.component.html',
})
export class MultiChoiceQuizModuleEditComponent implements OnInit, OnDestroy {
	/** The multi choice quiz module */
	multiChoiceQuizModule = new MultiChoiceQuizModule();
	/** The multi choice quiz module */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private multiChoiceQuizModuleService: MultiChoiceQuizModuleService
	) {}
	/** Init */
	ngOnInit() {
		// Get the multi choice quiz module after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch multi choice quiz module from API */
	private refresh() {
		this.multiChoiceQuizModuleService
			.get(this.id, {
				_populate: [
					'instructions.image',
					'categories.elements.image',
					'categories.elements.media',
				],
			})
			.then((multiChoiceQuizModule: MultiChoiceQuizModule) => {
				this.multiChoiceQuizModule.fromObject(
					multiChoiceQuizModule.toObject()
				);
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the multi choice quiz module is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the multi choice quiz module is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/multi-choice-quiz-module')
			.catch((error) => this.errorService.handle(error));
	}
}
