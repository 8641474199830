<div class="container">
  <div class="col-12" *ngFor="let item of value;index as i">

    <label>Name</label>
    <input [value]="item.title" nz-input class="w-100" (focusout)="item.title=$event.target.value" [type]="inputType">
    <label>Body</label>
    <input [value]="item.body" nz-input class="w-100" (focusout)="item.body=$event.target.value" [type]="inputType">
    <span class="btn btn-danger col-12" (click)="remove(i)">{{ 'common_remove' | translate }}</span>
    <hr>
  </div>

  <div class="row">
    <span class="col-12 btn btn-primary" (click)="add()">{{ 'common_add' | translate }}</span>
  </div>
</div>
