import {Component, Input} from '@angular/core';
import {ErrorService} from '@app/services';
import {EntityTableComponent} from '@app/abstracts';
import {MultiChoiceCategorySearchParams} from '../multi-choice-category-search-params';
import {MultiChoiceCategoryService} from '../multi-choice-category.service';
import {MultiChoiceCategory} from '../multi-choice-category';

@Component({
  selector: 'hpf-multi-choice-category-table',
  templateUrl: './multi-choice-category-table.component.html'
})
export class MultiChoiceCategoryTableComponent extends EntityTableComponent< MultiChoiceCategory, MultiChoiceCategorySearchParams> {
  /** Columns to show */
  @Input() columns = [
    'name',
    'result_type',
    'elements',
    'created_at',
  ];
  /** Constructor */
  constructor(private multiChoiceCategoryService: MultiChoiceCategoryService,
              private errorService: ErrorService) {
    super();
  }
  /** @inheritDoc */
  protected refresh(): void {
    const params = this.searchParams.toObject();
    // Force relation population
    params._populate = [
        'elements',
    ];
    // Set refreshing flag
    this.refreshing = true;
    // Get list
    this.multiChoiceCategoryService.list(params)
      .then((results) => {
        // Link data
        this.items = results.items;
        this.total = results.total;
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => {
        this.refreshing = false;
      });
  }

  /** Called when the user select multiple rows and delete them */
  async removeMany() {
    if (!this.selectedRows.length) {
        return;
    }

    this.deleting = true;
    for (const item of this.selectedRows) {
      await this.multiChoiceCategoryService.remove(item.getId())
        .catch(e => this.errorService.handle(e));
    }

    this.deleteMany.next(this.selectedRows);
    this.resetSelectedRows();
    this.deleting = false;
    this.deleteManyModal = false;
    this.searchParams.next();
  }
  /** Called when the user delete a single row */
  async removeOne() {
    if (!this.rowToDelete) {
        return;
    }

    this.deleting = true;
    await this.multiChoiceCategoryService.remove(this.rowToDelete.getId())
      .catch(e => this.errorService.handle(e));

    this.deleteOne.next(this.rowToDelete);
    delete this.rowToDelete;
    this.deleting = false;
    this.deleteOneModal = false;
    this.searchParams.next();
  }

}
