import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputArrayComponent } from '../input-array.component';

@Component({
	selector: 'string-array',
	templateUrl: '../input-array.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: StringArrayComponent,
			multi: true,
		},
	],
})
export class StringArrayComponent extends InputArrayComponent<string> {
	inputType = 'string';

	add() {
		console.log(this.value);
		this.value.push('');
		this.onChange(this.value);
	}
}
