import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Image360 } from '../image-360';
import { Image360Service } from '../image-360.service';
@Component({
	selector: 'hpf-image-360-form',
	templateUrl: './image-360-form.component.html',
})
export class Image360FormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The image 360 to inject in the form */
	@Input() image360: Image360;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Image360>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private image360Service: Image360Service,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'image_360',
			'update'
		));

		// Init model
		if (!this.image360) this.image360 = new Image360();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.image360.props.name, [
				Validators.required,
			]),
			file: new FormControl(this.image360.props.file, [
				Validators.required,
			]),
		});

		// Update form when model loads or changes
		this.modelSubscription = this.image360.subscribe(() => {
			if (!this.image360.isNew()) {
				this.form = this.formBuilder.group({
					name: new FormControl(this.image360.props.name, [
						Validators.required,
					]),
					file: new FormControl(this.image360.props.file, []),
				});
			}
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();

			// Creation or update ?
			if (this.image360.isNew()) {
				// Creation
				const image360: Image360 = await this.image360Service.create(
					this.image360.toPayload()
				);
				this.create.next(image360);
			} else {
				// Update
				await this.image360Service.update(
					this.image360.getId(),
					this.image360.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.image360.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.image360.props.name,
			file: null,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.image360Service
			.remove(this.image360.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
