import {Injectable} from '@angular/core';
import {Image360, Image360Interface, Image360Payload} from './image-360';
import {Image360SearchParamsInterface} from './image-360-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class Image360Service extends BaseModelService< Image360, Image360Interface, Image360Payload, Image360SearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'image-360';
  }
  /** @inheritDoc */
  protected new(object: Image360Interface): Image360 {
    return new Image360(object);
  }
}
