<div class="bgBlack" [hidden]="!opened" (click)="opened=false">


</div>
<div class="imagemodal" [hidden]="!opened">
  <div class="imagemodalSearchBox">

    <h3>{{'image_common_search-placeholder'|translate}}</h3>
    <input field="text" class="w-100" (input)="search($event.target.value)">

  </div>

  <div class="imagemodalInside">

    <div class="row">
      <div class="col-4 imgContainer" *ngFor="let img of images" (click)="imageSelected(img)"
        [style.background-image]="'url('+img.getThumbnail()+ ')'">

        <span>{{img.getLabel()}}</span>
      </div>

    </div>
  </div>


</div>
