
<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form nz-form
      class="point-of-interest-form w-100"
      *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      [nzAutoTips]="explainErrorsService.autoTips">
    <nz-affix>
        <div class="py-4 border-bottom bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-between">
            			<!-- Title -->
            			<div>
            				<h1 class="mb-0">
            					<span *ngIf="pointOfInterest.isNew()">{{ 'point-of-interest_create-form-title' | translate }}</span>
            					<span *ngIf="!pointOfInterest.isNew()">{{ pointOfInterest.getLabel() }}</span>
            				</h1>
            				<small *ngIf="!pointOfInterest.isNew()" class="text-black-50">{{ 'common_id' | translate: { id : pointOfInterest.getId() } }}</small>
            			</div>
            			<!-- /Title -->
            			
            			<!-- Actions -->
            			<div>
            				<button
            					nz-button
            					[disabled]="form.invalid"
            					[nzLoading]="saving"
            					nzType="primary"
                                *appRestrictedByScope="{
                                    model: 'point_of_interest',
                                    action: 'update'
                                }"
            				>
            					{{ 'common_save' | translate }}
            				</button>
                            <ng-container *appRestrictedByScope="{
                                model: 'point_of_interest',
                                action: 'delete'
                            }">
                                <button
                                    *ngIf="!pointOfInterest.isNew() && deletable"
                                    class="ml-4"
                                    nz-button
                                    nzShape="circle"
                                    [title]="'common_delete' | translate"
                                    [nzLoading]="deleting"
                                    (click)="deleteModal = true"
                                    type="button"
                                >
                                    <i nz-icon nzType="delete"></i>
                                </button>
                            </ng-container>
            			</div>
            			<!-- /Actions -->
            		</div>
            	</div>
            </div>
        </div>
    </nz-affix>
    <div class="container">
    <nz-card class="round-border my-5">
    <div *ngIf="loading" class="row">
        <div class="col-12">
            <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
            <div class="col-12  col-md-6" >
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_name' | translate }}</h4>
                    <nz-form-control>

             
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Details -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_details' | translate }}</h4>
                    <nz-form-control>

             
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="details">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Details -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Picture -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_picture' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('picture')">

             

     <app-image-modal-button formControlName="picture"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Picture -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Latitude -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_latitude' | translate }}</h4>
                    <nz-form-control>

             
                        <nz-input-number class="w-100"
                                         formControlName="latitude" [nzMin]="-90" [nzMax]="90"
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Latitude -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Longitude -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_longitude' | translate }}</h4>
                    <nz-form-control>

             
                        <nz-input-number class="w-100"
                                         formControlName="longitude" [nzMin]="-180" [nzMax]="180"
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Longitude -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Modules -->

                
                <nz-form-item>
                    <h4>{{ 'point-of-interest_modules' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('modules')">

             

                        <hpf-module-select
                            [controlName]="'modules'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="pointOfInterest.props.modules"
                            [multiple]="true"
                            [nullable]="true"
                            [filterEnabled]="true"
                            [placeholder]="'module_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-module-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Modules -->
            </div>
        </div>

        <nz-divider *ngIf="!pointOfInterest.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!pointOfInterest.isNew()" class="row">
            <div class="col-12 col-md-6" >
                <!-- _Id -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_-id' | translate }}</h4>
                    <div>{{ pointOfInterest.props._id }}</div>
                </div>
                <!-- /_Id -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Name -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_name' | translate }}</h4>
                    <div>{{ pointOfInterest.props.name }}</div>
                </div>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Details -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_details' | translate }}</h4>
                    <div>{{ pointOfInterest.props.details }}</div>
                </div>
                <!-- /Details -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Picture -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_picture' | translate }}</h4>
                    <div *ngIf="pointOfInterest.pictureExists()">
                        <a [routerLink]="['/image', pointOfInterest.props.picture.getId()]">
                            {{ pointOfInterest.props.picture.getLabel() }}
                        </a>
                    </div>
                </div>
                <!-- /Picture -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Latitude -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_latitude' | translate }}</h4>
                    <div>{{ pointOfInterest.props.latitude }}</div>
                </div>
                <!-- /Latitude -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Longitude -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_longitude' | translate }}</h4>
                    <div>{{ pointOfInterest.props.longitude }}</div>
                </div>
                <!-- /Longitude -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Modules -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_modules' | translate }}</h4>
                    <div *ngIf="pointOfInterest.modulesExists()">
                        <div *ngFor="let item of pointOfInterest.props.modules">
                            <a class="mr-2" [routerLink]="['/module', item.getId()]">
                                {{ item.getLabel() }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /Modules -->
            </div>
            <div class="col-12 col-md-6" >
                <!-- Created At -->
                <div class="mb-4">
                    <h4>{{ 'point-of-interest_created-at' | translate }}</h4>
                    <div>{{ pointOfInterest.props.created_at | date:'medium' }}</div>
                </div>
                <!-- /Created At -->
            </div>
        </div>
    </div>
    </nz-card>
    </div>
</form>
