import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MultiChoiceQuizModule} from '../../multi-choice-quiz-module';

@Component({
  selector: 'hpf-multi-choice-quiz-module-create',
  templateUrl: './multi-choice-quiz-module-create.component.html'
})
export class MultiChoiceQuizModuleCreateComponent implements OnInit {
  /** The multi choice quiz module */
  multiChoiceQuizModule = new MultiChoiceQuizModule();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the multi choice quiz module is created */
  onCreate(multiChoiceQuizModule: MultiChoiceQuizModule): void {
    this.router.navigateByUrl(`/multi-choice-quiz-module/${multiChoiceQuizModule.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
