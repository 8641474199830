import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {MediaPDFSearchParams} from '../media-pdf-search-params';
import {MediaPDFService} from '../media-pdf.service';
@Component({
  selector: 'hpf-media-pdf-count',
  templateUrl: './media-pdf-count.component.html'
})
export class MediaPDFCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: MediaPDFSearchParams = new MediaPDFSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private mediaPDFService: MediaPDFService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.mediaPDFService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
