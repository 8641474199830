import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {Media360ImageSearchParams} from '../media-360-image-search-params';
import {Media360ImageService} from '../media-360-image.service';
import {Media360Image} from '../media-360-image';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-media-360-image-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class Media360ImageSelectComponent extends EntitySelectComponent< Media360Image> {
  protected modelName = 'media_360_image';
  /**
   * Constructor
   * @param {Media360ImageService} media360ImageService
   * @param {ErrorService} errorService
   */
  constructor(private media360ImageService: Media360ImageService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Media360Image)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Media360Image[]>this.model).push(<Media360Image>model);
          } else {
            this.model = <Media360Image>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Media360Image[]> {
      const params = new Media360ImageSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.media360ImageService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Media360Image> {
      return await this.media360ImageService.get(id);
  }
}
