import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
import {MultiElement, MultiElementInterface} from '../multi-element/multi-element';
export interface MultiChoiceCategoryInterface extends BaseModelInterface {
  name: string;
  question: string;
  result_type: string;
  category_image?: (string | Image | ImageInterface);
  environment_image?: (string | Image | ImageInterface);
  elements?: (string | MultiElement | MultiElementInterface)[];
  created_at: number | Date;
}
export interface MultiChoiceCategoryPayload {
  name: string;
  question: string;
  result_type: string;
  category_image?: (string | Image | ImageInterface);
  environment_image?: (string | Image | ImageInterface);
  elements?: (string | MultiElement | MultiElementInterface)[];
}
type MultiChoiceCategoryPayloadKey = keyof MultiChoiceCategoryPayload;
export class MultiChoiceCategory extends BaseModel< MultiChoiceCategoryInterface, MultiChoiceCategoryPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Denotes if the instance of category image has been populated */
  categoryImageExists(): boolean {
    return !!this.props &&
        this.props.category_image instanceof Image &&
        this.props.category_image.exists();
  }
  /** Denotes if the instance of environment image has been populated */
  environmentImageExists(): boolean {
    return !!this.props &&
        this.props.environment_image instanceof Image &&
        this.props.environment_image.exists();
  }
  /** Denotes if the instance of elements has been populated */
  elementsExists(): boolean {
    return !!this.props &&
        this.props.elements instanceof Array &&
        (< MultiElement[]>this.props.elements).every((item) => {
            return item instanceof MultiElement && item.exists();
        });
  }
  /** Populate the current instance from an object */
  fromObject(object: MultiChoiceCategoryInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.category_image !== null && typeof this.props.category_image === 'object') {
        this.props.category_image = new Image(< ImageInterface>this.props.category_image);
    }
    if (this.props.environment_image !== null && typeof this.props.environment_image === 'object') {
        this.props.environment_image = new Image(< ImageInterface>this.props.environment_image);
    }
    if (this.props.elements instanceof Array) {
        this.props.elements = (< MultiElementInterface[]>this.props.elements).map((item) => {
            return typeof item === 'object' ? new MultiElement(item) : item;
        });
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MultiChoiceCategoryInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.category_image === 'undefined') {
        props.category_image = null;
    }
    if (typeof props.environment_image === 'undefined') {
        props.environment_image = null;
    }
    if (typeof props.elements === 'undefined') {
        props.elements = [];
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.category_image !== null && props.category_image instanceof Image) {
        props.category_image = props.category_image.toObject();
    }
    if (props.environment_image !== null && props.environment_image instanceof Image) {
        props.environment_image = props.environment_image.toObject();
    }
    if (this.props.elements instanceof Array) {
        props.elements = (< MultiElement[]>props.elements).map((item) => {
            return item instanceof MultiElement ? item.toObject() : item;
        });
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MultiChoiceCategoryPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MultiChoiceCategoryInterface);
    payload.category_image = payload.category_image ? this.extractId(payload.category_image) : null;
    payload.environment_image = payload.environment_image ? this.extractId(payload.environment_image) : null;
    payload.elements = payload.elements ? payload.elements.map(i => this.extractId(i)) : null;
    return payload as MultiChoiceCategoryPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MultiChoiceCategoryPayloadKey[] {
  	return [
      'name',
      'question',
      'result_type',
      'category_image',
      'environment_image',
      'elements',
  	];
  }
}
