import { Component } from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import { ErrorService } from '@app/services';
import { VideoSearchParams } from '../video-search-params';
import { VideoService } from '../video.service';
import { Video } from '../video';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
	selector: 'hpf-video-select',
	templateUrl: 'video-select.component.html',
})
export class VideoSelectComponent extends EntitySelectComponent<Video> {
	protected modelName = 'video';
	/**
	 * Constructor
	 * @param {VideoService} videoService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private videoService: VideoService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	ngOnInit() {
		super.ngOnInit();
		// Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if (!(model instanceof Video)) return;
					if (this.multiple) {
						if (!this.model) this.model = [];
						(<Video[]>this.model).push(<Video>model);
					} else {
						this.model = <Video>model;
					}
					this.refresh();
				}
			)
		);
	}
	public getModels() {
		if (this.model == undefined) return [];
		if (!Array.isArray(this.model)) return [this.model];
		return this.model;
	}

	/** @inheritDoc */
	protected async getList(): Promise<Video[]> {
		const params = new VideoSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.videoService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Video> {
		return await this.videoService.get(id);
	}
}
