import {Injectable} from '@angular/core';
import {ActivityModule, ActivityModuleInterface, ActivityModulePayload} from './activity-module';
import {ActivityModuleSearchParamsInterface} from './activity-module-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class ActivityModuleService extends BaseModelService< ActivityModule, ActivityModuleInterface, ActivityModulePayload, ActivityModuleSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'activity-module';
  }
  /** @inheritDoc */
  protected new(object: ActivityModuleInterface): ActivityModule {
    return new ActivityModule(object);
  }
}
