import {Injectable} from '@angular/core';
import {ClientInfo, ClientInfoInterface, ClientInfoPayload} from './client-info';
import {ClientInfoSearchParamsInterface} from './client-info-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class ClientInfoService extends BaseModelService< ClientInfo, ClientInfoInterface, ClientInfoPayload, ClientInfoSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'client-info';
  }
  /** @inheritDoc */
  protected new(object: ClientInfoInterface): ClientInfo {
    return new ClientInfo(object);
  }
}
