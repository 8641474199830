<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Title -->

                
                <nz-form-item>
                    <h4>{{ 'media-audio_title' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="title">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Title -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Thumbnail -->

                
                <nz-form-item>
                    <h4>{{ 'media-audio_thumbnail' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="thumbnail"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Thumbnail -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Audio -->

                
                <nz-form-item>
                    <h4>{{ 'media-audio_audio' | translate }}</h4>
                    <nz-form-control>

         

        

 

                        <hpf-audio-select
                            [controlName]="'audio'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="value.props.audio"
                            [filterEnabled]="true"
                            [placeholder]="'audio_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-audio-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Audio -->
            </div>
        </div>
  </div>
  