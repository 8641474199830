import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-multi-element',
  templateUrl: './multi-element.component.html'
})
export class MultiElementComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
