import {Injectable} from '@angular/core';
import {Pdf, PdfInterface, PdfPayload} from './pdf';
import {PdfSearchParamsInterface} from './pdf-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class PdfService extends BaseModelService< Pdf, PdfInterface, PdfPayload, PdfSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'pdf';
  }
  /** @inheritDoc */
  protected new(object: PdfInterface): Pdf {
    return new Pdf(object);
  }
}
