
interface ModelLink {
  label: string;
  link: string[];
  model: string;
}
export const ModelsLinks: ModelLink[] = [
  {
    label: 'Activity Module',
    link: ['/activity-module'],
		model: 'activity_module'
  },
  {
    label: 'Audio',
    link: ['/audio'],
		model: 'audio'
  },
  {
    label: 'Client Info',
    link: ['/client-info'],
		model: 'client_info'
  },
  {
    label: 'Image',
    link: ['/image'],
		model: 'image'
  },
  {
    label: 'Image 360',
    link: ['/image-360'],
		model: 'image_360'
  },
  {
    label: 'Instruction',
    link: ['/instruction'],
		model: 'instruction'
  },
  {
    label: 'Itinerary',
    link: ['/itinerary'],
		model: 'itinerary'
  },
  {
    label: 'Media',
    link: ['/media'],
		model: 'media'
  },
  {
    label: 'Media 360 Image',
    link: ['/media-360-image'],
		model: 'media_360_image'
  },
  {
    label: 'Media 360 Video',
    link: ['/media-360-video'],
		model: 'media_360_video'
  },
  {
    label: 'Media Audio',
    link: ['/media-audio'],
		model: 'media_audio'
  },
  {
    label: 'Media Image',
    link: ['/media-image'],
		model: 'media_image'
  },
  {
    label: 'Media PDF',
    link: ['/media-pdf'],
		model: 'media_pdf'
  },
  {
    label: 'Media Video',
    link: ['/media-video'],
		model: 'media_video'
  },
  {
    label: 'Module',
    link: ['/module'],
		model: 'module'
  },
  {
    label: 'Multi Choice Category',
    link: ['/multi-choice-category'],
		model: 'multi_choice_category'
  },
  {
    label: 'Multi Choice Quiz Module',
    link: ['/multi-choice-quiz-module'],
		model: 'multi_choice_quiz_module'
  },
  {
    label: 'Multi Element',
    link: ['/multi-element'],
		model: 'multi_element'
  },
  {
    label: 'Multimedia Module',
    link: ['/multimedia-module'],
		model: 'multimedia_module'
  },
  {
    label: 'Oral Category',
    link: ['/oral-category'],
		model: 'oral_category'
  },
  {
    label: 'Oral Quiz Module',
    link: ['/oral-quiz-module'],
		model: 'oral_quiz_module'
  },
  {
    label: 'Pdf',
    link: ['/pdf'],
		model: 'pdf'
  },
  {
    label: 'Photo Module',
    link: ['/photo-module'],
		model: 'photo_module'
  },
  {
    label: 'Point Of Interest',
    link: ['/point-of-interest'],
		model: 'point_of_interest'
  },
  {
    label: 'User',
    link: ['/user'],
		model: 'user'
  },
  {
    label: 'Video',
    link: ['/video'],
		model: 'video'
  },
];
