import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MediaAudio} from '../../media-audio';

@Component({
  selector: 'hpf-media-audio-create',
  templateUrl: './media-audio-create.component.html'
})
export class MediaAudioCreateComponent implements OnInit {
  /** The media audio */
  mediaAudio = new MediaAudio();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media audio is created */
  onCreate(mediaAudio: MediaAudio): void {
    this.router.navigateByUrl(`/media-audio/${mediaAudio.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
