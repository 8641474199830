import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {MultiChoiceQuizModule} from '../multi-choice-quiz-module';
import {MultiChoiceQuizModuleService} from '../multi-choice-quiz-module.service';
import {InstructionFormArrayComponent} from "../../instruction/instruction-form-array/instruction-form-array.component"
import {MultiChoiceCategoryFormArrayComponent} from "../../multi-choice-category/multi-choice-category-form-array/multi-choice-category-form-array.component"
import {Helpers} from '@app/shared';
@Component({
  selector: 'hpf-multi-choice-quiz-module-mini-form',
  templateUrl: './multi-choice-quiz-module-mini-form.component.html'
})
export class MultiChoiceQuizModuleMiniFormComponent implements OnInit{

    @Input() value: MultiChoiceQuizModule;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    @ViewChildren(InstructionFormArrayComponent) instructionsforms: QueryList<
		InstructionFormArrayComponent
	>;
    @ViewChildren(MultiChoiceCategoryFormArrayComponent) categoriesforms: QueryList<
		MultiChoiceCategoryFormArrayComponent
	>;
    constructor(private formBuilder: FormBuilder,private multiChoiceQuizModuleService: MultiChoiceQuizModuleService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    memory_aid: new FormControl(this.value.props.memory_aid, []),
                    answers: new FormControl(Helpers.toJSON(this.value.props.answers), []),
                    instructions: new FormControl(this.value.props.instructions, []),
                    categories: new FormControl(this.value.props.categories, []),
                });
    	    
    	 }
    public async submit()
    {
    for (let form of this.instructionsforms)
    {
        await form.submit();
    }
    for (let form of this.categoriesforms)
    {
        await form.submit();
    }
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.multiChoiceQuizModuleService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.multiChoiceQuizModuleService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
    if (typeof this.value.props.answers === 'string') {
        this.value.props.answers = this.value.props.answers ?
            JSON.parse(this.value.props.answers) : null;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'memory_aid': this.value.props.memory_aid,
     'answers': Helpers.toJSON(this.value.props.answers),
     'instructions': this.value.props.instructions,
     'categories': this.value.props.categories,
    });
  }
	@Output() init = new EventEmitter<MultiChoiceQuizModuleMiniFormComponent>();

}
