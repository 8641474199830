import {Injectable} from '@angular/core';
import {Media360Video, Media360VideoInterface, Media360VideoPayload} from './media-360-video';
import {Media360VideoSearchParamsInterface} from './media-360-video-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class Media360VideoService extends BaseModelService< Media360Video, Media360VideoInterface, Media360VideoPayload, Media360VideoSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media-360-video';
  }
  /** @inheritDoc */
  protected new(object: Media360VideoInterface): Media360Video {
    return new Media360Video(object);
  }
}
