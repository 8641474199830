import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {MediaImageSearchParams} from '../media-image-search-params';
import {MediaImageService} from '../media-image.service';
import {MediaImage} from '../media-image';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-media-image-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class MediaImageSelectComponent extends EntitySelectComponent< MediaImage> {
  protected modelName = 'media_image';
  /**
   * Constructor
   * @param {MediaImageService} mediaImageService
   * @param {ErrorService} errorService
   */
  constructor(private mediaImageService: MediaImageService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof MediaImage)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<MediaImage[]>this.model).push(<MediaImage>model);
          } else {
            this.model = <MediaImage>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< MediaImage[]> {
      const params = new MediaImageSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.mediaImageService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< MediaImage> {
      return await this.mediaImageService.get(id);
  }
}
