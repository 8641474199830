import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {PhotoModule} from '../../photo-module';

@Component({
  selector: 'hpf-photo-module-create',
  templateUrl: './photo-module-create.component.html'
})
export class PhotoModuleCreateComponent implements OnInit {
  /** The photo module */
  photoModule = new PhotoModule();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the photo module is created */
  onCreate(photoModule: PhotoModule): void {
    this.router.navigateByUrl(`/photo-module/${photoModule.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
