import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
export interface OralCategoryInterface extends BaseModelInterface {
  name: string;
  answers?: any;
  foreground_image?: (string | Image | ImageInterface);
  icon?: (string | Image | ImageInterface);
  should_shrink: boolean;
  color: string;
  created_at: number | Date;
}
export interface OralCategoryPayload {
  name: string;
  answers?: any;
  foreground_image?: (string | Image | ImageInterface);
  icon?: (string | Image | ImageInterface);
  should_shrink: boolean;
  color: string;
}
type OralCategoryPayloadKey = keyof OralCategoryPayload;
export class OralCategory extends BaseModel< OralCategoryInterface, OralCategoryPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Denotes if the instance of foreground image has been populated */
  foregroundImageExists(): boolean {
    return !!this.props &&
        this.props.foreground_image instanceof Image &&
        this.props.foreground_image.exists();
  }
  /** Denotes if the instance of icon has been populated */
  iconExists(): boolean {
    return !!this.props &&
        this.props.icon instanceof Image &&
        this.props.icon.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: OralCategoryInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.foreground_image !== null && typeof this.props.foreground_image === 'object') {
        this.props.foreground_image = new Image(< ImageInterface>this.props.foreground_image);
    }
    if (this.props.icon !== null && typeof this.props.icon === 'object') {
        this.props.icon = new Image(< ImageInterface>this.props.icon);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): OralCategoryInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.answers === 'undefined') {
        props.answers = null;
    }
    if (typeof props.foreground_image === 'undefined') {
        props.foreground_image = null;
    }
    if (typeof props.icon === 'undefined') {
        props.icon = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.foreground_image !== null && props.foreground_image instanceof Image) {
        props.foreground_image = props.foreground_image.toObject();
    }
    if (props.icon !== null && props.icon instanceof Image) {
        props.icon = props.icon.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): OralCategoryPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as OralCategoryInterface);
    payload.foreground_image = payload.foreground_image ? this.extractId(payload.foreground_image) : null;
    payload.icon = payload.icon ? this.extractId(payload.icon) : null;
    return payload as OralCategoryPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): OralCategoryPayloadKey[] {
  	return [
      'name',
      'answers',
      'foreground_image',
      'icon',
      'should_shrink',
      'color',
  	];
  }
}
