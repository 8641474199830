<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'video_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- File -->

                
                <nz-form-item>
                    <h4>{{ 'video_file' | translate }}</h4>
                    <nz-form-control>

         
                    </nz-form-control>
                </nz-form-item>
                <!-- /File -->
            </div>
        </div>
  </div>
  