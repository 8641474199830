
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChildren, QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService, ExplainErrorsService, SessionService} from '@app/services';
import { environment } from '@env/environment';
import {MediaVideo} from '../media-video';
import {MediaVideoService} from '../media-video.service';
@Component({
  selector: 'hpf-media-video-form',
  templateUrl: './media-video-form.component.html'
})
export class MediaVideoFormComponent implements OnInit, OnDestroy {
  /** The model subscription */
  private modelSubscription: RxJsSubscription;
  /** The media video to inject in the form */
  @Input() mediaVideo: MediaVideo;
  /** Called the save button is clicked for a new instance */
  @Output() create = new EventEmitter<MediaVideo>();
  /** Called the save button is clicked for an existing instance */
  @Output() update = new EventEmitter<void>();
  /** Called the delete button is clicked */
  @Output() delete = new EventEmitter<void>();
  /** Set loading state of the form */
  @Input() loading = false;
  /** Enable the deletion button */
  @Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
  /** Denotes if deltion in progress */
  deleting = false;
  /** Show delete modal */
  deleteModal = false;
  /** The form group to use */
  form: FormGroup;
  /** Denotes if the form is pending */
  saving = false;
  /** Check permission of update **/
  readOnly = false;
  /** Constructor */
  constructor(private formBuilder: FormBuilder,
              private errorService: ErrorService,
              public explainErrorsService: ExplainErrorsService,
              private mediaVideoService: MediaVideoService,
              private sessionService: SessionService) {
  }
  /** Init */
  async ngOnInit() {
    this.readOnly = !(await this.sessionService.checkPermission(
			'media_video',
			'update'
		));

    // Init model
    if(!this.mediaVideo) this.mediaVideo = new MediaVideo();

    // Create form
    this.form = this.formBuilder.group({
    title: new FormControl(this.mediaVideo.props.title, [Validators.required]),
    thumbnail: new FormControl(this.mediaVideo.props.thumbnail, []),
    video: new FormControl(this.mediaVideo.props.video, [Validators.required]),
    subtitles: new FormControl(this.mediaVideo.props.subtitles, []),
    });

    // Update form when model loads or changes
    this.modelSubscription = this.mediaVideo.subscribe(() => {
        this.updateForm();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.modelSubscription.unsubscribe();
  }
  /** Called on form submit */
  async onSubmit(): Promise<void> {
    // Saving flag
    this.saving = true;
    try {

        // Update model
        this.updateModel();
	
        // Creation or update ?
        if (this.mediaVideo.isNew()) {
            // Creation
            const mediaVideo: MediaVideo = await this.mediaVideoService.create(this.mediaVideo.toPayload());
            this.create.next(mediaVideo);
        } else {
            // Update
            await this.mediaVideoService.update(this.mediaVideo.getId(), this.mediaVideo.toPayload());
            this.update.next();
        }
    } catch (error) {
        this.errorService.handle(error);
    }
    // Saving flag
    this.saving = false
  }
  /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.mediaVideo.props[key] = this.form.get(key).value;
    }
  }
  /** Update inputs values from models properties */
  private updateForm(): void {
    this.form.setValue({
     'title': this.mediaVideo.props.title,
     'thumbnail': this.mediaVideo.props.thumbnail,
     'video': this.mediaVideo.props.video,
     'subtitles': this.mediaVideo.props.subtitles,
    });
  }
  /** Called on deletion */
  onDelete(): void {
    this.deleting = true;
    this.mediaVideoService.remove(this.mediaVideo.getId())
      .then(() => {
        this.delete.next();
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => this.deleting = false);
  }
}
