import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {ClientInfo} from '../../client-info';
import {ClientInfoService} from '../../client-info.service';
@Component({
  selector: 'hpf-client-info-edit',
  templateUrl: './client-info-edit.component.html'
})
export class ClientInfoEditComponent implements OnInit, OnDestroy {
  /** The client info */
  clientInfo = new ClientInfo();
  /** The client info */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private clientInfoService: ClientInfoService) {}
  /** Init */
  ngOnInit() {
    // Get the client info after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch client info from API */
  private refresh() {
    this.clientInfoService.get(this.id, { _populate: ['*'] })
      .then((clientInfo: ClientInfo) => {
        this.clientInfo.fromObject(clientInfo.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the client info is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the client info is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/client-info')
      .catch((error) => this.errorService.handle(error));
  }
}
