<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Title -->

                
                <nz-form-item>
                    <h4>{{ 'instruction_title' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="title">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Title -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Body -->

                
                <nz-form-item>
                    <h4>{{ 'instruction_body' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100"
                                  nz-input
                                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                                  [placeholder]="'common_value-string' | translate"
                                  formControlName="body"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Body -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Image -->

                
                <nz-form-item>
                    <h4>{{ 'instruction_image' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="image"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Image -->
            </div>
        </div>
  </div>
  