import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {PointOfInterest} from '../point-of-interest';
import {PointOfInterestService} from '../point-of-interest.service';
@Component({
  selector: 'hpf-point-of-interest-mini-form',
  templateUrl: './point-of-interest-mini-form.component.html'
})
export class PointOfInterestMiniFormComponent implements OnInit{

    @Input() value: PointOfInterest;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private pointOfInterestService: PointOfInterestService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    details: new FormControl(this.value.props.details, [Validators.required]),
                    picture: new FormControl(this.value.props.picture, []),
                    latitude: new FormControl(this.value.props.latitude, [Validators.min(-90), Validators.max(90), Validators.required]),
                    longitude: new FormControl(this.value.props.longitude, [Validators.min(-180), Validators.max(180), Validators.required]),
                    modules: new FormControl(this.value.props.modules, []),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.pointOfInterestService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.pointOfInterestService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'details': this.value.props.details,
     'picture': this.value.props.picture,
     'latitude': this.value.props.latitude,
     'longitude': this.value.props.longitude,
     'modules': this.value.props.modules,
    });
  }
	@Output() init = new EventEmitter<PointOfInterestMiniFormComponent>();

}
