import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {ModuleSearchParams} from '../module-search-params';

@Component({
  selector: 'hpf-module-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class ModuleListTopBarComponent extends EntityListTopBarComponent< ModuleSearchParams> {
}
 