import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Instruction} from '../../instruction';
import {InstructionService} from '../../instruction.service';
@Component({
  selector: 'hpf-instruction-edit',
  templateUrl: './instruction-edit.component.html'
})
export class InstructionEditComponent implements OnInit, OnDestroy {
  /** The instruction */
  instruction = new Instruction();
  /** The instruction */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private instructionService: InstructionService) {}
  /** Init */
  ngOnInit() {
    // Get the instruction after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch instruction from API */
  private refresh() {
    this.instructionService.get(this.id, { _populate: ['*'] })
      .then((instruction: Instruction) => {
        this.instruction.fromObject(instruction.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the instruction is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the instruction is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/instruction')
      .catch((error) => this.errorService.handle(error));
  }
}
