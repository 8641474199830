import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { environment } from '@env/environment';
export interface ImageInterface extends BaseModelInterface {
	name: string;
	file_ext: string;
	size: number;
	file: {};
	created_at: number | Date;
}
export interface ImagePayload {
	name: string;
	size: number;
	file: {};
}

type ImagePayloadKey = keyof ImagePayload;
export class Image extends BaseModel<ImageInterface, ImagePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	getUri(): string {
		return `${environment.api.uri}/image/view/${this.props._id}`;
	}
	getThumbnail(): string {
		return `${environment.api.uri}/image/thumbnail/${this.props._id}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: ImageInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ImageInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ImagePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ImageInterface);

		const formData = new FormData();
		for (const key in payload) {
			formData.append(key, payload[key]);
		}
		return payload as ImagePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ImagePayloadKey[] {
		return ['name', 'size', 'file'];
	}
}
