<div>
  <div *ngFor="let item of value;index as i">

    <input [value]="item" nz-input class="col-10" (focusout)="value[i]=$event.target.value" [type]="inputType">

    <span class="btn btn-danger col-2" (click)="remove(i)">{{ 'common_remove' | translate }}</span>
  </div>

  <div class="row">
    <span class="col-12 btn btn-primary" (click)="add()">{{ 'common_add' | translate }}</span>
  </div>
</div>
