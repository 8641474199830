import {Injectable} from '@angular/core';
import {Instruction, InstructionInterface, InstructionPayload} from './instruction';
import {InstructionSearchParamsInterface} from './instruction-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class InstructionService extends BaseModelService< Instruction, InstructionInterface, InstructionPayload, InstructionSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'instruction';
  }
  /** @inheritDoc */
  protected new(object: InstructionInterface): Instruction {
    return new Instruction(object);
  }
}
