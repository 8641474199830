import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {ClientInfo} from '../client-info';
import {ClientInfoService} from '../client-info.service';
@Component({
  selector: 'hpf-client-info-mini-form',
  templateUrl: './client-info-mini-form.component.html'
})
export class ClientInfoMiniFormComponent implements OnInit{

    @Input() value: ClientInfo;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private clientInfoService: ClientInfoService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    email: new FormControl(this.value.props.email, [Validators.email, Validators.required]),
                    invitation: new FormControl(this.value.props.invitation, [Validators.required]),
                    newsletter: new FormControl(this.value.props.newsletter, [Validators.required]),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.clientInfoService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.clientInfoService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'email': this.value.props.email,
     'invitation': this.value.props.invitation,
     'newsletter': this.value.props.newsletter,
    });
  }
	@Output() init = new EventEmitter<ClientInfoMiniFormComponent>();

}
