import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Instruction} from '../../instruction';

@Component({
  selector: 'hpf-instruction-create',
  templateUrl: './instruction-create.component.html'
})
export class InstructionCreateComponent implements OnInit {
  /** The instruction */
  instruction = new Instruction();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the instruction is created */
  onCreate(instruction: Instruction): void {
    this.router.navigateByUrl(`/instruction/${instruction.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
