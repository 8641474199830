import {Component, EventEmitter, ViewChildren,Input, OnInit, OnDestroy, Output,QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {environment } from '@env/environment';
import {MultiElement} from '../multi-element';
import {MultiElementService} from '../multi-element.service';
@Component({
  selector: 'hpf-multi-element-mini-form',
  templateUrl: './multi-element-mini-form.component.html'
})
export class MultiElementMiniFormComponent implements OnInit{

    @Input() value: MultiElement;

    @Input() quickFormEnabled = environment.plugins.quickForm.enabled;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private multiElementService: MultiElementService) {}

    
    	 ngOnInit() {
    	     this.init.next(this);
    	     
    	    
            this.form = this.formBuilder.group({
                    name: new FormControl(this.value.props.name, [Validators.required]),
                    description: new FormControl(this.value.props.description, [Validators.required]),
                    answer: new FormControl(this.value.props.answer, [Validators.required]),
                    media: new FormControl(this.value.props.media, []),
                    image: new FormControl(this.value.props.image, []),
                });
    	    
    	 }
    public async submit()
    {
          this.updateModel();
     if (this.value.isNew())
    {
        var payload= this.value.toPayload();
    var created=    await this.multiElementService.create(payload);
        this.value.props._id=created.getId();

    }
    else
    {        var payload= this.value.toPayload();
        await this.multiElementService.update(this.value.getId(),payload);
    }
    }

      /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.value.props[key] = this.form.get(key).value;
    }
}
  private updateForm(): void {
    this.form.setValue({
     'name': this.value.props.name,
     'description': this.value.props.description,
     'answer': this.value.props.answer,
     'media': this.value.props.media,
     'image': this.value.props.image,
    });
  }
	@Output() init = new EventEmitter<MultiElementMiniFormComponent>();

}
