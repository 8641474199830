import { QueryList, ViewChildren, Component, Input } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {PointOfInterest,PointOfInterestMiniFormComponent} from '../index';
import {PointOfInterestService} from '../point-of-interest.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
	selector: 'point-of-interest-form-array',
	templateUrl: './point-of-interest-form-array.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PointOfInterestFormArrayComponent,
		},
	],
})
export class PointOfInterestFormArrayComponent implements ControlValueAccessor{

    @Input() items: PointOfInterest[];

    onChange = (t) => {};
	onTouched = () => {};
    disabled = false;
	touched = false;

    removed:PointOfInterest[]=[];
    constructor(private pointOfInterestService: PointOfInterestService) {}

    public async submit()
    {
         for(let form of this.viewChildren)
         {
            await form.submit();
         }

         for(let rm of this.removed)
         {
            await this.pointOfInterestService.remove(rm.getId());
         }
         this.onChange(this.items);
    }

    writeValue(obj: PointOfInterest[]): void {
		this.items = obj;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	
	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}
	add():void
	{
	    var model=new PointOfInterest();
	    	if (!(this.items instanceof Array)) {
			this.items = [];
		}
	    this.items.push(model);
	}
	remove(item):void
	{
	   const index = this.items.indexOf(item, 0);
         if (index > -1) {
         this.items.splice(index, 1);
        }
        if(!item.isNew())
        {
            this.removed.push(item);  
        }
	}
	   @ViewChildren(PointOfInterestMiniFormComponent) viewChildren!: QueryList<PointOfInterestMiniFormComponent>;

}