import {Injectable} from '@angular/core';
import {MediaAudio, MediaAudioInterface, MediaAudioPayload} from './media-audio';
import {MediaAudioSearchParamsInterface} from './media-audio-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MediaAudioService extends BaseModelService< MediaAudio, MediaAudioInterface, MediaAudioPayload, MediaAudioSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'media-audio';
  }
  /** @inheritDoc */
  protected new(object: MediaAudioInterface): MediaAudio {
    return new MediaAudio(object);
  }
}
