import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MediaPDF} from '../../media-pdf';

@Component({
  selector: 'hpf-media-pdf-create',
  templateUrl: './media-pdf-create.component.html'
})
export class MediaPDFCreateComponent implements OnInit {
  /** The media pdf */
  mediaPDF = new MediaPDF();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media pdf is created */
  onCreate(mediaPDF: MediaPDF): void {
    this.router.navigateByUrl(`/media-pdf/${mediaPDF.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
