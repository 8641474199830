import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {PhotoModuleSearchParams} from '../photo-module-search-params';

@Component({
  selector: 'hpf-photo-module-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class PhotoModuleListTopBarComponent extends EntityListTopBarComponent< PhotoModuleSearchParams> {
}
 