<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Title -->

                
                <nz-form-item>
                    <h4>{{ 'media-video_title' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="title">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Title -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Thumbnail -->

                
                <nz-form-item>
                    <h4>{{ 'media-video_thumbnail' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="thumbnail"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Thumbnail -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Video -->

                
                <nz-form-item>
                    <h4>{{ 'media-video_video' | translate }}</h4>
                    <nz-form-control>

         

        

 

                        <hpf-video-select
                            [controlName]="'video'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="value.props.video"
                            [filterEnabled]="true"
                            [placeholder]="'video_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-video-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Video -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Subtitles -->

                
                <nz-form-item>
                    <h4>{{ 'media-video_subtitles' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="subtitles">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Subtitles -->
            </div>
        </div>
  </div>
  