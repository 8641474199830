<div class="container">
<div class="row" *ngFor="let item of items" (init)="forms.push($event)">
<div class="col-12">
<hpf-multi-choice-quiz-module-mini-form [value]="item"></hpf-multi-choice-quiz-module-mini-form>
    </div>
    <div class="col-12">
      <span class="btn btn-danger float-right" (click)="remove(item)">{{ 'common_remove' | translate }}</span>
    </div>
    <hr class="col-12">
  </div>
  <div class="row">
    <span class="col-12 btn btn-primary" (click)="add()">{{ 'common_add' | translate }}</span>
  </div>
</div>
