import { QueryList, ViewChildren, Component, Input } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {User,UserMiniFormComponent} from '../index';
import {UserService} from '../user.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
	selector: 'user-form-array',
	templateUrl: './user-form-array.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: UserFormArrayComponent,
		},
	],
})
export class UserFormArrayComponent implements ControlValueAccessor{

    @Input() items: User[];

    onChange = (t) => {};
	onTouched = () => {};
    disabled = false;
	touched = false;

    removed:User[]=[];
    constructor(private userService: UserService) {}

    public async submit()
    {
         for(let form of this.viewChildren)
         {
            await form.submit();
         }

         for(let rm of this.removed)
         {
            await this.userService.remove(rm.getId());
         }
         this.onChange(this.items);
    }

    writeValue(obj: User[]): void {
		this.items = obj;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	
	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}
	add():void
	{
	    var model=new User();
	    	if (!(this.items instanceof Array)) {
			this.items = [];
		}
	    this.items.push(model);
	}
	remove(item):void
	{
	   const index = this.items.indexOf(item, 0);
         if (index > -1) {
         this.items.splice(index, 1);
        }
        if(!item.isNew())
        {
            this.removed.push(item);  
        }
	}
	   @ViewChildren(UserMiniFormComponent) viewChildren!: QueryList<UserMiniFormComponent>;

}