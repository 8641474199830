import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Media, MediaInterface} from '../media/media';
import {Image, ImageInterface} from '../image/image';
export interface MultiElementInterface extends BaseModelInterface {
  name: string;
  description: string;
  answer: number;
  media?: (string | Media | MediaInterface);
  image?: (string | Image | ImageInterface);
  created_at: number | Date;
}
export interface MultiElementPayload {
  name: string;
  description: string;
  answer: number;
  media?: (string | Media | MediaInterface);
  image?: (string | Image | ImageInterface);
}
type MultiElementPayloadKey = keyof MultiElementPayload;
export class MultiElement extends BaseModel< MultiElementInterface, MultiElementPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Denotes if the instance of media has been populated */
  mediaExists(): boolean {
    return !!this.props &&
        this.props.media instanceof Media &&
        this.props.media.exists();
  }
  /** Denotes if the instance of image has been populated */
  imageExists(): boolean {
    return !!this.props &&
        this.props.image instanceof Image &&
        this.props.image.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: MultiElementInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.media !== null && typeof this.props.media === 'object') {
        this.props.media = new Media(< MediaInterface>this.props.media);
    }
    if (this.props.image !== null && typeof this.props.image === 'object') {
        this.props.image = new Image(< ImageInterface>this.props.image);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MultiElementInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.media === 'undefined') {
        props.media = null;
    }
    if (typeof props.image === 'undefined') {
        props.image = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.media !== null && props.media instanceof Media) {
        props.media = props.media.toObject();
    }
    if (props.image !== null && props.image instanceof Image) {
        props.image = props.image.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MultiElementPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MultiElementInterface);
    payload.media = payload.media ? this.extractId(payload.media) : null;
    payload.image = payload.image ? this.extractId(payload.image) : null;
    return payload as MultiElementPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MultiElementPayloadKey[] {
  	return [
      'name',
      'description',
      'answer',
      'media',
      'image',
  	];
  }
}
