import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Module} from '../../module';
import {ModuleService} from '../../module.service';
@Component({
  selector: 'hpf-module-edit',
  templateUrl: './module-edit.component.html'
})
export class ModuleEditComponent implements OnInit, OnDestroy {
  /** The module */
  module = new Module();
  /** The module */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private moduleService: ModuleService) {}
  /** Init */
  ngOnInit() {
    // Get the module after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch module from API */
  private refresh() {
    this.moduleService.get(this.id, { _populate: ['*'] })
      .then((module: Module) => {
        this.module.fromObject(module.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the module is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the module is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/module')
      .catch((error) => this.errorService.handle(error));
  }
}
