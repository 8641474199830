import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Media360Image} from '../../media-360-image';

@Component({
  selector: 'hpf-media-360-image-create',
  templateUrl: './media-360-image-create.component.html'
})
export class Media360ImageCreateComponent implements OnInit {
  /** The media 360 image */
  media360Image = new Media360Image();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the media 360 image is created */
  onCreate(media360Image: Media360Image): void {
    this.router.navigateByUrl(`/media-360-image/${media360Image.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
