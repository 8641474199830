import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MultiElement} from '../../multi-element';

@Component({
  selector: 'hpf-multi-element-create',
  templateUrl: './multi-element-create.component.html'
})
export class MultiElementCreateComponent implements OnInit {
  /** The multi element */
  multiElement = new MultiElement();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the multi element is created */
  onCreate(multiElement: MultiElement): void {
    this.router.navigateByUrl(`/multi-element/${multiElement.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
