import {Injectable} from '@angular/core';
import {MultiChoiceCategory, MultiChoiceCategoryInterface, MultiChoiceCategoryPayload} from './multi-choice-category';
import {MultiChoiceCategorySearchParamsInterface} from './multi-choice-category-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MultiChoiceCategoryService extends BaseModelService< MultiChoiceCategory, MultiChoiceCategoryInterface, MultiChoiceCategoryPayload, MultiChoiceCategorySearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'multi-choice-category';
  }
  /** @inheritDoc */
  protected new(object: MultiChoiceCategoryInterface): MultiChoiceCategory {
    return new MultiChoiceCategory(object);
  }
}
