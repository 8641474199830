import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Itinerary } from '../itinerary';
import { ItineraryService } from '../itinerary.service';
import { AssetBundler } from '@app/shared/assetBundler/assetbundler';
@Component({
	selector: 'hpf-itinerary-form',
	templateUrl: './itinerary-form.component.html',
})
export class ItineraryFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The itinerary to inject in the form */
	@Input() itinerary: Itinerary;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Itinerary>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;

	/** Show assetbundle modal */
	assetModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private itineraryService: ItineraryService,
		private sessionService: SessionService,
		private assetbundler: AssetBundler
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'itinerary',
			'update'
		));

		// Init model
		if (!this.itinerary) this.itinerary = new Itinerary();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.itinerary.props.name, [
				Validators.required,
			]),
			details: new FormControl(this.itinerary.props.details, [
				Validators.required,
			]),
			point_of_interests: new FormControl(
				this.itinerary.props.point_of_interests,
				[]
			),
		});

		// Update form when model loads or changes
		this.modelSubscription = this.itinerary.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();

			// Creation or update ?
			if (this.itinerary.isNew()) {
				// Creation
				const itinerary: Itinerary = await this.itineraryService.create(
					this.itinerary.toPayload()
				);
				this.create.next(itinerary);
			} else {
				// Update
				await this.itineraryService.update(
					this.itinerary.getId(),
					this.itinerary.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.itinerary.props[key] = this.form.get(key).value;
		}
	}
	async createBundle() {
		try {
			await this.assetbundler.createBundle(this.itinerary.getId());
			window.location.reload();
		} catch (error) {
			this.errorService.handle(error);
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.itinerary.props.name,
			details: this.itinerary.props.details,
			point_of_interests: this.itinerary.props.point_of_interests,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.itineraryService
			.remove(this.itinerary.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
