import {Injectable} from '@angular/core';
import {MultiElement, MultiElementInterface, MultiElementPayload} from './multi-element';
import {MultiElementSearchParamsInterface} from './multi-element-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MultiElementService extends BaseModelService< MultiElement, MultiElementInterface, MultiElementPayload, MultiElementSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'multi-element';
  }
  /** @inheritDoc */
  protected new(object: MultiElementInterface): MultiElement {
    return new MultiElement(object);
  }
}
