
<!-- Title -->
<nz-form-item>
    <nz-form-label>{{ 'media_title' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.title">
</nz-form-item>
<!-- /Title -->
<!-- Type -->
<nz-form-item>
    <nz-form-label>{{ 'media_type' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.type">
</nz-form-item>
<!-- /Type -->
<button
	nz-button
	nzType="default"
	(click)="resetSearchParams()"
>
	{{ 'common_reset-filters' | translate }}
</button>
