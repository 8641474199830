import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {MultiChoiceQuizModuleSearchParams} from '../multi-choice-quiz-module-search-params';

@Component({
  selector: 'hpf-multi-choice-quiz-module-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class MultiChoiceQuizModuleListTopBarComponent extends EntityListTopBarComponent< MultiChoiceQuizModuleSearchParams> {
}
 