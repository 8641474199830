import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {PointOfInterest, PointOfInterestInterface} from '../point-of-interest/point-of-interest';
export interface ItineraryInterface extends BaseModelInterface {
  name: string;
  details: string;
  point_of_interests?: (string | PointOfInterest | PointOfInterestInterface)[];
  created_at: number | Date;
}
export interface ItineraryPayload {
  name: string;
  details: string;
  point_of_interests?: (string | PointOfInterest | PointOfInterestInterface)[];
}
type ItineraryPayloadKey = keyof ItineraryPayload;
export class Itinerary extends BaseModel< ItineraryInterface, ItineraryPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return this.props._id;
  }
  /** Denotes if the instance of point of interests has been populated */
  pointOfInterestsExists(): boolean {
    return !!this.props &&
        this.props.point_of_interests instanceof Array &&
        (< PointOfInterest[]>this.props.point_of_interests).every((item) => {
            return item instanceof PointOfInterest && item.exists();
        });
  }
  /** Populate the current instance from an object */
  fromObject(object: ItineraryInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.point_of_interests instanceof Array) {
        this.props.point_of_interests = (< PointOfInterestInterface[]>this.props.point_of_interests).map((item) => {
            return typeof item === 'object' ? new PointOfInterest(item) : item;
        });
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): ItineraryInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.point_of_interests === 'undefined') {
        props.point_of_interests = [];
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (this.props.point_of_interests instanceof Array) {
        props.point_of_interests = (< PointOfInterest[]>props.point_of_interests).map((item) => {
            return item instanceof PointOfInterest ? item.toObject() : item;
        });
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): ItineraryPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as ItineraryInterface);
    payload.point_of_interests = payload.point_of_interests ? payload.point_of_interests.map(i => this.extractId(i)) : null;
    return payload as ItineraryPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): ItineraryPayloadKey[] {
  	return [
      'name',
      'details',
      'point_of_interests',
  	];
  }
}
