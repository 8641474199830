import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
export interface PhotoModuleInterface extends BaseModelInterface {
	state_id: any;
	created_at: number | Date;
	name: string;
	memory_aid: string;
}
export interface PhotoModulePayload {
	name: string;
	memory_aid: string;
	state_id: string;
}
type PhotoModulePayloadKey = keyof PhotoModulePayload;
export class PhotoModule extends BaseModel<
	PhotoModuleInterface,
	PhotoModulePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Populate the current instance from an object */
	fromObject(object: PhotoModuleInterface): void {
		this.props = Object.assign({}, object);

		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): PhotoModuleInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): PhotoModulePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as PhotoModuleInterface);
		return payload as PhotoModulePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): PhotoModulePayloadKey[] {
		return ['name', 'memory_aid', 'state_id'];
	}
}
