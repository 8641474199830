import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {OralCategory} from '../../oral-category';

@Component({
  selector: 'hpf-oral-category-create',
  templateUrl: './oral-category-create.component.html'
})
export class OralCategoryCreateComponent implements OnInit {
  /** The oral category */
  oralCategory = new OralCategory();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the oral category is created */
  onCreate(oralCategory: OralCategory): void {
    this.router.navigateByUrl(`/oral-category/${oralCategory.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
