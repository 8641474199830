<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'multi-choice-quiz-module_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Memory Aid -->

                
                <nz-form-item>
                    <h4>{{ 'multi-choice-quiz-module_memory-aid' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100"
                                  nz-input
                                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                                  [placeholder]="'common_value-string' | translate"
                                  formControlName="memory_aid"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Memory Aid -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Answers -->

                
                <nz-form-item>
                    <h4>{{ 'multi-choice-quiz-module_answers' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100 text-monospace"
                                  nz-input
                                  [nzAutosize]="{ minRows: 6, maxRows: 12 }"
                                  [placeholder]="'common_value-object' | translate"
                                  formControlName="answers"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Answers -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Instructions -->

                
                <nz-form-item>
                    <h4>{{ 'multi-choice-quiz-module_instructions' | translate }}</h4>
                    <nz-form-control>

         
              <instruction-form-array formControlName="instructions"></instruction-form-array>

                    </nz-form-control>
                </nz-form-item>
                <!-- /Instructions -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Categories -->

                
                <nz-form-item>
                    <h4>{{ 'multi-choice-quiz-module_categories' | translate }}</h4>
                    <nz-form-control>

         
              <multi-choice-category-form-array formControlName="categories"></multi-choice-category-form-array>

                    </nz-form-control>
                </nz-form-item>
                <!-- /Categories -->
            </div>
        </div>
  </div>
  