import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {InstructionSearchParams} from '../instruction-search-params';
import {InstructionService} from '../instruction.service';
@Component({
  selector: 'hpf-instruction-count',
  templateUrl: './instruction-count.component.html'
})
export class InstructionCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: InstructionSearchParams = new InstructionSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private instructionService: InstructionService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.instructionService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
