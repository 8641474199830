import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {MediaPDFSearchParams} from '../media-pdf-search-params';

@Component({
  selector: 'hpf-media-pdf-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class MediaPDFListTopBarComponent extends EntityListTopBarComponent< MediaPDFSearchParams> {
}
 