import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-oral-category',
  templateUrl: './oral-category.component.html'
})
export class OralCategoryComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
