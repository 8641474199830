import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { User } from '../user';
import { UserService } from '../user.service';
@Component({
	selector: 'hpf-user-mini-form',
	templateUrl: './user-mini-form.component.html',
})
export class UserMiniFormComponent implements OnInit {
	value: User;

	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService
	) {}

	ngOnInit() {
		this.init.next(this);
		const hiddenRequired = this.value.isNew() ? [Validators.required] : [];

		this.form = this.formBuilder.group({
			name: new FormControl(this.value.props.name, [Validators.required]),
			role: new FormControl(this.value.props.role, [Validators.required]),
			email: new FormControl(this.value.props.email, [
				Validators.email,
				Validators.required,
			]),
			password: new FormControl(
				this.value.props.password,
				[Validators.minLength(6)].concat(hiddenRequired)
			),
		});
		this.updateForm();
	}
	public async submit() {
		this.updateModel();
		if (this.value.isNew()) {
			var payload = this.value.toPayload();
			var created = await this.userService.create(payload);
			this.value.props._id = created.getId();
		} else {
			var payload = this.value.toPayload();
			await this.userService.update(this.value.getId(), payload);
		}
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.value.props[key] = this.form.get(key).value;
		}
	}
	private updateForm(): void {
		this.form.setValue({
			name: this.value.props.name,
			role: this.value.props.role,
			email: this.value.props.email,
			password: this.value.props.password,
		});
	}
	@Output() init = new EventEmitter<UserMiniFormComponent>();
}
