
<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form nz-form
      class="multi-element-form w-100"
      *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      [nzAutoTips]="explainErrorsService.autoTips">
    <nz-affix>
        <div class="py-4 border-bottom bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-between">
            			<!-- Title -->
            			<div>
            				<h1 class="mb-0">
            					<span *ngIf="multiElement.isNew()">{{ 'multi-element_create-form-title' | translate }}</span>
            					<span *ngIf="!multiElement.isNew()">{{ multiElement.getLabel() }}</span>
            				</h1>
            				<small *ngIf="!multiElement.isNew()" class="text-black-50">{{ 'common_id' | translate: { id : multiElement.getId() } }}</small>
            			</div>
            			<!-- /Title -->
            			
            			<!-- Actions -->
            			<div>
            				<button
            					nz-button
            					[disabled]="form.invalid"
            					[nzLoading]="saving"
            					nzType="primary"
                                *appRestrictedByScope="{
                                    model: 'multi_element',
                                    action: 'update'
                                }"
            				>
            					{{ 'common_save' | translate }}
            				</button>
                            <ng-container *appRestrictedByScope="{
                                model: 'multi_element',
                                action: 'delete'
                            }">
                                <button
                                    *ngIf="!multiElement.isNew() && deletable"
                                    class="ml-4"
                                    nz-button
                                    nzShape="circle"
                                    [title]="'common_delete' | translate"
                                    [nzLoading]="deleting"
                                    (click)="deleteModal = true"
                                    type="button"
                                >
                                    <i nz-icon nzType="delete"></i>
                                </button>
                            </ng-container>
            			</div>
            			<!-- /Actions -->
            		</div>
            	</div>
            </div>
        </div>
    </nz-affix>
    <div class="container">
    <nz-card class="round-border my-5">
    <div *ngIf="loading" class="row">
        <div class="col-12">
            <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
            <div class="col-12  col-md-6" >
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_name' | translate }}</h4>
                    <nz-form-control>

             
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Description -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_description' | translate }}</h4>
                    <nz-form-control>

             
                        <textarea class="w-100"
                                  nz-input
                                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                                  [placeholder]="'common_value-string' | translate"
                                  formControlName="description"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Description -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Answer -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_answer' | translate }}</h4>
                    <nz-form-control>

             
                        <nz-input-number class="w-100"
                                         formControlName="answer" 
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Answer -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Media -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_media' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('media')">

             

                        <hpf-media-select
                            [controlName]="'media'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="multiElement.props.media"
                            [nullable]="true"
                            [filterEnabled]="true"
                            [placeholder]="'media_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-media-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Media -->
            </div>
            <div class="col-12  col-md-6" >
                <!-- Image -->

                
                <nz-form-item>
                    <h4>{{ 'multi-element_image' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('image')">

             

     <app-image-modal-button formControlName="image"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Image -->
            </div>
        </div>

        <nz-divider *ngIf="!multiElement.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!multiElement.isNew()" class="row">
            <div class="col-12 col-md-6" >
                <!-- _Id -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_-id' | translate }}</h4>
                    <div>{{ multiElement.props._id }}</div>
                </div>
                <!-- /_Id -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Name -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_name' | translate }}</h4>
                    <div>{{ multiElement.props.name }}</div>
                </div>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Description -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_description' | translate }}</h4>
                    <div [innerHTML]="multiElement.props.description | nl2br"></div>
                </div>
                <!-- /Description -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Answer -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_answer' | translate }}</h4>
                    <div>{{ multiElement.props.answer }}</div>
                </div>
                <!-- /Answer -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Media -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_media' | translate }}</h4>
                    <div *ngIf="multiElement.mediaExists()">
                        <a [routerLink]="['/media', multiElement.props.media.getId()]">
                            {{ multiElement.props.media.getLabel() }}
                        </a>
                    </div>
                </div>
                <!-- /Media -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Image -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_image' | translate }}</h4>
                    <div *ngIf="multiElement.imageExists()">
                        <a [routerLink]="['/image', multiElement.props.image.getId()]">
                            {{ multiElement.props.image.getLabel() }}
                        </a>
                    </div>
                </div>
                <!-- /Image -->
            </div>
            <div class="col-12 col-md-6" >
                <!-- Created At -->
                <div class="mb-4">
                    <h4>{{ 'multi-element_created-at' | translate }}</h4>
                    <div>{{ multiElement.props.created_at | date:'medium' }}</div>
                </div>
                <!-- /Created At -->
            </div>
        </div>
    </div>
    </nz-card>
    </div>
</form>
