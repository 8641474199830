<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'multimedia-module_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Memory Aid -->

                
                <nz-form-item>
                    <h4>{{ 'multimedia-module_memory-aid' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100"
                                  nz-input
                                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                                  [placeholder]="'common_value-string' | translate"
                                  formControlName="memory_aid"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Memory Aid -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Medias -->

                
                <nz-form-item>
                    <h4>{{ 'multimedia-module_medias' | translate }}</h4>
                    <nz-form-control>

         

        

 

                        <hpf-media-select
                            [controlName]="'medias'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="value.props.medias"
                            [filterEnabled]="true"
                            [placeholder]="'media_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-media-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Medias -->
            </div>
        </div>
  </div>
  