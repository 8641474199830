import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {MediaVideo} from '../../media-video';
import {MediaVideoService} from '../../media-video.service';
@Component({
  selector: 'hpf-media-video-edit',
  templateUrl: './media-video-edit.component.html'
})
export class MediaVideoEditComponent implements OnInit, OnDestroy {
  /** The media video */
  mediaVideo = new MediaVideo();
  /** The media video */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private mediaVideoService: MediaVideoService) {}
  /** Init */
  ngOnInit() {
    // Get the media video after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch media video from API */
  private refresh() {
    this.mediaVideoService.get(this.id, { _populate: ['*'] })
      .then((mediaVideo: MediaVideo) => {
        this.mediaVideo.fromObject(mediaVideo.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the media video is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the media video is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/media-video')
      .catch((error) => this.errorService.handle(error));
  }
}
