import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {MultiChoiceCategorySearchParams} from '../multi-choice-category-search-params';
import {MultiChoiceCategoryService} from '../multi-choice-category.service';
@Component({
  selector: 'hpf-multi-choice-category-count',
  templateUrl: './multi-choice-category-count.component.html'
})
export class MultiChoiceCategoryCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: MultiChoiceCategorySearchParams = new MultiChoiceCategorySearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private multiChoiceCategoryService: MultiChoiceCategoryService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.multiChoiceCategoryService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
