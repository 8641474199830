import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {PdfSearchParams} from '../pdf-search-params';

@Component({
  selector: 'hpf-pdf-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class PdfListTopBarComponent extends EntityListTopBarComponent< PdfSearchParams> {
}
 