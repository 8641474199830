import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { environment } from '@env/environment';
export interface VideoInterface extends BaseModelInterface {
	name: string;
	file_ext: string;
	file: any;
	created_at: number | Date;
}
export interface VideoPayload {
	name: string;
	file: any;
}
type VideoPayloadKey = keyof VideoPayload;
export class Video extends BaseModel<VideoInterface, VideoPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	getUri(): string {
		return `${environment.api.uri}/video/view/${this.props._id}.${this.props.file_ext}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: VideoInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): VideoInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): VideoPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as VideoInterface);
		return payload as VideoPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): VideoPayloadKey[] {
		return ['name', 'file'];
	}
}
