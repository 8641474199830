<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'client-info_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Email -->

                
                <nz-form-item>
                    <h4>{{ 'client-info_email' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="email"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="email">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Email -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Invitation -->

                
                <nz-form-item>
                    <h4>{{ 'client-info_invitation' | translate }}</h4>
                    <nz-form-control>

         
                        <nz-select class="w-100"
                                   [nzPlaceHolder]="'common_value-boolean' | translate"
                                   formControlName="invitation">
                            <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
                            <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Invitation -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Newsletter -->

                
                <nz-form-item>
                    <h4>{{ 'client-info_newsletter' | translate }}</h4>
                    <nz-form-control>

         
                        <nz-select class="w-100"
                                   [nzPlaceHolder]="'common_value-boolean' | translate"
                                   formControlName="newsletter">
                            <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
                            <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Newsletter -->
            </div>
        </div>
  </div>
  