
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChildren, QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService, ExplainErrorsService, SessionService} from '@app/services';
import { environment } from '@env/environment';
import {MultiElementFormArrayComponent} from "../../multi-element/multi-element-form-array/multi-element-form-array.component"
import {MultiChoiceCategory} from '../multi-choice-category';
import {MultiChoiceCategoryService} from '../multi-choice-category.service';
@Component({
  selector: 'hpf-multi-choice-category-form',
  templateUrl: './multi-choice-category-form.component.html'
})
export class MultiChoiceCategoryFormComponent implements OnInit, OnDestroy {
  /** The model subscription */
  private modelSubscription: RxJsSubscription;
  /** The multi choice category to inject in the form */
  @Input() multiChoiceCategory: MultiChoiceCategory;
  /** Called the save button is clicked for a new instance */
  @Output() create = new EventEmitter<MultiChoiceCategory>();
  /** Called the save button is clicked for an existing instance */
  @Output() update = new EventEmitter<void>();
  /** Called the delete button is clicked */
  @Output() delete = new EventEmitter<void>();
  /** Set loading state of the form */
  @Input() loading = false;
  /** Enable the deletion button */
  @Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
  /** Denotes if deltion in progress */
  deleting = false;
  /** Show delete modal */
  deleteModal = false;
  /** The form group to use */
  form: FormGroup;
  /** Denotes if the form is pending */
  saving = false;
  /** Check permission of update **/
  readOnly = false;
    @ViewChildren(MultiElementFormArrayComponent) elementsforms: QueryList<
		MultiElementFormArrayComponent
	>;
  /** Constructor */
  constructor(private formBuilder: FormBuilder,
              private errorService: ErrorService,
              public explainErrorsService: ExplainErrorsService,
              private multiChoiceCategoryService: MultiChoiceCategoryService,
              private sessionService: SessionService) {
  }
  /** Init */
  async ngOnInit() {
    this.readOnly = !(await this.sessionService.checkPermission(
			'multi_choice_category',
			'update'
		));

    // Init model
    if(!this.multiChoiceCategory) this.multiChoiceCategory = new MultiChoiceCategory();

    // Create form
    this.form = this.formBuilder.group({
    name: new FormControl(this.multiChoiceCategory.props.name, [Validators.required]),
    question: new FormControl(this.multiChoiceCategory.props.question, [Validators.required]),
    result_type: new FormControl(this.multiChoiceCategory.props.result_type, [Validators.required]),
    category_image: new FormControl(this.multiChoiceCategory.props.category_image, []),
    environment_image: new FormControl(this.multiChoiceCategory.props.environment_image, []),
    elements: new FormControl(this.multiChoiceCategory.props.elements, []),
    });

    // Update form when model loads or changes
    this.modelSubscription = this.multiChoiceCategory.subscribe(() => {
        this.updateForm();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.modelSubscription.unsubscribe();
  }
  /** Called on form submit */
  async onSubmit(): Promise<void> {
    // Saving flag
    this.saving = true;
    try {

        for(let form of this.elementsforms)
        {
            await form.submit();
        }
        // Update model
        this.updateModel();
	
        // Creation or update ?
        if (this.multiChoiceCategory.isNew()) {
            // Creation
            const multiChoiceCategory: MultiChoiceCategory = await this.multiChoiceCategoryService.create(this.multiChoiceCategory.toPayload());
            this.create.next(multiChoiceCategory);
        } else {
            // Update
            await this.multiChoiceCategoryService.update(this.multiChoiceCategory.getId(), this.multiChoiceCategory.toPayload());
            this.update.next();
        }
    } catch (error) {
        this.errorService.handle(error);
    }
    // Saving flag
    this.saving = false
  }
  /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.multiChoiceCategory.props[key] = this.form.get(key).value;
    }
  }
  /** Update inputs values from models properties */
  private updateForm(): void {
    this.form.setValue({
     'name': this.multiChoiceCategory.props.name,
     'question': this.multiChoiceCategory.props.question,
     'result_type': this.multiChoiceCategory.props.result_type,
     'category_image': this.multiChoiceCategory.props.category_image,
     'environment_image': this.multiChoiceCategory.props.environment_image,
     'elements': this.multiChoiceCategory.props.elements,
    });
  }
  /** Called on deletion */
  onDelete(): void {
    this.deleting = true;
    this.multiChoiceCategoryService.remove(this.multiChoiceCategory.getId())
      .then(() => {
        this.delete.next();
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => this.deleting = false);
  }
}
