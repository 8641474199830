<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'user_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Role -->

                
                <nz-form-item>
                    <h4>{{ 'user_role' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="role">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Role -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Email -->

                
                <nz-form-item>
                    <h4>{{ 'user_email' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="email"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="email">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Email -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Password -->

                
                <nz-form-item>
                    <h4>{{ 'user_password' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="password"
                               autocomplete="new-password"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="password">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Password -->
            </div>
        </div>
  </div>
  