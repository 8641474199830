import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import {
	OralCategory,
	OralCategoryInterface,
} from '../oral-category/oral-category';
import { Instruction, InstructionInterface } from '../instruction/instruction';
export interface OralQuizModuleInterface extends BaseModelInterface {
	name: string;
	memory_aid?: string;
	background_image?: string | Image | ImageInterface;
	question: string;
	good_answers_per_category: string;
	final_text: string;
	final_image?: string | Image | ImageInterface;
	timer_final_results: any;
	time_in_seconds: number;
	time_results_tiers: any;
	categories?: (string | OralCategory | OralCategoryInterface)[];
	instructions?: (string | Instruction | InstructionInterface)[];
	created_at: number | Date;
}
export interface OralQuizModulePayload {
	name: string;
	memory_aid?: string;
	background_image?: string | Image | ImageInterface;
	question: string;
	good_answers_per_category: string;
	final_text: string;
	final_image?: string | Image | ImageInterface;
	timer_final_results: any;
	time_in_seconds: number;
	time_results_tiers: any;
	categories?: (string | OralCategory | OralCategoryInterface)[];
	instructions?: (string | Instruction | InstructionInterface)[];
}
type OralQuizModulePayloadKey = keyof OralQuizModulePayload;
export class OralQuizModule extends BaseModel<
	OralQuizModuleInterface,
	OralQuizModulePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of background image has been populated */
	backgroundImageExists(): boolean {
		return (
			!!this.props &&
			this.props.background_image instanceof Image &&
			this.props.background_image.exists()
		);
	}
	/** Denotes if the instance of final image has been populated */
	finalImageExists(): boolean {
		return (
			!!this.props &&
			this.props.final_image instanceof Image &&
			this.props.final_image.exists()
		);
	}
	/** Denotes if the instance of categories has been populated */
	categoriesExists(): boolean {
		return (
			!!this.props &&
			this.props.categories instanceof Array &&
			(<OralCategory[]>this.props.categories).every((item) => {
				return item instanceof OralCategory && item.exists();
			})
		);
	}
	/** Denotes if the instance of instructions has been populated */
	instructionsExists(): boolean {
		return (
			!!this.props &&
			this.props.instructions instanceof Array &&
			(<Instruction[]>this.props.instructions).every((item) => {
				return item instanceof Instruction && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: OralQuizModuleInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (
			this.props.background_image !== null &&
			typeof this.props.background_image === 'object'
		) {
			this.props.background_image = new Image(
				<ImageInterface>this.props.background_image
			);
		}

		if (
			this.props.final_image !== null &&
			typeof this.props.final_image === 'object'
		) {
			this.props.final_image = new Image(
				<ImageInterface>this.props.final_image
			);
		}
		if (this.props.categories instanceof Array) {
			this.props.categories = (<OralCategoryInterface[]>(
				this.props.categories
			)).map((item) => {
				return typeof item === 'object' ? new OralCategory(item) : item;
			});
		}
		if (this.props.instructions instanceof Array) {
			this.props.instructions = (<InstructionInterface[]>(
				this.props.instructions
			)).map((item) => {
				return typeof item === 'object' ? new Instruction(item) : item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): OralQuizModuleInterface {
		const props = Object.assign({}, this.props);
		if (
			typeof props.memory_aid !== 'string' ||
			props.memory_aid.length === 0
		) {
			props.memory_aid = null;
		}
		if (typeof props.categories === 'undefined') {
			props.categories = [];
		}
		if (typeof props.instructions === 'undefined') {
			props.instructions = [];
		}
		if (typeof props.final_image === 'undefined') {
			props.final_image = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (
			props.background_image !== null &&
			props.background_image instanceof Image
		) {
			props.background_image = props.background_image.toObject();
		}
		if (props.final_image !== null && props.final_image instanceof Image) {
			props.final_image = props.final_image.toObject();
		}
		if (this.props.categories instanceof Array) {
			props.categories = (<OralCategory[]>props.categories).map(
				(item) => {
					return item instanceof OralCategory
						? item.toObject()
						: item;
				}
			);
		}
		if (this.props.instructions instanceof Array) {
			props.instructions = (<Instruction[]>props.instructions).map(
				(item) => {
					return item instanceof Instruction ? item.toObject() : item;
				}
			);
		}

		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): OralQuizModulePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as OralQuizModuleInterface);
		payload.background_image = payload.background_image
			? this.extractId(payload.background_image)
			: null;
		payload.final_image = payload.final_image
			? this.extractId(payload.final_image)
			: null;
		payload.categories = payload.categories
			? payload.categories.map((i) => this.extractId(i))
			: null;
		payload.instructions = payload.instructions
			? payload.instructions.map((i) => this.extractId(i))
			: null;
		return payload as OralQuizModulePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): OralQuizModulePayloadKey[] {
		return [
			'name',
			'memory_aid',
			'background_image',
			'question',
			'good_answers_per_category',
			'final_text',
			'final_image',
			'timer_final_results',
			'time_in_seconds',
			'time_results_tiers',
			'categories',
			'instructions',
		];
	}
}
