<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Title -->

                
                <nz-form-item>
                    <h4>{{ 'media-image_title' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="title">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Title -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Thumbnail -->

                
                <nz-form-item>
                    <h4>{{ 'media-image_thumbnail' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="thumbnail"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Thumbnail -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Image -->

                
                <nz-form-item>
                    <h4>{{ 'media-image_image' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="image"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Image -->
            </div>
        </div>
  </div>
  