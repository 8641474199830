import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {ActivityModule} from '../../activity-module';

@Component({
  selector: 'hpf-activity-module-create',
  templateUrl: './activity-module-create.component.html'
})
export class ActivityModuleCreateComponent implements OnInit {
  /** The activity module */
  activityModule = new ActivityModule();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the activity module is created */
  onCreate(activityModule: ActivityModule): void {
    this.router.navigateByUrl(`/activity-module/${activityModule.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
