import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { environment } from '@env/environment';
export interface Image360Interface extends BaseModelInterface {
	name: string;
	file_ext: string;
	file: any;
	created_at: number | Date;
}
export interface Image360Payload {
	name: string;
	file: any;
}
type Image360PayloadKey = keyof Image360Payload;
export class Image360 extends BaseModel<Image360Interface, Image360Payload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	getUri(): string {
		return `${environment.api.uri}/image-360/view/${this.props._id}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: Image360Interface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): Image360Interface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): Image360Payload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as Image360Interface);
		return payload as Image360Payload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): Image360PayloadKey[] {
		return ['name', 'file'];
	}
}
