import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {MediaAudio} from '../../media-audio';
import {MediaAudioService} from '../../media-audio.service';
@Component({
  selector: 'hpf-media-audio-edit',
  templateUrl: './media-audio-edit.component.html'
})
export class MediaAudioEditComponent implements OnInit, OnDestroy {
  /** The media audio */
  mediaAudio = new MediaAudio();
  /** The media audio */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private mediaAudioService: MediaAudioService) {}
  /** Init */
  ngOnInit() {
    // Get the media audio after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch media audio from API */
  private refresh() {
    this.mediaAudioService.get(this.id, { _populate: ['*'] })
      .then((mediaAudio: MediaAudio) => {
        this.mediaAudio.fromObject(mediaAudio.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the media audio is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the media audio is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/media-audio')
      .catch((error) => this.errorService.handle(error));
  }
}
