<div class="container" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_name' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Memory Aid -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_memory-aid' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100"
                                  nz-input
                                  [nzAutosize]="{ minRows: 3, maxRows: 12 }"
                                  [placeholder]="'common_value-string' | translate"
                                  formControlName="memory_aid"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Memory Aid -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Background Image -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_background-image' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="background_image"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Background Image -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Question -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_question' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="question">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Question -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Good Answers Per Category -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_good-answers-per-category' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="good_answers_per_category">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Good Answers Per Category -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Final Text -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_final-text' | translate }}</h4>
                    <nz-form-control>

         
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="final_text">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Final Text -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Final Image -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_final-image' | translate }}</h4>
                    <nz-form-control>

         

        

     <app-image-modal-button formControlName="final_image"></app-image-modal-button>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Final Image -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Timer Final Results -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_timer-final-results' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100 text-monospace"
                                  nz-input
                                  [nzAutosize]="{ minRows: 6, maxRows: 12 }"
                                  [placeholder]="'common_value-object' | translate"
                                  formControlName="timer_final_results"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Timer Final Results -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Time In Seconds -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_time-in-seconds' | translate }}</h4>
                    <nz-form-control>

         
                        <nz-input-number class="w-100"
                                         formControlName="time_in_seconds" 
                                         [nzStep]="1"
                                         [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Time In Seconds -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Time Results Tiers -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_time-results-tiers' | translate }}</h4>
                    <nz-form-control>

         
                        <textarea class="w-100 text-monospace"
                                  nz-input
                                  [nzAutosize]="{ minRows: 6, maxRows: 12 }"
                                  [placeholder]="'common_value-object' | translate"
                                  formControlName="time_results_tiers"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Time Results Tiers -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Categories -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_categories' | translate }}</h4>
                    <nz-form-control>

         
              <oral-category-form-array formControlName="categories"></oral-category-form-array>

                    </nz-form-control>
                </nz-form-item>
                <!-- /Categories -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Instructions -->

                
                <nz-form-item>
                    <h4>{{ 'oral-quiz-module_instructions' | translate }}</h4>
                    <nz-form-control>

         
              <instruction-form-array formControlName="instructions"></instruction-form-array>

                    </nz-form-control>
                </nz-form-item>
                <!-- /Instructions -->
            </div>
        </div>
  </div>
  