import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {MultimediaModule} from '../../multimedia-module';

@Component({
  selector: 'hpf-multimedia-module-create',
  templateUrl: './multimedia-module-create.component.html'
})
export class MultimediaModuleCreateComponent implements OnInit {
  /** The multimedia module */
  multimediaModule = new MultimediaModule();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the multimedia module is created */
  onCreate(multimediaModule: MultimediaModule): void {
    this.router.navigateByUrl(`/multimedia-module/${multimediaModule.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
