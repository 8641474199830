import {Injectable} from '@angular/core';
import {MultimediaModule, MultimediaModuleInterface, MultimediaModulePayload} from './multimedia-module';
import {MultimediaModuleSearchParamsInterface} from './multimedia-module-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MultimediaModuleService extends BaseModelService< MultimediaModule, MultimediaModuleInterface, MultimediaModulePayload, MultimediaModuleSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'multimedia-module';
  }
  /** @inheritDoc */
  protected new(object: MultimediaModuleInterface): MultimediaModule {
    return new MultimediaModule(object);
  }
}
