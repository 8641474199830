import { Component, Input } from '@angular/core';
import { ErrorService } from '@app/services';
import { EntityTableComponent } from '@app/abstracts';
import { AudioSearchParams } from '../audio-search-params';
import { AudioService } from '../audio.service';
import { Audio } from '../audio';

@Component({
	selector: 'hpf-audio-table',
	templateUrl: './audio-table.component.html',
})
export class AudioTableComponent extends EntityTableComponent<
	Audio,
	AudioSearchParams
> {
	/** Columns to show */
	@Input() columns = ['name', 'file_ext', 'created_at'];
	/** Constructor */
	constructor(
		private audioService: AudioService,
		private errorService: ErrorService
	) {
		super();
	}
	/** @inheritDoc */
	protected refresh(): void {
		const params = this.searchParams.toObject();
		// Set refreshing flag
		this.refreshing = true;
		// Get list
		this.audioService
			.list(params)
			.then((results) => {
				// Link data
				this.items = results.items;
				this.total = results.total;
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => {
				this.refreshing = false;
			});
	}

	/** Called when the user select multiple rows and delete them */
	async removeMany() {
		if (!this.selectedRows.length) {
			return;
		}

		this.deleting = true;
		for (const item of this.selectedRows) {
			await this.audioService
				.remove(item.getId())
				.catch((e) => this.errorService.handle(e));
		}

		this.deleteMany.next(this.selectedRows);
		this.resetSelectedRows();
		this.deleting = false;
		this.deleteManyModal = false;
		this.searchParams.next();
	}
	/** Called when the user delete a single row */
	async removeOne() {
		if (!this.rowToDelete) {
			return;
		}

		this.deleting = true;
		await this.audioService
			.remove(this.rowToDelete.getId())
			.catch((e) => this.errorService.handle(e));

		this.deleteOne.next(this.rowToDelete);
		delete this.rowToDelete;
		this.deleting = false;
		this.deleteOneModal = false;
		this.searchParams.next();
	}
}
