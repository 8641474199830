<div class="container" [formGroup]="form">
  <div class="row">
    <div class="col-12 col-md-6">
      <!-- Name -->


      <nz-form-item>
        <h4>{{ 'multi-choice-category_name' | translate }}</h4>
        <nz-form-control>


          <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
            formControlName="name">
        </nz-form-control>
      </nz-form-item>
      <!-- /Name -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Question -->


      <nz-form-item>
        <h4>{{ 'multi-choice-category_question' | translate }}</h4>
        <nz-form-control>


          <textarea class="w-100" nz-input [nzAutosize]="{ minRows: 3, maxRows: 12 }"
            [placeholder]="'common_value-string' | translate" formControlName="question"></textarea>
        </nz-form-control>
      </nz-form-item>
      <!-- /Question -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Result Type -->


      <nz-form-item>
        <h4>{{ 'multi-choice-category_result-type' | translate }}</h4>
        <nz-form-control>


          <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
            formControlName="result_type">
        </nz-form-control>
      </nz-form-item>
      <!-- /Result Type -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Category Image -->


      <nz-form-item>
        <h4>{{ 'multi-choice-category_category-image' | translate }}</h4>
        <nz-form-control>





          <app-image-modal-button formControlName="category_image"></app-image-modal-button>
        </nz-form-control>
      </nz-form-item>
      <!-- /Category Image -->
    </div>
    <div class="col-12 col-md-6">
      <!-- Environment Image -->


      <nz-form-item>
        <h4>{{ 'multi-choice-category_environment-image' | translate }}</h4>
        <nz-form-control>





          <app-image-modal-button formControlName="environment_image"></app-image-modal-button>
        </nz-form-control>
      </nz-form-item>
      <!-- /Environment Image -->
    </div>
    <div class="col-12">
      <!-- Elements -->


      <nz-form-item>
        <h4>{{ 'multi-choice-category_elements' | translate }}</h4>
        <nz-form-control>


          <multi-element-form-array formControlName="elements"></multi-element-form-array>

        </nz-form-control>
      </nz-form-item>
      <!-- /Elements -->
    </div>
  </div>
</div>
