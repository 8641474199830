import {Injectable} from '@angular/core';
import {MultiChoiceQuizModule, MultiChoiceQuizModuleInterface, MultiChoiceQuizModulePayload} from './multi-choice-quiz-module';
import {MultiChoiceQuizModuleSearchParamsInterface} from './multi-choice-quiz-module-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MultiChoiceQuizModuleService extends BaseModelService< MultiChoiceQuizModule, MultiChoiceQuizModuleInterface, MultiChoiceQuizModulePayload, MultiChoiceQuizModuleSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'multi-choice-quiz-module';
  }
  /** @inheritDoc */
  protected new(object: MultiChoiceQuizModuleInterface): MultiChoiceQuizModule {
    return new MultiChoiceQuizModule(object);
  }
}
