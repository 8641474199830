import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {InstructionSearchParams} from '../instruction-search-params';
import {InstructionService} from '../instruction.service';
import {Instruction} from '../instruction';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-instruction-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class InstructionSelectComponent extends EntitySelectComponent< Instruction> {
  protected modelName = 'instruction';
  /**
   * Constructor
   * @param {InstructionService} instructionService
   * @param {ErrorService} errorService
   */
  constructor(private instructionService: InstructionService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Instruction)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Instruction[]>this.model).push(<Instruction>model);
          } else {
            this.model = <Instruction>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Instruction[]> {
      const params = new InstructionSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.instructionService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Instruction> {
      return await this.instructionService.get(id);
  }
}
