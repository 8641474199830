import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription as RxJsSubscription} from 'rxjs';
import {OralQuizModuleSearchParams} from '../../oral-quiz-module-search-params';
import {OralQuizModule} from '../../oral-quiz-module';
import {Helpers} from '@app/shared';
@Component({
  selector: 'hpf-oral-quiz-module-list',
  templateUrl: './oral-quiz-module-list.component.html',
  styleUrls: []
})
export class OralQuizModuleListComponent implements OnInit, OnDestroy {
  /** The filters */
  searchParams = new OralQuizModuleSearchParams(this.route.snapshot.queryParams);
  /** The search params subscription */
  private paramsSubscription: RxJsSubscription;
  /** Used to open or close filter drawer */
  filtersDrawer = false;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location) {
  }
  /** Init */
  ngOnInit() {
    // Update query string when params change
    this.paramsSubscription = this.searchParams.subscribe(() => {
      this.location.replaceState('/oral-quiz-module', Helpers.toQueryString(this.searchParams.toObject()));
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
  /** Called when the user select a row in the table */
  onSelect(item: OralQuizModule) {
    this.router.navigateByUrl(`/oral-quiz-module/${item.getId()}`);
  }
  /** Called when the user click on create */
  onCreate() {
    this.router.navigate(['/oral-quiz-module/create']);
  }
}
