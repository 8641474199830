
<!-- Name -->
<nz-form-item>
    <nz-form-label>{{ 'multimedia-module_name' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.name">
</nz-form-item>
<!-- /Name -->
<button
	nz-button
	nzType="default"
	(click)="resetSearchParams()"
>
	{{ 'common_reset-filters' | translate }}
</button>
