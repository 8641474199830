import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ClientInfoSearchParams} from '../../client-info-search-params';
import {ClientInfo} from '../../client-info';
import {Helpers} from '@app/shared';
@Component({
  selector: 'hpf-client-info-list',
  templateUrl: './client-info-list.component.html',
  styleUrls: []
})
export class ClientInfoListComponent implements OnInit, OnDestroy {
  /** The filters */
  searchParams = new ClientInfoSearchParams(this.route.snapshot.queryParams);
  /** The search params subscription */
  private paramsSubscription: RxJsSubscription;
  /** Used to open or close filter drawer */
  filtersDrawer = false;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location) {
  }
  /** Init */
  ngOnInit() {
    // Update query string when params change
    this.paramsSubscription = this.searchParams.subscribe(() => {
      this.location.replaceState('/client-info', Helpers.toQueryString(this.searchParams.toObject()));
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
  /** Called when the user select a row in the table */
  onSelect(item: ClientInfo) {
    this.router.navigateByUrl(`/client-info/${item.getId()}`);
  }
  /** Called when the user click on create */
  onCreate() {
    this.router.navigate(['/client-info/create']);
  }
}
