import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Pdf} from '../../pdf';

@Component({
  selector: 'hpf-pdf-create',
  templateUrl: './pdf-create.component.html'
})
export class PdfCreateComponent implements OnInit {
  /** The pdf */
  pdf = new Pdf();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the pdf is created */
  onCreate(pdf: Pdf): void {
    this.router.navigateByUrl(`/pdf/${pdf.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
