import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	templateUrl: './input-array.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: InputArrayComponent,
			multi: true,
		},
	],
})
export abstract class InputArrayComponent<t> implements ControlValueAccessor {
	@Input() value: t[];

	onChange: Function;
	inputType = 'string';
	constructor(private host: ElementRef<HTMLInputElement>) {}

	inputValueChanged(): void {
		this.onChange(this.value);
	}
	writeValue(value: any) {
		console.log(value);
		if (!(value instanceof Array)) {
			this.value = [];
		} else {
			this.value = value;
		}
	}
	add() {}

	remove(index) {
		if (index > -1) {
			this.value.splice(index, 1);
		}
	}
	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {}
}
