import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@app/shared';
import {TranslateModuleLoad} from '@app/translate-import';
import {ModelsRoutingModule} from './models-routing.module';
import {ModelsModule} from './models.module';
import {ActivityModuleService} from './activity-module';
import {ActivityModuleComponent} from './activity-module/activity-module/activity-module.component';
import {ActivityModuleCreateComponent} from './activity-module/views/activity-module-create/activity-module-create.component';
import {ActivityModuleEditComponent} from './activity-module/views/activity-module-edit/activity-module-edit.component';
import {ActivityModuleListComponent} from './activity-module/views/activity-module-list/activity-module-list.component';
import {AudioService} from './audio';
import {AudioComponent} from './audio/audio/audio.component';
import {AudioCreateComponent} from './audio/views/audio-create/audio-create.component';
import {AudioEditComponent} from './audio/views/audio-edit/audio-edit.component';
import {AudioListComponent} from './audio/views/audio-list/audio-list.component';
import {ClientInfoService} from './client-info';
import {ClientInfoComponent} from './client-info/client-info/client-info.component';
import {ClientInfoCreateComponent} from './client-info/views/client-info-create/client-info-create.component';
import {ClientInfoEditComponent} from './client-info/views/client-info-edit/client-info-edit.component';
import {ClientInfoListComponent} from './client-info/views/client-info-list/client-info-list.component';
import {ImageService} from './image';
import {ImageComponent} from './image/image/image.component';
import {ImageCreateComponent} from './image/views/image-create/image-create.component';
import {ImageEditComponent} from './image/views/image-edit/image-edit.component';
import {ImageListComponent} from './image/views/image-list/image-list.component';
import {Image360Service} from './image-360';
import {Image360Component} from './image-360/image-360/image-360.component';
import {Image360CreateComponent} from './image-360/views/image-360-create/image-360-create.component';
import {Image360EditComponent} from './image-360/views/image-360-edit/image-360-edit.component';
import {Image360ListComponent} from './image-360/views/image-360-list/image-360-list.component';
import {InstructionService} from './instruction';
import {InstructionComponent} from './instruction/instruction/instruction.component';
import {InstructionCreateComponent} from './instruction/views/instruction-create/instruction-create.component';
import {InstructionEditComponent} from './instruction/views/instruction-edit/instruction-edit.component';
import {InstructionListComponent} from './instruction/views/instruction-list/instruction-list.component';
import {ItineraryService} from './itinerary';
import {ItineraryComponent} from './itinerary/itinerary/itinerary.component';
import {ItineraryCreateComponent} from './itinerary/views/itinerary-create/itinerary-create.component';
import {ItineraryEditComponent} from './itinerary/views/itinerary-edit/itinerary-edit.component';
import {ItineraryListComponent} from './itinerary/views/itinerary-list/itinerary-list.component';
import {MediaService} from './media';
import {MediaComponent} from './media/media/media.component';
import {MediaCreateComponent} from './media/views/media-create/media-create.component';
import {MediaEditComponent} from './media/views/media-edit/media-edit.component';
import {MediaListComponent} from './media/views/media-list/media-list.component';
import {Media360ImageService} from './media-360-image';
import {Media360ImageComponent} from './media-360-image/media-360-image/media-360-image.component';
import {Media360ImageCreateComponent} from './media-360-image/views/media-360-image-create/media-360-image-create.component';
import {Media360ImageEditComponent} from './media-360-image/views/media-360-image-edit/media-360-image-edit.component';
import {Media360ImageListComponent} from './media-360-image/views/media-360-image-list/media-360-image-list.component';
import {Media360VideoService} from './media-360-video';
import {Media360VideoComponent} from './media-360-video/media-360-video/media-360-video.component';
import {Media360VideoCreateComponent} from './media-360-video/views/media-360-video-create/media-360-video-create.component';
import {Media360VideoEditComponent} from './media-360-video/views/media-360-video-edit/media-360-video-edit.component';
import {Media360VideoListComponent} from './media-360-video/views/media-360-video-list/media-360-video-list.component';
import {MediaAudioService} from './media-audio';
import {MediaAudioComponent} from './media-audio/media-audio/media-audio.component';
import {MediaAudioCreateComponent} from './media-audio/views/media-audio-create/media-audio-create.component';
import {MediaAudioEditComponent} from './media-audio/views/media-audio-edit/media-audio-edit.component';
import {MediaAudioListComponent} from './media-audio/views/media-audio-list/media-audio-list.component';
import {MediaImageService} from './media-image';
import {MediaImageComponent} from './media-image/media-image/media-image.component';
import {MediaImageCreateComponent} from './media-image/views/media-image-create/media-image-create.component';
import {MediaImageEditComponent} from './media-image/views/media-image-edit/media-image-edit.component';
import {MediaImageListComponent} from './media-image/views/media-image-list/media-image-list.component';
import {MediaPDFService} from './media-pdf';
import {MediaPDFComponent} from './media-pdf/media-pdf/media-pdf.component';
import {MediaPDFCreateComponent} from './media-pdf/views/media-pdf-create/media-pdf-create.component';
import {MediaPDFEditComponent} from './media-pdf/views/media-pdf-edit/media-pdf-edit.component';
import {MediaPDFListComponent} from './media-pdf/views/media-pdf-list/media-pdf-list.component';
import {MediaVideoService} from './media-video';
import {MediaVideoComponent} from './media-video/media-video/media-video.component';
import {MediaVideoCreateComponent} from './media-video/views/media-video-create/media-video-create.component';
import {MediaVideoEditComponent} from './media-video/views/media-video-edit/media-video-edit.component';
import {MediaVideoListComponent} from './media-video/views/media-video-list/media-video-list.component';
import {ModuleService} from './module';
import {ModuleComponent} from './module/module/module.component';
import {ModuleCreateComponent} from './module/views/module-create/module-create.component';
import {ModuleEditComponent} from './module/views/module-edit/module-edit.component';
import {ModuleListComponent} from './module/views/module-list/module-list.component';
import {MultiChoiceCategoryService} from './multi-choice-category';
import {MultiChoiceCategoryComponent} from './multi-choice-category/multi-choice-category/multi-choice-category.component';
import {MultiChoiceCategoryCreateComponent} from './multi-choice-category/views/multi-choice-category-create/multi-choice-category-create.component';
import {MultiChoiceCategoryEditComponent} from './multi-choice-category/views/multi-choice-category-edit/multi-choice-category-edit.component';
import {MultiChoiceCategoryListComponent} from './multi-choice-category/views/multi-choice-category-list/multi-choice-category-list.component';
import {MultiChoiceQuizModuleService} from './multi-choice-quiz-module';
import {MultiChoiceQuizModuleComponent} from './multi-choice-quiz-module/multi-choice-quiz-module/multi-choice-quiz-module.component';
import {MultiChoiceQuizModuleCreateComponent} from './multi-choice-quiz-module/views/multi-choice-quiz-module-create/multi-choice-quiz-module-create.component';
import {MultiChoiceQuizModuleEditComponent} from './multi-choice-quiz-module/views/multi-choice-quiz-module-edit/multi-choice-quiz-module-edit.component';
import {MultiChoiceQuizModuleListComponent} from './multi-choice-quiz-module/views/multi-choice-quiz-module-list/multi-choice-quiz-module-list.component';
import {MultiElementService} from './multi-element';
import {MultiElementComponent} from './multi-element/multi-element/multi-element.component';
import {MultiElementCreateComponent} from './multi-element/views/multi-element-create/multi-element-create.component';
import {MultiElementEditComponent} from './multi-element/views/multi-element-edit/multi-element-edit.component';
import {MultiElementListComponent} from './multi-element/views/multi-element-list/multi-element-list.component';
import {MultimediaModuleService} from './multimedia-module';
import {MultimediaModuleComponent} from './multimedia-module/multimedia-module/multimedia-module.component';
import {MultimediaModuleCreateComponent} from './multimedia-module/views/multimedia-module-create/multimedia-module-create.component';
import {MultimediaModuleEditComponent} from './multimedia-module/views/multimedia-module-edit/multimedia-module-edit.component';
import {MultimediaModuleListComponent} from './multimedia-module/views/multimedia-module-list/multimedia-module-list.component';
import {OralCategoryService} from './oral-category';
import {OralCategoryComponent} from './oral-category/oral-category/oral-category.component';
import {OralCategoryCreateComponent} from './oral-category/views/oral-category-create/oral-category-create.component';
import {OralCategoryEditComponent} from './oral-category/views/oral-category-edit/oral-category-edit.component';
import {OralCategoryListComponent} from './oral-category/views/oral-category-list/oral-category-list.component';
import {OralQuizModuleService} from './oral-quiz-module';
import {OralQuizModuleComponent} from './oral-quiz-module/oral-quiz-module/oral-quiz-module.component';
import {OralQuizModuleCreateComponent} from './oral-quiz-module/views/oral-quiz-module-create/oral-quiz-module-create.component';
import {OralQuizModuleEditComponent} from './oral-quiz-module/views/oral-quiz-module-edit/oral-quiz-module-edit.component';
import {OralQuizModuleListComponent} from './oral-quiz-module/views/oral-quiz-module-list/oral-quiz-module-list.component';
import {PdfService} from './pdf';
import {PdfComponent} from './pdf/pdf/pdf.component';
import {PdfCreateComponent} from './pdf/views/pdf-create/pdf-create.component';
import {PdfEditComponent} from './pdf/views/pdf-edit/pdf-edit.component';
import {PdfListComponent} from './pdf/views/pdf-list/pdf-list.component';
import {PhotoModuleService} from './photo-module';
import {PhotoModuleComponent} from './photo-module/photo-module/photo-module.component';
import {PhotoModuleCreateComponent} from './photo-module/views/photo-module-create/photo-module-create.component';
import {PhotoModuleEditComponent} from './photo-module/views/photo-module-edit/photo-module-edit.component';
import {PhotoModuleListComponent} from './photo-module/views/photo-module-list/photo-module-list.component';
import {PointOfInterestService} from './point-of-interest';
import {PointOfInterestComponent} from './point-of-interest/point-of-interest/point-of-interest.component';
import {PointOfInterestCreateComponent} from './point-of-interest/views/point-of-interest-create/point-of-interest-create.component';
import {PointOfInterestEditComponent} from './point-of-interest/views/point-of-interest-edit/point-of-interest-edit.component';
import {PointOfInterestListComponent} from './point-of-interest/views/point-of-interest-list/point-of-interest-list.component';
import {UserService} from './user';
import {UserComponent} from './user/user/user.component';
import {UserCreateComponent} from './user/views/user-create/user-create.component';
import {UserEditComponent} from './user/views/user-edit/user-edit.component';
import {UserListComponent} from './user/views/user-list/user-list.component';
import {VideoService} from './video';
import {VideoComponent} from './video/video/video.component';
import {VideoCreateComponent} from './video/views/video-create/video-create.component';
import {VideoEditComponent} from './video/views/video-edit/video-edit.component';
import {VideoListComponent} from './video/views/video-list/video-list.component';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModuleLoad(),
    ModelsModule,
    ModelsRoutingModule
  ],
  declarations: [
    ActivityModuleComponent,
    ActivityModuleCreateComponent,
    ActivityModuleEditComponent,
    ActivityModuleListComponent,
    AudioComponent,
    AudioCreateComponent,
    AudioEditComponent,
    AudioListComponent,
    ClientInfoComponent,
    ClientInfoCreateComponent,
    ClientInfoEditComponent,
    ClientInfoListComponent,
    ImageComponent,
    ImageCreateComponent,
    ImageEditComponent,
    ImageListComponent,
    Image360Component,
    Image360CreateComponent,
    Image360EditComponent,
    Image360ListComponent,
    InstructionComponent,
    InstructionCreateComponent,
    InstructionEditComponent,
    InstructionListComponent,
    ItineraryComponent,
    ItineraryCreateComponent,
    ItineraryEditComponent,
    ItineraryListComponent,
    MediaComponent,
    MediaCreateComponent,
    MediaEditComponent,
    MediaListComponent,
    Media360ImageComponent,
    Media360ImageCreateComponent,
    Media360ImageEditComponent,
    Media360ImageListComponent,
    Media360VideoComponent,
    Media360VideoCreateComponent,
    Media360VideoEditComponent,
    Media360VideoListComponent,
    MediaAudioComponent,
    MediaAudioCreateComponent,
    MediaAudioEditComponent,
    MediaAudioListComponent,
    MediaImageComponent,
    MediaImageCreateComponent,
    MediaImageEditComponent,
    MediaImageListComponent,
    MediaPDFComponent,
    MediaPDFCreateComponent,
    MediaPDFEditComponent,
    MediaPDFListComponent,
    MediaVideoComponent,
    MediaVideoCreateComponent,
    MediaVideoEditComponent,
    MediaVideoListComponent,
    ModuleComponent,
    ModuleCreateComponent,
    ModuleEditComponent,
    ModuleListComponent,
    MultiChoiceCategoryComponent,
    MultiChoiceCategoryCreateComponent,
    MultiChoiceCategoryEditComponent,
    MultiChoiceCategoryListComponent,
    MultiChoiceQuizModuleComponent,
    MultiChoiceQuizModuleCreateComponent,
    MultiChoiceQuizModuleEditComponent,
    MultiChoiceQuizModuleListComponent,
    MultiElementComponent,
    MultiElementCreateComponent,
    MultiElementEditComponent,
    MultiElementListComponent,
    MultimediaModuleComponent,
    MultimediaModuleCreateComponent,
    MultimediaModuleEditComponent,
    MultimediaModuleListComponent,
    OralCategoryComponent,
    OralCategoryCreateComponent,
    OralCategoryEditComponent,
    OralCategoryListComponent,
    OralQuizModuleComponent,
    OralQuizModuleCreateComponent,
    OralQuizModuleEditComponent,
    OralQuizModuleListComponent,
    PdfComponent,
    PdfCreateComponent,
    PdfEditComponent,
    PdfListComponent,
    PhotoModuleComponent,
    PhotoModuleCreateComponent,
    PhotoModuleEditComponent,
    PhotoModuleListComponent,
    PointOfInterestComponent,
    PointOfInterestCreateComponent,
    PointOfInterestEditComponent,
    PointOfInterestListComponent,
    UserComponent,
    UserCreateComponent,
    UserEditComponent,
    UserListComponent,
    VideoComponent,
    VideoCreateComponent,
    VideoEditComponent,
    VideoListComponent,
  ],
  providers: [
    ActivityModuleService,
    AudioService,
    ClientInfoService,
    ImageService,
    Image360Service,
    InstructionService,
    ItineraryService,
    MediaService,
    Media360ImageService,
    Media360VideoService,
    MediaAudioService,
    MediaImageService,
    MediaPDFService,
    MediaVideoService,
    ModuleService,
    MultiChoiceCategoryService,
    MultiChoiceQuizModuleService,
    MultiElementService,
    MultimediaModuleService,
    OralCategoryService,
    OralQuizModuleService,
    PdfService,
    PhotoModuleService,
    PointOfInterestService,
    UserService,
    VideoService,
  ],
  exports: [
    ActivityModuleComponent,
    AudioComponent,
    ClientInfoComponent,
    ImageComponent,
    Image360Component,
    InstructionComponent,
    ItineraryComponent,
    MediaComponent,
    Media360ImageComponent,
    Media360VideoComponent,
    MediaAudioComponent,
    MediaImageComponent,
    MediaPDFComponent,
    MediaVideoComponent,
    ModuleComponent,
    MultiChoiceCategoryComponent,
    MultiChoiceQuizModuleComponent,
    MultiElementComponent,
    MultimediaModuleComponent,
    OralCategoryComponent,
    OralQuizModuleComponent,
    PdfComponent,
    PhotoModuleComponent,
    PointOfInterestComponent,
    UserComponent,
    VideoComponent,
  ]
})
export class ModelsViewsModule {
}
